:root {
  --app-full: black;
  --app-center: #f5f5f5;
  --app-center-google: #e8ffe7;
  --app-center-google-temp: #e7f5fe;
  --app-center-google-temp-wrap: #b4dcf5;
  --app-container: #dbd9d9ca;
  --item-solo: #ffffff;
  --item-sec-header: #f0f0f0;
  --item-restrict: rgb(122, 77, 77);

  --off-black-0: rgb(0, 0, 0);
  --off-black-1: rgb(16, 16, 16);
  --off-black-2: rgb(32, 32, 32);
  --off-black-3: rgb(48, 48, 48);
  --off-black-4: rgb(64, 64, 64);
  --off-black-5: rgb(80, 80, 80);
  --off-black-6: rgb(96, 96, 96);
  --off-black-7: rgb(112, 112, 112);
  --off-white-0: rgb(255, 255, 255);
  --off-white-1: #ececec;
  --off-white-2: rgb(225, 225, 225);
  --off-white-3: rgb(196, 196, 196);
  --off-white-4: rgb(183, 183, 183);
  --off-white-5: rgb(150, 150, 150);
  --dimmer: rgb(32, 32, 32, .65);

  --off-red-1: rgb(68, 0, 0);

  --scroll-main: rgb(242, 242, 242);
  --scroll-sub: rgb(200, 200, 200);

  --card-shadow: rgb(228, 228, 228);
  --card-shadow-fav: rgb(175, 185, 255);
  --ddp: rgb(70, 70, 70);
  --drag-number-b: rgb(149, 182, 157);
  --drag-number-d: rgb(149, 182, 157);
  --drag-number-dd: rgb(101, 126, 107);
  --drag-number: rgb(186, 226, 196);
  --drag-number: rgb(224, 224, 224);
  --drop-content: lightgray;
  --help-header: rgb(235, 235, 235);
  --json-viewer-light: rgb(241, 241, 241);
  --json-viewer: rgb(19, 19, 19);
  --light-bgc: #f0f0f0;
  --marked-items: rgb(210, 252, 255);
  --mid-bgc: #adadad;
  --mod-h-bgc: rgb(245, 240, 240);
  --modify-footer: rgb(233, 233, 233);
  --page-bgc-mob: rgb(218, 218, 218);
  --page-bgc: rgb(31, 31, 31);
  --selected-borders: solid 2px lightgrey;
  --sem-black-b: rgb(55, 56, 59);
  --sem-black: #1b1c1d;
  --sem-blue: #2185d0;
  --sem-dark-blue: #004070;
  --sem-green-light: rgb(167, 255, 188);
  --sem-green: #21ba45;
  --sem-green-blackish: #00350c;
  --sem-greenish: #dfffe6;
  --sem-grey: rgb(116, 116, 116);
  --sem-light-blue: #d2ebff;
  --sem-light-green: #b6f1c4;
  --sem-light-green: #bbffcb;
  --sem-light-red: #ebc3c3;
  --sem-orange: #f2711c;
  --sem-purple: #a333c8;
  --sem-red-light: rgb(255, 118, 118);
  --sem-red: #db2828;
  --sem-redish: #ffe3e3;
  --sem-teal: #00b5ad;
  --sem-yellow: #fbbd08;
  --sem-brown: #a5673f;
  --settings-menu-item-dd: rgb(77, 33, 33);
  --side-menu-bgc-na: rgb(146, 146, 146);
  --side-menu-dmo-bgc: rgb(44, 40, 40);
  --sort-borders: solid 1px rgb(153, 163, 177);
  --sort-borders-dark: solid 1px rgb(32, 32, 32);
  --splash-container: rgb(14, 14, 14);
  --page-dimmer: rgb(58, 58, 58);
  --test: rgb(40, 75, 172);
  --theme-c: #131313;
  --thin-border-dm: solid 1px rgb(42, 42, 42);
  --thin-border-settings: solid 1px rgb(73, 73, 73);
  --thin-border-thin: solid 1px rgb(255, 255, 255);
  --thin-border: solid 1px lightgrey;
  --thin-borders-sort: dotted 1px rgb(75, 75, 75);
  --thin-borders: solid 1px lightgrey;
  --url-color: #4183c4;
  --me-background-bgc-dm: black;
  --me-background-bgc-dmoff: rgb(43, 43, 43);
  --me-border-bgc-dm: rgb(66, 66, 66);
  --me-card-bgc-dm: rgb(36, 36, 36);
  --me-card-c-dm: rgb(230, 230, 230);
  --me-card-c-dm-2: rgb(177, 177, 177);
  --app-google: rgb(241, 254, 231);
  --app-google-temp: rgb(231, 245, 254);
  --app-google-wrap: rgb(185, 207, 170);
  --app-google-wrap-temp: rgb(180, 220, 245);
  --non-sel: rgb(184, 184, 184);
  --horz-height: 36px;
  --score-edit: rgb(1, 161, 7);
}

.gradient-background {
  background-image: linear-gradient(to bottom, #0AA147, #006DAF);
  /* Replace these colors with your desired gradient */
}

.ddp,
.ddp>div,
.ddp>div>div {
  background-color: var(--off-black-5) !important;
  color: white !important;
  padding: .5em !important;
}

/* The parent div */
.App {
  background-color: black;
}

.start-container,
.splash-container,
.start-dimmer,
.page-dimmer {
  background-color: var(--splash-container);
}

.dimmer {
  background-color: var(--dimmer) !important;
}

.pending-container {
  background-color: pink;
}

.modal-full {
  border: 8px solid var(--off-black-4) !important;
}

.framework-container {
  background-color: white;
}

/* SETTINGS */
.app-sidebar-left-wrapper {
  background-color: black;
  color: white;
}

.settings-app-container {
  background-color: var(--app-center);
}

.settings-app-container.gds.temp {
  background-color: var(--app-center-google-temp);
}

.gds.temp .settings-app-mobile-wrap {
  border-color: var(--app-center-google-temp-wrap);
}

.settings-app-container.google {
  background-color: var(--app-center-google);
}

/* APP */

.app-container {
  background-color: var(--app-container) !important;
}

.item-container .blockAttached>.sec-header {
  background-color: var(--item-sec-header) !important;
  border-bottom: var(--thin-border) !important;
}

/* colors */
/* This is the 3 column grid (mobile) that contains the app */
.app-settings-grid {
  background-color: var(--off-black-0) !important;
}

.app-settings-grid>.side {
  background-color: var(--off-black-0);
}

.av-menu {
  background-color: var(--off-black-3) !important;
  height: 100%;
}

/* adv  */
.adv .menu:first-child {
  background-color: var(--off-black-2);
  color: var(--off-white-1);
}

.adv .menu:first-child>.item {
  color: inherit;
}

.adv .menu:first-child>.item:hover {
  color: inherit;
  background-color: var(--off-black-3);
}

/* vert container */

.vert-container.app>.content *:not(.btn-confirm .button) {
  background-color: inherit !important;
}

.vert-container.app>.content>.menu {
  border-bottom: 1px solid grey;
}

.vert-container .topper {
  background-color: var(--off-black-3) !important;
  color: white !important;
}

.vert-container.setz .topper {
  background-color: var(--off-black-6) !important;
  color: white !important;
}

.vert-container.setz .footer {
  color: white !important;
}

.vert-container .divider.horizontal {
  color: var(--menu-item-divider);
}

.vert-container.splitLR {
  background-color: black;
}

.image-mapping .vert-container {
  background-color: var(--off-white-3);
}

.vert-container>.topper,
.vert-container>.footer {
  background-color: var(--off-black-1) !important;
  color: var(--off-white-1);
}

.vert-container>.header:not(.linking .header) {
  background-color: var(--off-black-3) !important;
  color: var(--off-white-1);
}

.app-dark-mode .menu>.item,
.app-dark-mode.menu>.item {
  color: var(--off-white-2) !important;
}

.app-dark-mode .menu-slider-sidebar {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .menu-slider-sidebar>.item {
  color: white !important;
}

.settings-dark-mode .menu-slider,
.app-dark-mode .menu-slider {
  background-color: var(--off-black-2) !important;
  color: white;
}

.settings-dark-mode .menu-slider>.item,
.app-dark-mode .menu-slider>.item,
.app-dark-mode .menu-slider>.item:hover {
  color: white !important;
}

.adv .vert-container>.topper,
.adv .vert-container>.footer,
.wrp-sub.vert-container>.topper,
.wrp-sub.vert-container>.footer {
  background-color: var(--off-black-3) !important;
  color: var(--off-white-1);
}

.adv .vert-container>.header,
.wrp-sub.vert-container>.header {
  background-color: var(--off-black-6) !important;
  color: var(--off-white-1);
}

.wrp-sub.vert-container>.content {
  background-color: var(--off-white-3) !important;
}

.verif .topper {
  background-color: var(--sem-green) !important;
}

/* sec-container */
.sec-container.block>.sec-header {
  border: var(--thin-border);
  background-color: var(--light-bgc);
}

.sec-container.blockAttached>.sec-header {
  border-bottom: var(--thin-border);
  background-color: var(--light-bgc);
}

.sec-header>.divider {
  color: inherit !important;
  background-color: inherit !important;
}

/* .google-data-container .vert-container, */
.adv.gs .vert-container,
.adv .vert-container.wrp-sub {
  padding: .75em;
  background-color: var(--off-black-2);
}

.adv.gs .vert-container>.content {
  background-color: var(--off-white-2);
}

.adv.gs>.menu>.item {
  display: flex;
  justify-content: space-between;
}

/* linking cf */
.linking.vert-container>.footer {
  background-color: var(--off-black-4) !important;
  color: var(--off-white-1);
}

.linking.vert-container>.content {
  background-color: var(--off-white-0) !important;
}

/* data-linking cf */
.data-linking.vert-container>.footer {
  background-color: var(--off-black-5) !important;
  color: var(--off-white-1);
}

.data-linking.vert-container>.content {
  background-color: var(--off-white-0) !important;
}

/* data-linking hf */
.file-management.vert-container>.header {
  background-color: var(--off-black-6) !important;
  color: var(--off-white-1);
  min-height: 70px;
}

.file-management.vert-container.hc>.content {
  padding: 0em !important;
  background-color: var(--off-white-0) !important;
}

.file-management.vert-container.hcf>.content {
  padding: 1em;
  background-color: var(--off-white-0) !important;
}

.global-data.vert-container.hcf>.content * {
  background-color: var(--off-white-0) !important;
  color: var(--off-black-0) !important;
}

/* data-sidebar */
.data-sidebar>.header,
.data-sidebar>.content .footer {
  background-color: var(--off-black-5) !important;
  color: var(--off-white-1) !important;
}

.data-sidebar>.content {
  background-color: var(--off-white-1) !important;
}

.wrp-sub .menu-alphabet>.item.active {
  background-color: var(--sem-blue) !important;
}

/* menu-groups */

.menu-groups {
  background-color: inherit !important;
}

.menu-groups>.item>.header:hover {
  background-color: var(--off-black-2) !important;
}

/* Group Items */
.menu-groups>.item.restrict>.header {
  color: var(--item-restrict) !important;
}

/* google */
.google-data-container>.gst>.menu {
  background-color: black;
}

.google-data-container>.gst>.menu>.item {
  color: rgb(179, 179, 179) !important;
}

.google-data-container>.gst>.menu>.item.active {
  background-color: var(--sem-blue);
  color: white !important;
}

/* Sub Items */
.menu-groups>.item>.menu,
.mi-group-item {
  background-color: var(--off-black-0) !important;
}

.menu-groups>.item>.menu>.item,
.mi-group-item>.item {
  color: var(--off-white-2) !important;
}

.menu-groups>.item>.menu>.item:hover {
  color: var(--off-white-1) !important;
}

/* HELP */
.help-container>.message {
  background-color: rgb(68, 68, 68) !important;
  color: white;
}

.help-seg>.message {
  border-color: grey !important;
}

/* IMAGE MAPPING */
.image-mapping .list>.item.sel {
  background-color: var(--off-white-1);
}

.imt-zoneMapping {
  background-color: var(--sem-green);
}

/* APP DARK MODE */

.vert-container.setz .form-container {
  background-color: var(--off-black-0) !important;
  color: var(--off-white-1) !important;
}

.menu-groups>.item>.header,
.view-settings-menu.setz .menu *,
.vert-container.wrp .menu:not(.dropdown .menu) {
  background-color: var(--off-black-1) !important;
  color: var(--off-white-1) !important;
}

.smii.tt {
  background-color: var(--off-black-2);
  border: 1px solid var(--off-black-3);
}

/* OTHER */
.dimmer-suspense {
  background-color: var(--off-black-3) !important;
}

.settings-breadcrumb>.section,
.settings-breadcrumb>.divider {
  background-color: inherit !important;
  color: var(--off-white-3) !important;
  font-size: .8rem !important;
}

.inverted .is-html {
  color: white !important;
}

.sec-content .is-html {
  color: black !important;
}

.nob {
  background-color: green !important;
}

.table-split-container .card.sel {
  background-color: rgb(217, 217, 217);
}

.scroll-top {
  background: #473BF0;
  color: #fff !important;
}

.alpha-float>.item.sel {
  background-color: rgb(86, 86, 86) !important;
}

.alpha-float>.item.active {
  background-color: black !important;
}

.circle-container {
  background-color: #3498db;
}

.scrolling-letter {
  color: #ffffff;
}

.scrolling-count {
  color: #ffffff;
}

.gst.vit>.menu>.item.active {
  background-color: var(--sem-blue) !important;
}

.ms-button-group {
  background-color: white;
}

.start-dot {
  background-color: rgb(0, 54, 191);
}

.num-grid .num-pad-pad {
  background-color: rgb(42, 42, 42);
}

.grid-icons-content .segment.ao {
  border-color: gray;
  background-color: black !important;
  color: rgb(175, 175, 175) !important
}

.grid-icons-content .segment.hid {
  background-color: rgb(88, 88, 88) !important;
  color: white !important;
}

.grid-icons-content .divider {
  font-size: .9rem;
}

.lbl-color {
  padding-left: 0 !important;
  border: 1px solid rgb(204, 204, 204) !important
}

.lbl-color>.detail {
  background-color: white !important;
  color: black !important;
  padding: .5em;
  margin-left: 0.5em !important;
}

/* CALENDAR */
.cal-close>div {
  border: 1px solid grey;
  background-color: rgb(230, 230, 230);
}

.cal-month {
  background-color: rgb(185, 185, 185);
  border: 1px solid grey;
}

.calendar-container {
  background-color: rgb(230, 230, 230);
}

.calendar-container>div {
  border: 1px solid lightblue;
}

.calendar-container>div.dow {
  background-color: rgb(135, 206, 250);
}

.calendar-container>div.nd {
  background-color: rgb(211, 211, 211);
}

.calendar-container>div.sel {
  background-color: rgb(135, 250, 154);
}

.calendar-container>div.sel.co {
  background-color: rgb(8, 135, 29);
}

.calendar-container>div.norm {
  border-color: rgb(135, 250, 154);
}

.calendar-container>div.norm.dk {
  background-color: rgb(135, 250, 154);
}

.calendar-container>div .label {
  margin-top: .5em;
}

.non-sel {
  background-color: var(--non-sel);
}

.norm-count>span>div {
  background-color: red !important;
}

.month-select-container>div {
  background-color: rgb(187, 187, 216);
  border: 1px solid grey;
}

/* NOTIFICAITON */
.app-notification-container.active {
  background-color: rgb(236, 236, 236)
}

.app-notification-container.past {
  background-color: rgb(239, 255, 237)
}

.app-notification-container.past-ns {
  background-color: rgb(255, 229, 224)
}

.app-notification-container.past {
  border: 1px solid rgb(182, 250, 172) !important;
}

.app-notification-container.past-ns {
  border: 2px solid rgb(255, 197, 197) !important;
}

.app-notification-container .note-description,
.app-notification-container .note-note {
  background-color: rgb(255, 255, 255)
}

/* NUM PAD */

.num-pad {
  background-color: rgb(42, 42, 42);
}

.num-pad-current {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr !important;
}

.num-pad-value {
  background-color: white;
}

.num-pad-pad>div {
  background-color: rgb(61, 61, 61);
  color: white;
}

.num-pad-pad>div:nth-child(10),
.num-pad-pad>div:nth-child(12) {
  background-color: rgb(170, 170, 170);
  color: black
}

/* DARK MODE */

.app-dark-mode {
  background-color: var(--off-black-1) !important;
  color: var(--off-white-1) !important;
}

.app-dark-mode * .menu.transition>.item,
.app-dark-mode * .menu.transition>.item>span,
.app-dark-mode * .drag-number>.label,
.app-dark-mode * .field input,
.app-dark-mode * .field .dropdown,
.app-dark-mode * .field .checkbox>label,
.app-dark-mode * .dropdown,
.app-dark-mode * .dropdown>.menu,
.app-dark-mode * .images-content,
.app-dark-mode * .is-html,
.app-dark-mode * .rc-input-number *,
.settings-dark-mode .setz * .field input,
.settings-dark-mode .setz * .field .dropdown,
.settings-dark-mode .setz * .dropdown,
.settings-dark-mode .setz * .images-content,
.settings-dark-mode .setz * .is-html,
.settings-dark-mode .setz * .rc-input-number * {
  background-color: var(--off-black-2) !important;
  color: var(--off-white-1) !important;
  border-color: var(--off-black-4) !important;
}

.settings-dark-mode .setz * .field .dropdown>.label,
.settings-dark-mode .setz * .dropdown>.label {
  background-color: var(--off-black-3) !important;
  color: var(--off-white-1) !important;
}

.app-dark-mode * .dropdown>.label {
  background-color: var(--off-black-4);
  color: var(--off-white-1) !important;
}

.app-dark-mode * .dropdown .text:not(.menu .text) {
  background-color: var(--off-black-2);
  color: var(--off-white-1) !important;
}

.app-dark-mode * .header:not(.news-container .header):not(.app-homepage .header) {
  color: var(--off-white-1) !important;
}

.app-dark-mode * .form-container .menu:not(.menu-groups),
.settings-dark-mode * .form-container .menu:not(.menu-groups) {
  background-color: var(--off-black-2) !important;
  border-color: var(--off-black-3) !important;
}

.app-dark-mode .label:not(.label.color) {
  background-color: var(--off-black-2);
}

.app-dark-mode .form .field>label,
.app-dark-mode .form-container .field>label,
.app-dark-mode.form-container .icon {
  color: var(--off-white-2) !important;
}

.qaa>.footer {
  background-color: var(--off-black-2) !important;
}

.app-dark-mode * .field>.label,
.settings-dark-mode * .field>.label,
.app-dark-mode * .field .dropdown>.label,
.settings-dark-mode * .field .dropdown>.label,
.app-dark-mode *.images-header,
.settings-dark-mode *.images-header {
  background-color: var(--off-black-3) !important;
  color: var(--off-white-1) !important;
}

.app-dark-mode .item-container .card,
.app-dark-mode .item-container .crdc,
.app-dark-mode .item-container .card.card-schedule,
.app-dark-mode .item-container .card.card-profile {
  background-color: var(--off-black-3) !important;
  box-shadow: 0 1px 3px 0 var(--off-black-4), 0 0 0 1px var(--off-black-4) !important;
  color: var(--off-white-1) !important;
}

.app-dark-mode .divider:not(.text.divider),
.app-dark-mode .divider>.header:not(.text.divider) {
  background-color: var(--off-black-0) !important;
  color: var(--off-white-1) !important;
}

.settings-dark-mode .divider:not(.dropdown .divider) {
  color: var(--off-black-7) !important;
}

/* INPUTS */
.app-dark-mode .meta {
  color: var(--off-white-4) !important;
}

.app-dark-mode .ms-button-group {
  background-color: black !important;
}

.app-dark-mode * .form-container .menu:not(.menu-groups)>.item,
.settings-dark-mode * .form-container .menu:not(.menu-groups)>.item {
  color: var(--off-white-1) !important;
  border-color: var(--dark-adjust-brdc) !important;
}

.settings-dark-mode * .form-container .divider:not(.text) {
  font-size: .8rem !important;
}

.settings-dark-mode * .divider.alt {
  color: var(--off-white-4);
}

.settings-dark-mode * .images-header {
  border-color: var(--off-black-4) !important;
}

.dd-menu {
  background-color: var(--off-black-3) !important;
  color: white;
}

.json-container * {
  font-family: 'Roboto, Helvetica Neue', Arial, Helvetica, sans-serif;
}

.react-json-view * {
  background-color: black !important;
  color: var(--off-white-2) !important;
}

.app-dark-mode .menu-select-items .menu {
  border-color: var(--off-black-3) !important;
}

.app-dark-mode .athlete-team-select,
.app-dark-mode .menu-select-items .item {
  background-color: var(--off-black-2) !important;
}

.drop-group-header {
  background-color: var(--off-white-3);
}

.setz .drop-group-header {
  background-color: var(--off-black-1);
  color: white;
}

.drop-group-container {
  border: var(--sort-borders);
}

.setz .drop-group-container {
  border: var(--sort-borders-dark);
}

.icn-black .icon {
  color: var(--sem-black);
}

.icn-grey .icon {
  color: var(--sem-grey);
}

.app-dark-mode .calendar-container {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .calendar-container>div {
  background-color: var(--off-black-1) !important;
  border-color: var(--off-black-2) !important;
}

.app-dark-mode .calendar-container>div.dow {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .calendar-container>div.nd {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .calendar-container>div.norm {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .calendar-container>div.norm.dk {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .calendar-container>div.non-sel {
  background-color: var(--off-black-3) !important;
}

.app-dark-mode .calendar-container>div.sel {
  background-color: var(--off-black-5) !important;
}

.app-dark-mode .cal-month {
  background-color: var(--off-black-2) !important;
  border-color: var(--off-black-2) !important;
}

.app-dark-mode .cal-month-cb,
.app-dark-mode .cal-month-ic1,
.app-dark-mode .cal-month-ic2,
.app-dark-mode .cal-month-icw {
  background-color: var(--off-black-1) !important;
  border-color: var(--off-black-3) !important;
}


.app-dark-mode .vert-container:not(.noContent) .content {
  background-color: var(--off-black-1) !important;
  border-color: var(--off-black-1) !important;
}

.app-dark-mode .cal-months-container {
  background-color: var(--off-black-1) !important;
  border: 1px solid var(--off-black-1);
}

.app-dark-mode.cnt-uploader .content {
  background-color: var(--off-black-2) !important;
}

.app-dark-mode .wbbw .message {
  background-color: var(--off-black-2) !important;
  color: var(--off-white-1);
}

.settings-dark-mode .list-add>div {
  color: var(--off-white-2) !important;
}

.app-dark-mode .menu-slim {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .drop-group-header {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .drop-group-container {
  border-color: var(--off-black-3) !important;
}

.app-dark-mode .grid-row {
  color: var(--off-white-1) !important;
}

.app-dark-mode .notification>.message,
.app-dark-mode.list,
.app-dark-mode .drop-group-container,
.app-dark-mode .drop-group-content,
.app-dark-mode .drop-list {
  background-color: var(--off-black-1) !important;
  color: white !important;
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }

  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }

  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

@keyframes glow {
  0% {
    color: #2ba805;
  }

  50% {
    color: #49e819;
  }

  100% {
    color: #2ba805;
  }
}