:root {
  --gtc-1-10-1: 1fr 10fr 1fr;
  --gtc-1-2: 1fr 2fr;
  --gtc-1-3-1: 1fr 3fr 1fr;
  --gtc-1-5-1: 1fr 5fr 1fr;
  --gtc-1-7: 1fr 7fr;
  --gtc-1-8-1: 1fr 8fr 1fr;
  --gtc-100: 100%;
  --gtc-10x: repeat(10, 1fr);
  --gtc-2-3-2: 2fr 3fr 2fr;
  --gtc-2-4-4: 20% 40% 40%;
  --gtc-2-8: 20% 80%;
  --gtc-1x: 1fr;
  --gtc-2x: repeat(2, 1fr);
  --gtc-4x: repeat(4, 1fr);
  --gtc-6x: repeat(6, 1fr);

  --gtc-1-1: 1fr auto;
  --gtc-a-1: auto 1fr;
  --gtc-a-1-a: auto 1fr auto;
  --gtc-a-1-a-a: auto 1fr auto auto;
  --gtc-a-1-4-a: auto 1fr 4fr auto;
  --gtc-a-a-1: auto auto 1fr;
  --gtc-a-a-1-a: auto auto 1fr auto;

  --gtr-1-5-1: 1fr 5fr 1fr;
  --gtr-1-8-1: 1fr 8fr 1fr;
}

.rsvs {
  width: 100%;
  height: 100%;
}

.rsvs.nc {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
}

.rsvs-footer {
  padding: .5em
}

.rsvs>div {
  height: 100%;
}

.motion-div-container {
  height: 100%;
}

/* .grd100 */
/* GRID CONTAINERS */
.app-container,
.app-swipe-container,
.delete-container,
.google-data-container,
.grid-icons-container,
.hcaf-container,
.hcf-container,
.hcs-container,
.image-mapping.zone,
.image-mapping.location,
.label-container,
.label-content,
.map-container,
.map-list-container,
.message-container,
.messages-container,
.modify-container,
.push-container,
.splash-content,
.start-container,
.swipe-container,
.tab-container,
.table-container,
.uploader-container,
.uploader-container.url,
.content-content.mapList>div {
  display: grid;
  height: 100%;
  overflow-y: hidden;
}

.app-container {
  min-height: 100%;
}

.igw {
  height: auto !important;
  overflow-y: hidden !important;
}

.app-content,
.app-swipe-content {
  overflow-y: auto !important;
  height: 100% !important;
}

.vert-container.confirmation .segment {
  padding: 1em;
}

.sfp {
  height: 100%;
}

.sfp>.message {
  background-color: var(--off-black-2) !important;
  color: var(--off-white-1) !important;
  margin: .5em;
}

/* ova */
div[data-swipeable="true"]:has(.app-swipe-content.groupedItems) {
  overflow: hidden !important;
  -webkit-overflow: hidden !important;
}

.menu-select-container,
.filter-group-container,
.images-container {
  width: 100%;
  display: grid;
}

/* APP CONTAINER */
.app-container {
  overflow-x: hidden;
  overflow-y: hidden !important;
  grid-template-rows: auto auto 1fr auto auto auto;
  grid-template-columns: 100%;
  grid-template-areas:
    "app-menu"
    "app-banner"
    "app-content"
    "app-banners"
    "app-navigation"
    "app-homepage";
}

.app-container.segment {
  padding: 0;
}

.app-container.blt {
  grid-template-areas:
    "app-menu"
    "app-banner"
    "app-banners"
    "app-content"
    "app-navigation"
    "app-homepage"
}

.dt-mode .app-container {
  grid-template-rows: auto 1fr auto;
  grid-template-columns: var(--gtr-1-8-1) !important;
  grid-template-areas:
    "app-sticky app-sticky app-sticky"
    ". app-content ."
    "app-footer app-footer app-footer";
  scrollbar-width: none;
}

.dt-mode .app-container {
  height: auto;
  scrollbar-width: none;
  min-height: 100vh !important;
}

.dt-mode .app-container .app-header {
  width: 100%;
  z-index: 100;
  display: grid;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "app-menu"
    "app-navigation"
}

/* APP CONTENT */
.app-content {
  grid-area: app-content;
  scrollbar-width: none;
}

.app-content.ui-item {
  overflow-x: hidden;
}

.app-content.padd {
  padding: 8px;
  padding-top: 16px;
}

.app-content.padd .big-icon {
  text-align: center;
}

.app-content .image {
  margin: 0px 8px 8px 0
}

.app-content .normal .image {
  margin: 0
}

.app-content .header {
  vertical-align: top !important;
}

.app-content .group-swiper,
.app-content .group-swiper>div {
  overflow-y: hidden !important;
}


.app-content>.list {
  margin: 1em !important
}

.app-menu {
  grid-area: app-menu;
}

.app-banner {
  grid-area: app-banner;
}

.app-banners {
  grid-area: app-banners;
}

.app-header {
  grid-area: app-header;
}

.app-navigation {
  grid-area: app-navigation;
  margin-top: auto;
}

.app-menu>.top-menu>menu.right {
  align-self: flex-start !important;
}

.app-navigation .menu>.item>.dropdown>.icon {
  display: none;
}

.app-footer {
  grid-area: app-footer;
}

.app-sticky {
  grid-area: app-sticky;
}

.dt-mode .app-container>.app-header>.app-menu {
  width: 100% !important;
}

.dt-mode .app-container>.app-header>.app-navigation {
  width: 100% !important;
}

.app-container.dtm {
  padding: 0 0em
}

.dt-mode .app-container.mcnf>.app-content {
  padding-top: 4em !important
}

.app-container .menu:not(.menu-slider):not(.sports-active) {
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  padding: 0;
}

/* ************************************************ */

/* APP SWIPE */
.app-swipe-container {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "app-swipe-menu"
    "app-swipe-content"
}

.app-swipe-menu {
  grid-area: app-swipe-menu;
}

.app-swipe-content {
  grid-area: app-swipe-content;
  height: 100%;
}

.ms-button-group {
  padding: .5em .5em 1em .5em !important;
  text-align: center;
  /* margin-bottom: .25em; */
  border-radius: 4px;
  width: 100%;
  overflow-x: auto;
}

.app-swipe-menu>.menu {
  padding: 0 !important;
  overflow-x: auto;
  scrollbar-width: none;
  border-bottom: 0px !important;
  padding-bottom: 2px !important;
  width: 100% !important;
}

.menu-alphabet:not(.vertical) {
  width: 100%;
}

.app-swipe-menu>.menu>.item {
  margin-bottom: 2px !important
}


.swipe-tab {
  font-size: .9rem;
  border-bottom-width: 2px !important;
}

.swipe-tab.item,
.swipe-tab.item.active {
  padding: 1em 1.25em .75em 1.25em !important
}


/* HCF */
.hcf-container {
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "hcf-header"
    "hcf-content"
    "hcf-footer";
}

.hcf-header {
  grid-area: hcf-header;
  border-bottom: var(--thin-border);
  font-weight: bold;
}

.hcf-content {
  grid-area: hcf-content;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
}

.hcf-header.padd,
.hcf-content.padd {
  padding: .5em;
}

.hcf-content.vpl div {
  display: contents;
}

.hcf-content.ctr>.segment {
  height: 100%;
}

.hcf-footer {
  grid-area: hcf-footer;
  border-top: var(--thin-border);
}

.hcf-footer:not(.settings-menu-footer) {
  padding: .5em;
}

.hcf-footer.modify>.button:nth-child(1) {
  float: left !important;
}

.hcf-footer.modify>.button:nth-child(2) {
  float: right !important;
}

.hcf-content form,
.hcf-content.cfm {
  margin: 1em;
  padding-bottom: 1em;
}

.hcf-container.vid>.hcf-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hcf-container.vid>.hcf-header,
.hcf-container.vid>.hcf-footer {
  z-index: 1000;
}

.hcf-header.mod {
  padding: 1em
}

.hcf-header.mod>.ui.dropdown {
  float: right;
  width: 80px;
  min-width: 5em;
  font-size: .8rem;
}

.hcf-footer.mod {
  padding: 1em
}


/* HCF */
.hcs-container {
  grid-template-columns: var(--gtc-100);
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "hcs-top"
    "hcs-bottom";
}

.hcs-top,
.hcs-bottom {
  padding: .5em !important
}

/* ************************************************ */

/* HACF */
.hcaf-container {
  grid-template-columns: var(--gtc-100);
  border-radius: 10px;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "hcaf-header"
    "hcaf-alert"
    "hcaf-content"
    "hcaf-footer";
}

.hcaf-header {
  padding: 1em 1em;
  border-radius: 10px;
}

.hcaf-header .icon:nth-child(1) {
  margin-right: 10px !important;
}

.hcaf-content {
  grid-area: hcaf-content;
  overflow-x: hidden;
  scrollbar-width: thin;
  height: 100%;
}

.hcaf-alert {
  grid-area: hcaf-alert;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.hcaf-footer {
  grid-area: hcaf-footer;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.hcaf-content form,
.hcaf-content.cc {
  margin: 1em;
  padding-bottom: 1em;
}

.hcaf-content.padd {
  padding: .5em;
}

/* ************************************************ */

/* GRID ICONS */
.grid-icons-container {
  grid-template-columns: var(--gtc-100);
  overflow-x: hidden;
  grid-template-rows: 1fr;
  grid-template-areas:
    "grid-icons-content"
}

.grid-icons-container {
  scrollbar-width: none;
  padding: .5em;
}

.grid-icons-content .column {
  align-self: stretch;
  padding: .5em !important;
}

.grid-icons-content .row {
  padding: 0 !important;
}

.grid-icons-content .segment .icon {
  text-align: center;
  margin-top: 10px
}

.grid-icons-content .segment div {
  text-align: center;
  font-size: 1rem;
  margin-top: 10px
}

.grid-icons-content {
  padding: .5em;
}

.grid-icons-content {
  padding: .5em;
  align-items: flex-start !important
}

.grid-icons-content .segment,
.grid-settings-icon {
  text-align: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 8px !important;
  border-style: solid !important;
}

.grid-icons-content>.divider.horizontal {
  padding: .5em;
  font-size: .9rem;
}

/* END - GRID ICONS */

/* HELP CONTAINER*/
.help-container {
  height: auto !important;
  padding: 0 !important;
  margin-top: .5em;
}

.help-container>.message {
  padding-bottom: .75em;
}

.help-container .label.attached {
  margin: 0 !important;
  padding: .75em !important
}

.help-container .label.attached .icon {
  margin: 0 !important;
  padding: 0 !important
}

.help-container .list>.item .header {
  padding: .5em;
}

.help-container .list>.item .description {
  padding: 1em
}

.help-header {
  padding: .5em;
  font-weight: bold;
}

.help-content {
  padding: 1em;
}

.help-group-header {
  font-weight: bold;
  font-size: 1.1rem;
}

.help-seg .help-group-item-header {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.help-seg:not(.doc) .help-group-item-description {
  font-weight: bold;
}

.help-seg.doc mark {
  font-weight: bold;
}

/* extra */
.help-seg {
  height: 100%;
  padding: .5em !important;
  border: 1px solid rgb(68, 68, 68) !important
}

.help-seg .header {
  margin-bottom: .25em !important;
}

.help-seg>.message {
  margin-bottom: 2em !important;
  padding: .75em !important;
  box-shadow: none !important;
}

.help-seg>.message>.content {
  padding: .75em !important
}

.help-edit-seg {
  margin-top: .5em !important;
}

.help-accordion>.title {
  padding: .5em !important
}

.help-accordion>.title>.icon {
  float: right !important;
}

.help-edit textarea {
  width: 100%;
  padding: 1em
}

.help-list .label {
  margin: .5em !important
}

.help-edit label {
  padding-bottom: 1em !important
}

.help-list {
  padding: .5em
}

.help-edit-edit>div:first-child {
  margin-bottom: 1em;
}

.help-edit-edit {
  padding: .5em !important;
  margin-top: .5em;
  border-radius: 8px;
}

.help-seg>.help-accordion>.content.active>.message {
  padding: 1em !important
}

/* IMAGES CONTAINER */
.images-container {
  grid-template-areas:
    "images-header"
    "images-content";
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr;
  margin-bottom: 1em;
}

.images-header {
  grid-area: images-header;
  padding: 1em;
  border-bottom: var(--thin-border);
  font-weight: bold;
}

.images-content {
  grid-area: images-content;
  padding: 10px;
  min-height: 40px;
  margin-bottom: .5em;
}

.images-content>div>div,
.images-content>.sw {
  padding: 18px;
}

.img-80 {
  max-width: 80px !important
}

.img-100 {
  max-width: 100px !important
}

.img-140 {
  max-width: 140px !important
}

.img-200 {
  max-width: 200px !important
}

.images-header {
  width: 100%;
  display: grid;
  grid-template-columns: var(--gtc-2x);
}

.images-content:not(.viewManifest)>.images-picker {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.images-content:not(.viewManifest)>.images-picker .span-image {
  max-width: 100px;
  margin: .25em
}

.images-content.viewManifest>.images-picker .span-image {
  margin: .25em
}

.images-content>.images-picker .image-manifest>.image {
  max-width: none
}

/* file-selected */
.file-selected>img,
.file-unselected>img {
  margin: 0 auto !important;
}

.file-selected {
  border: 2px solid rgba(53, 241, 7, 0.924);
}

.file-unselected {
  border: 2px solid #cfcfcf;
}

.images-manifest .span-image {
  max-width: 100px;
  margin: .25em
}

.images-manifest .image-manifest>.image {
  max-width: none
}

/* END - IMAGES CONTAINER */


/* INLINE CONTAINER */
.inline-container {
  width: 100%;
  margin-bottom: .5em;
  font-size: small;
}

.inline-container .content>.checkbox>input {
  height: 1rem !important;
}

.inline-container .content>.checkbox {
  float: right;
  font-size: small;
  margin-left: 1em
}

.inline-container .content>.checker {
  float: right;
  font-size: small;
  margin-left: 1em;
  margin-left: 2em;
}

.inline-container .content:nth-child(1) {
  padding: 1em !important
}

.inline-container .content.extra {
  padding-right: 0 !important;
}

.inline-container .content.extra {
  padding: 1em !important
}

.inline-container .content.extra.checkers {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.inline-container .header {
  margin-bottom: .5em;
  font-size: small;
}


.header.in-line>div>div:first-child {
  float: left;
}

.header.in-line>div>div:last-child {
  float: right;
}

.inline-item-container {
  width: 100%;
  font-size: .9rem;
}

/* END - INLINE CONTAINER */

/* LABEL CONTAINER */
.label-container,
.label-content {
  grid-template-columns: var(--gtc-1x);
  grid-gap: 4px;
}

.landing-container.srt {
  grid-template-areas:
    "label-header"
    "label-save"
    "label-content";
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto auto 1fr;
}

.label-container .ui.label {
  padding: .5em !important;
  margin: 0 !important;
}

.label-container>.label-header {
  padding: 1em !important;
  border-bottom: var(--thin-borders);
  font-size: .7em;
}

.label-container>.label-save {
  padding: .5em !important;
  border-bottom: var(--thin-borders);
}

.label-container>.label-header>.check-toggle {
  float: right;
  margin-right: 1em;
}

.hcf-header>.check-toggle,
.hc-header>.check-toggle {
  margin-bottom: 1em !important;
}

.label-content {
  padding: .5em;
  max-height: 300px;
  scrollbar-width: thin;
}

/* MAP */

.map-container {
  padding: 0 !important;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto;
  grid-template-areas:
    "map-map"
}

.map-map {
  grid-area: map-map;
  position: relative;
  height: 30vh;
  width: 100%;
}

.map-container.split {
  grid-template-rows: 6fr 3fr 1fr;
  grid-template-areas:
    "map-map"
    "map-list-list"
}

/* MAP-CONTAINER */
.map-list-container {
  width: 100%;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: 2fr 2fr;
  grid-template-areas:
    "map-list-map"
    "map-list-list";
}

.map-list-container.gm {
  position: absolute;
}

.map-list-map {
  grid-area: map-list-map;
  position: relative;
  display: block;
}

.map-list-map .gm-style {
  font-size: 8pt !important
}

.map-list-list {
  grid-area: map-list-list;
  scrollbar-width: thin;
  padding: 0;
}

.map-list-list .item>.icon {
  width: 2em !important;
  display: inline-block;
  position: relative;
}


.map-map,
.map-address-map,
.map-address-map>div,
.map-address-map>div>div {
  height: 100%;
}

.map-address-address {
  padding: 0 .5em;
}

.map-address-url {
  padding: 0 .5em;
  margin: auto;
}

.map-address-name {
  padding: 1em .5em .5em .5em;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

/* map-address-container */
.map-address-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 5fr 1fr;
  text-align: center;
}

.map-address-container>div:not(:first-child) {
  padding-bottom: .5em;
}

.map-address-container.full {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 4fr 1fr 1fr 1fr;
}

.map-simple {
  height: 100% !important;
  width: 100% !important;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}

/* ************************************************ */

/* MAP LIST  CONTAINER */
.content-content.mapList {
  padding: 0;
  margin: 0 !important;
}

.content-content.mapList>div {
  padding: 0;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: 100%;
  grid-template-areas:
    "map-list-container-a"
}

.map-list-container-a {
  grid-area: map-list-container-a;
}

/* ************************************************ */

/* MESSAGES */

.messages-container {
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "messages-content"
    "messages-footer";
}

.messages-content {
  grid-area: messages-content;
  scrollbar-width: none;
  padding-bottom: 3em !important;
}

.message-container {
  grid-template-rows: auto 1fr;
  padding: 1em .5em;
}

.message-line {
  display: grid;
  width: 100%;
  grid-template-columns: var(--gtc-1-5-1);
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: "message-icon message-message message-date";
  margin-top: 1em;
}

.message-line.au {
  grid-template-areas: "message-date message-message message-icon";
}

.message-icon {
  grid-area: message-icon;
}

.message-icon .list {
  float: right !important;
}

.message-from {
  grid-area: message-from;
  font-weight: bold;
}

.message-message {
  grid-area: message-message;
  border: var(--thin-border);
  padding: 1em .5em;
  border-radius: 8px;
}

.message-date {
  grid-area: message-date;
  font-weight: lighter;
  font-size: 9pt;
}

.message-from>.label {
  margin-bottom: .25em;
}

.message-line.au>.message-date,
.message-line>.message-message,
.message-line>.message-icon {
  text-align: left;
}

.message-line>.message-date,
.message-line.au>.message-message,
.message-line.au>.message-icon {
  text-align: right;
}

.message-line.au>.message-message {
  margin-left: 2em;
  margin-right: 0
}

.message-line>.message-message {
  margin-left: 0;
  margin-right: 2em
}

/* ************************************************ */


/* MENU ROW CONTAINER */
.menu-row-container {
  display: flex;
  gap: 1em !important;
  margin: 1em 0;
  padding-right: 0 !important;
  width: 100%;
  align-items: center;
}

.menu-row-container .menu-row-container.button {
  max-width: 300px !important;
}

.menu-row-container .button {
  margin-top: 2em;
}

/* END - MENU ROW CONTAINER */

/* menu-select */
.menu-select>.item {
  padding: .25em !important;
}

.menu-select .segment {
  padding: .25em
}

.menu-select.quantity>.item {
  padding: .5em !important;
}


/* MENU SELECT CONTAINER */
.menu-select-container {
  padding-top: .5em;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "menu-select-header"
    "menu-select-toggles"
    "menu-select-items"
  ;
}

.menu-select-header {
  padding: 0 0 .5em;
  font-weight: 700;
}

.menu-select-header>.label {
  margin-left: 1em !important;
}

.menu-select-header>.checker {
  float: right;
}

.menu-select-toggles {
  padding: .5em;
  margin-bottom: .5em;
  border-bottom: var(--thin-border)
}

.menu-select-toggles .checkbox:nth-child(2) {
  float: right;
  margin-right: 10px
}

.menu-select-toggles .checkbox>label {
  font-size: small !important;
}

.menu-select-items {
  max-height: 300px;
  scrollbar-width: thin;
  overflow-y: auto !important;
}

.menu-select-items .item {
  font-size: .7rem;
  padding: .75em 1em !important
}

.menu-select-container.adding {
  margin-bottom: 1em;
}

/* END - MENU SELECT CONTAINER */

/* ************************************************ */

/* MODIFY CONTAINER */
.modify-container {
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "modify-header"
    "modify-sub-header"
    "modify-content"
    "modify-footer";
}

.modify-container.sw {
  grid-template-rows: 1fr;
  grid-template-areas:
    "modify-content"
}

.modify-content {
  grid-area: modify-content;
  overflow-x: hidden;
  padding: .5em 1em;
  scrollbar-width: thin;
}

.modify-content>.form {
  margin-bottom: 1em;
}

.modify-content.dataTable {
  padding: .5em .5em;
}

.modify-content.direct {
  padding: .25em;
}

.modify-footer {
  grid-area: modify-footer;
  text-align: center;
  padding: .5em;
  border-top: var(--thin-border);
}

.modify-header .checkbox {
  margin-left: 1em;
}

.modify-header .icon:nth-child(1) {
  margin-right: 1em;
}

.modify-header>.grid>.column:nth-child(1) {
  font-weight: bold;
  display: inline-flex !important;
}

.modify-header {
  grid-area: modify-header;
  padding: 1em
}

.modify-sub-header {
  grid-area: modify-sub-header;
  border-bottom: var(--thin-border);
  padding: 1em 1em;
  text-align: right;
}

/* END - MODIFY CONTAINER */

/* PAGE WRAPPER */
.page-wrapper-container {
  height: 100vh;
  width: 100%;
}

.page-wrapper {
  height: 100vh;
  width: 40%;
  margin: auto;
}

.page-wrapper.mobile {
  width: 100%;
}

.page-wrapper>div:nth-child(1),
.page-wrapper>div:nth-child(3) {
  display: none;
}

.page-wrapper>div {
  height: 100vh;
}

.page-wrapper.print {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: var(--gtc-1-3-1);
  margin: 0 auto;
  width: 100%;
}

.page-wrapper.print>div:nth-child(2) {
  border: 1em solid rgb(240, 240, 240);
  border-radius: 10px;
  height: 100%;
}

.page-wrapper.print {
  display: contents
}

/* END PAGE WRAPPER */


/* push-container */
.push-container {
  padding: .05em .15em;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr;
}

.push-container>.push-header {
  padding: 0 0 .1em 0;
}

.push-container>.push-header.test {
  display: flex;
}

.push-container>.push-header>.push-caption {
  padding: .5em;
}

.push-container>.push-content {
  padding: .25em .15em 0 .15em;
  overflow-x: hidden;
  height: 100%;
}

.push-container>.push-content.np {
  padding: 0;
  height: 100%;
}

/* end push-container */

/* ************************************************ */

/* SLIDER CONTAINER */
.slider-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 1em 0;
}

.slider-container>div:nth-child(1) {
  width: 25%;
  text-align: left;
}

.slider-container>div:nth-child(2) {
  width: 25%;
  text-align: center;
}

.slider-container>div:nth-child(3) {
  width: 50%;
  text-align: right;
  margin: .5em;
  margin-left: 1.5em;
}

.slider-container>div:nth-child(2)>.label {
  min-width: 40px;
}

/* END SLIDER CONTAINER */

/* ************************************************ */

/* SLIDING */
.sliding-container {
  margin: 0;
  padding: 1em 0
}

.sliding-container>.header {
  padding-left: 1em !important;
  padding-right: .5em !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
}

/* MOTION */

.motion-container.landing,
.motion-container.page,
.motion-container.pageItem,
.motion-container.splash {
  width: 100%;
  height: 100%;
  z-index: 9000;
}

.motion-container.pageItemElement {
  width: 100%;
}

.cards-container>.motion-container {
  margin-bottom: 7px;
}

.motion-container.element {
  width: 100%;
}

/* ************************************************ */

/* SPLASH CONTAINER */
.splash-container {
  height: 100%;
  width: 100%;
}

.splash-content {
  overflow-x: hidden;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    "splash-text"
    "splash-logo"
    "splash-footer";
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat !important;
}

.splash-text {
  text-align: center;
  font-size: 1.5rem;
  margin: auto 0
}

.splash-content>.splash-logo {
  padding: 2em;
  max-width: 280px;
}

.splash-content>.splash-text {
  padding: 1em;
}

.splash-content.desktop {
  height: 100%;
  width: 100%;
}

.splash-content.fh {
  height: 100vh !important;
}

.splash-content.mobile {
  height: 100vh;
}

.splash-content.preview {
  height: 600px;
}

.splash-content {
  max-width: 100%;
}

.splash-text {
  grid-area: splash-text;
}

.splash-footer {
  grid-area: splash-footer;
  bottom: 0px;
}

.splash-logo {
  grid-area: splash-logo;
}

.splash-text>.image {
  max-width: 200px;
}

.splash-logo,
.splash-footer {
  bottom: 0px;
  position: relative;
  margin: auto auto
}

/* END - SPLASH CONTAINER */

/* ************************************************ */

/* START CONTAINER */
.start-container {
  position: fixed;
  overflow-x: hidden;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: 1fr 4fr 1fr;
  grid-template-areas:
    "start-logo"
    "start-content";
  /* "start-footer";  */
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat !important;
}

.start-container {
  max-width: 100%;
}

.start-container.fh {
  height: 100vh !important;
}

.start-container.desktop {
  height: 100%;
  width: 100%;
}

.start-container.mobile {
  height: 100vh;
}

.start-logo {
  grid-area: start-logo;
}

.start-content {
  grid-area: start-content;
  display: block;
}

.start-footer {
  grid-area: start-footer;
  bottom: 0px;
}

.start-logo,
.start-footer {
  bottom: 0px;
  position: relative;
  margin: auto auto
}

.start-content {
  text-align: center;
  font-size: 1rem;
  margin: auto 0;
  padding: 2em;
}

.start-container>.start-logo {
  padding: 2em;
  max-width: 180px;
}

/* END - START CONTAINER */

/* ************************************************ */

/* SWIPE-CONTAINER */
.swipe-container {
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas:
    "filter"
    "tab"
    "alpha"
    "app-content-swiper"
}

.search:not(.ua-search):not(.icon) {
  width: 100%;
}

.ua-search {
  min-width: auto !important;
  font-size: .7rem !important;
}

.app-tab-menu-swiper {
  grid-area: app-tab-menu-swiper;
  scrollbar-width: none;
  overflow-x: auto;
}

.app-content-swiper {
  grid-area: app-content-swiper;
  overflow-x: hidden;
  scrollbar-width: none;
}

/* ************************************************ */

/* TAB CONTAINER */
.tab-container {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "tab-menu"
    "tab-content"
}

.tab-menu {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  grid-area: tab-menu;
  margin-bottom: 0 !important;

}

.tab-content {
  grid-area: tab-content;
  width: 100%;
}

.tab-content.tbl {
  overflow: hidden;
}

.tab-content>.form,
.tab-content>.map-list-container {
  padding: .75em !important;
  max-width: 100%;
}

.tab-button {
  padding: .25em 0;
  display: flow-root;
  margin: 1em 0;
  text-align: right;
}

/* END - TAB CONTAINER */

/* ************************************************ */

/* TABLE CONTAINER */
.table-container {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-search"
    "table-content";
}

.table-container.normal {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-diff"
    "table-content"
}

.table-container.normal.count {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-count"
    "table-content"
}

.table-container.normal.search {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-search"
    "table-content"
}

.table-container.normal.search.props {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "table-search"
    "table-props"
    "table-content"
}

.table-container.normal.toggle.props {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "table-props"
    "table-diff"
    "table-content"
}

.table-container.normal.foot {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "table-diff"
    "table-content"
    "table-footer";
}

.table-container.normal.toggle {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "table-diff"
    "table-content"
}

.table-count {
  grid-area: table-count;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-search {
  grid-area: table-search;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-props {
  grid-area: table-props;
  padding: 1em;
  text-align: right;
  border-bottom: var(--thin-border)
}

.table-diff {
  grid-area: table-diff;
  padding: 1em;
  border-bottom: var(--thin-border)
}

.table-footer {
  grid-area: table-footer;
  padding: .5em 0;
  text-align: center;
  border-top: var(--thin-border)
}

.table-content {
  grid-area: table-content;
  font-size: .8rem !important;
  padding: 0;
  height: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
}

.table-content.np {
  padding: 0;
}

.dbl-lbl>.label {
  margin-left: .5em !important
}


.table-count {
  display: grid;
  grid-template-columns: var(--gtc-2x);
  padding: .25em;
}

.table-wrapper>.table {
  font-size: .8rem;
}

.table-wrapper>.table th:first-child,
.table-wrapper>.table td:first-child {
  width: 50px !important
}

.table-wrapper>.table>thead>tr>th,
.table-wrapper>.table>tbody>tr>td {
  width: fit-content !important;
}

.trcl {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.trcl>.label {
  margin-left: 0 !important;
  width: fit-content;
}

/* END - TABLE CONTAINER */


/* TAG FILTER */
.tag-filter-container {
  padding: .5em;
}

.tag-filter-content {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.tag-filter-content>.label {
  text-align: left;
}

/* ************************************************ */

/* update-container */
.update-container {
  border: 3px solid rgba(34, 36, 38, .15);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%)
}

.update-container>.header {
  text-align: center;
  font-weight: bold;
}

.update-container label,
.update-container .input,
.update-container .menu,
.update-container .menu>.item,
.update-container .menu>.item>span,
.update-container .dropdown {
  font-size: .8rem;
}

/* end update-container */


/* UPLOADER CONTAINER */
.uploader-container {
  overflow-x: hidden;
  border: 1px dashed lightgray !important;
  padding: .5em !important;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas:
    "uploader-header"
    "uploader-path"
    "uploader-progress"
    "uploader-file-name"
    "uploader-content"
    "uploader-manifest"
}

.uploader-container.url {
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas:
    "uploader-header"
    "uploader-path"
    "uploader-url"
    "uploader-progress"
    "uploader-content"
}

.uploader-header {
  grid-area: uploader-header;
  padding: 0em;
  text-align: right;
}

.uploader-url {
  grid-area: uploader-url;
  padding: 8px;
}

.uploader-path {
  grid-area: uploader-path;
  padding: 1em;
  font-size: .7rem;
  text-align: center;
}

.uploader-progress {
  grid-area: uploader-progress;
  padding: 8px;
}

.uploader-file-name {
  grid-area: uploader-file-name;
  padding: 8px;
}

/* border-top: var(--thin-border); */
.uploader-content {
  grid-area: uploader-content;
  padding: 1em;
}

.uploader-manifest {
  grid-area: uploader-manifest;
}

.uploader-manifest>img {
  padding: .5em
}

.uploader-url>.input {
  width: 100%;
}

.dark-mode .uploader-header {
  border-bottom: var(--dark-thin-border-settings);
}

.dark-mode .uploader-path {
  border-bottom: var(--dark-thin-border-settings);
}

.dark-mode .uploader-progress {
  border-top: var(--dark-thin-border-settings);
}

.uploader-content .uploader-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}

.settings-dark-mode .uploader-header {
  border-bottom: var(--dark-thin-border-settings);
}

.settings-dark-mode .uploader-path {
  border-bottom: var(--dark-thin-border-settings);
}

.settings-dark-mode .uploader-progress {
  border-top: var(--dark-thin-border-settings);
}

.uploader-content .uploader-images {
  display: grid;
  grid-template-columns: var(--gtc-3x);
  gap: 1em;
}

/* END - UPLOADER CONTAINER */

/* AUL */

.aul-mods {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.aul-events {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.aul-events .label {
  padding: 1em;
  font-weight: bold !important;
}

.aul-events.crdi {
  flex-direction: row !important;
  justify-content: space-between;
  text-align: center;
}

.aul-events.crdi>.label,
.aul-events.crdi>.icon {
  width: 100%;
}

.aul-events>div>div:last-child {
  flex-direction: column !important;
  display: flex;
  gap: .5em 1em;
  flex-wrap: wrap;
}

/* FILE MANAGEMENT */

.grid-storage-images {
  width: 100%;
  display: grid;
  grid-template-columns: var(--gtc-4x) !important;
  padding: .5em;
}

.grid-storage-images.pagePdf,
.grid-storage-images.pageDirect,
.grid-storage-images.profileGallery {
  display: grid;
  row-gap: 1em;
}

.grid-storage-images.pdf {
  grid-template-columns: var(--gtc-1x) !important;
}

.grid-storage-images.pagePdf {
  grid-template-columns: var(--gtc-1-7) !important;
}

.grid-storage-images.pageDirect {
  grid-template-columns: var(--gtc-1-7) !important;
}

.grid-storage-images.profileGallery {
  grid-template-columns: var(--gtc-4x) !important;
}

.grid-storage-images.profileGallery {
  grid-template-columns: var(--gtc-4x) !important;
}

.file-management .grid-storage-images {
  grid-template-columns: var(--gtc-10x) !important;
}

.file-management .igw {
  border-right: 2px solid var(--off-white-4);
}

.file-management .igw .grid-storage-images {
  grid-template-columns: var(--gtc-6x) !important;
}

.file-management .image {
  max-width: 120px !important;
  height: auto !important;
}

/* IMAGE MAPPING */

.image-mapping.zone,
.image-mapping.location {
  display: grid;
  height: 100% !important;
  padding: .5em;
  gap: .5em;
}

.image-mapping.zone {
  grid-template-columns: var(--gtc-1-2);
}

.image-mapping.location {
  grid-template-columns: var(--gtc-2-3-2);
}

.image-mapping>div {
  border: 1px solid var(--off-white-1);
  border-radius: 8px;
  height: 100% !important;
  overflow-y: auto !important;
}

/* .image-mapping .list {
  height: 100% !important;
  overflow-y: auto !important;
} */

.image-mapping .list>.item {
  display: flex;
  padding: .5em !important;
  width: 100%;
  justify-content: space-between;
}

.image-mapping .list>.item>div {
  display: flex;
  padding: .5em !important;
  width: 100%;
  gap: 1em;
}

.image-mapping .list>.item>div:last-child {
  padding: .5em !important;
  justify-content: end;
  float: right;
}

.image-mapping .list>.item>div:last-child>div {
  justify-self: center !important;
}

.image-mapping .list>.item.sel {
  background-color: var(--off-white-1);
}

.image-mapping .list>.item>.label {
  float: right;
}

.image-mapping-list>.header {
  padding-top: 1rem;
}


.img-type-bordered {
  border: 2px solid rgb(73, 73, 73);
  border-radius: 8px;
}

.img-size-mini .image,
.img-size-tiny .image,
.img-size-small .image,
.img-size-mini .image,
.img-size-large .image,
.img-size-huge .image,
.img-size-massive .image {
  height: auto !important;
  object-fit: contain;
}

.img-size-mini .image {
  max-width: 20px !important;
  max-height: 20px !important;
}

.img-size-tiny .image {
  max-width: 40px !important;
  max-height: 40px !important;
}

.img-size-small .image {
  max-width: 60px !important;
  max-height: 60px !important;
}

.img-size-large .image {
  max-width: 80px !important;
  max-height: 80px !important;
}

.img-size-huge .image {
  max-width: 100px !important;
  max-height: 100px !important;
}

.img-size-massive .image {
  max-width: 120px !important;
  max-height: 120px !important;
}

.plus .image {
  max-width: none !important;
  max-height: none !important;
}


/* INDIVIDUAL CONTIAINERS */


/* END - ACTION CONAINTER */

/* CONFIRM BUTTONS */
.btn-confirm {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr;
  padding-bottom: .5em !important;
  /* margin-bottom: 8px !important */
}

.btn-confirm.alt-ib {
  grid-template-columns: 1fr 1fr 8fr 1fr;
}

.btn-confirm.alt-ib.alt-cb {
  grid-template-columns: 1fr 1fr 1fr 6fr 1fr;
}

.btn-confirm.help {
  grid-template-columns: 1fr 8fr 1fr 1fr;
}

.btn-confirm.no-commit {
  grid-template-columns: 1fr 8fr;
}

.btn-confirm.no-commit.help {
  grid-template-columns: 1fr 8fr 1fr;
}

.btn-confirm.confirmed {
  grid-template-columns: 8fr 1fr;
}

.btn-confirm .button {
  padding: 1em !important;
  border-radius: 0 !important;
}

.btn-confirm .button:nth-child(2) {
  text-align: left;
}

.btn-confirm.pending,
.btn-confirm.upload {
  grid-template-columns: 8fr 1fr !important;
}

.btn-confirm.alt-ib .button:nth-child(1),
.btn-confirm.alt-cb .button:nth-child(2) {
  border-right: 2px solid white;
}

.btn-confirm>.button.btn-input {
  padding: 0 .25em !important
}

.btn-confirm>.button.btn-input>.input {
  width: 100%;
}

.btn-confirm .rc-input-number {
  width: 100% !important;
}

.btn-confirm .rc-input-number-handler-wrap {
  width: 100% !important;
}

.btn-confirm .rc-input-number-input-wrap {
  width: 100% !important;
}

/* UPLOAD */
.upload-btn-wrapper {
  height: 34px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 30px !important;
  position: relative !important;
  left: 0 !important;
  /* top: -30px !important; */
  opacity: 0 !important;
  z-index: 1 !important;
}

.upload-btn-wrapper .btn,
.upload-btn-wrapper input[type=file] {
  width: 200px !important;
  height: 34px !important;
  position: absolute;
}

/* ADDRESS */

.address-container {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 30% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row];
}

.box {
  border-radius: 5px;
  padding: .25em .5em
}

.address {
  grid-column: span 2;
  grid-row: row 1;
}

.city {
  grid-column: span 2;
  grid-row: row 2;
}

.state {
  grid-column: span 1;
  grid-row: row 3;
}

.zip {
  grid-column: span 1;
  grid-row: row 3;
}

.address-container.mapp {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 30% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row] auto [row];
}

.address-container.mapp.url {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: [col] 50% [col] 70%;
  grid-template-rows: [row] auto [row] auto [row] auto [row] auto [row];
}

.box.mapp {
  border-radius: 5px;
  padding: .5em;
}

.map-view.mapp {
  grid-column: span 2;
  grid-row: row 1;
  width: 100% !important;
  height: 200px !important;
  position: relative;
}

.address.mapp {
  grid-column: span 2;
  grid-row: row 2;
}

.city.mapp {
  grid-column: span 2;
  grid-row: row 3;
}

.state.mapp {
  grid-column: span 1;
  grid-row: row 4;
}

.zip.mapp {
  grid-column: span 1;
  grid-row: row 4;
}


/* CALENDAR */
.form-dates {
  width: 100%;
  height: 100%;
}

.seg-cal-full {
  width: 100%;
  height: 100%;
}


.cal-months-container {
  width: 100%;
  height: 100%;
  padding: .1em;
  display: grid;
  grid-template-rows: auto 1fr;
}

/* .cal-months-container.sch {
  width: 50%;
  margin: auto;
} */

.cal-close {
  display: grid;
  gap: .2em;
  grid-template-columns: 1fr;
  margin-bottom: .15em;
}

.cal-close.today {
  grid-template-columns: 1fr 1fr;
}

.cal-close.missing.today {
  grid-template-columns: 2fr 5fr 2fr;
}

.cal-close>div {
  padding: .5em;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

.cal-months {
  width: 100%;
  height: 100%;
}

.cal-month {
  font-weight: bold;
  text-align: center;
  padding: .5em;
  border-radius: 4px;
}

/* CALENDAR CONTAINER */
.calendar-container {
  display: grid;
  padding: .25em;
  gap: .2em;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto repeat(5, 1fr);
  margin: auto;
}

.calendar-container>div {
  border-radius: 6px;
  padding: .5em;
  text-align: center;
}


.norm-count>span>div {
  margin: 0;
}

/* END - CALENDAR CONTAINER */

/* CALENDAR */
.cal-select-container {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
  margin: 0 0 .2em 0 !important
}

.cal-select-container>div {
  padding: 1em;
  text-align: center;
  font-weight: bold;
}

.cal-select-container>div:nth-child(0) {
  border-top: 1px solid grey
}

.cal-select-container>div {
  border-right: 1px solid grey
}

.cal-select-container>div {
  border-bottom: 1px solid grey
}

.cal-select-container>div:nth-child(0) {
  border-left: 1px solid grey
}

.cal-select-container>.item {
  font-weight: bold !important;
  padding: .2em !important
}

.cal-select-container>.item>.icon {
  margin: 0 !important
}

/* CALENDAR - MONTH */
.month-select-container {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background: #fff;
}

.month-select-container>div {
  padding: 1.78em;
  /* border: 1px solid grey; */
  text-align: center;
  font-weight: bold;
}

/* Q and A */

.questions-container {
  padding: .5em !important;
}

.questions-container>.header>.ui>i.icon:nth-child(1),
.questions-container>.content>.ui>i.icon:nth-child(1) {
  margin-right: .75em;
  margin-left: 0;
  font-size: 1.5em !important;
}

.questions-container>.header>.ui>i.icon:nth-child(3),
.questions-container>.content>.ui>i.icon:nth-child(3) {
  margin: 0;
  font-size: 1.5em !important;
}

.questions-container>.list>.item>.content>.description {
  padding-top: .5em;
}

.question-container>textarea {
  width: 100% !important;
  height: 100% !important;
  border: .1px solid lightgrey;
  outline: none;
  padding: .5em;
}

.questions-container .list>.item>.icon {
  float: right;
}

/* MENU HEADER */

.menu-header-topper-simple,
.menu-header-topper {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  padding: .5em;
  align-items: center;
  font-weight: bold;
  line-height: normal;
}

.menu-header-topper>div:nth-child(1) {
  align-self: flex-start;
}

.menu-header-topper>div:nth-child(2)>div:nth-child(2) {
  font-size: .7rem;
  font-weight: normal;
}

.menu-header-topper>div:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

.menu-header-topper-simple {
  grid-template-columns: var(--gtc-100);
  text-align: center;
  padding: 1em;
}

.menu-header-alt {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  padding: .5em;
  align-items: center;
}

.menu-header-alt>div:nth-child(1) {
  text-align: left;
}

.menu-header-alt>div:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

.menu-header-alt>div:nth-child(3) {
  text-align: right;
}

.menu-slim:not(.chk)>.item {
  padding: .75em 1em !important;
  font-weight: bold !important;
}

.menu-slim {
  border: 0 !important;
  height: 100%;
}

.menu-slim.pios {
  padding-top: .5em !important;
}

.menu-slim .item.iconed .icon:first-child {
  float: left !important;
  padding: 0 !important;
  margin-right: 1em !important;
}

.menu-nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  column-gap: .5em;
  row-gap: .5em;
  padding: 0;
}

.menu-nav>.label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  margin: 0 !important;
}

.menu-nav>.label>.icon {
  margin: 0 !important;
}

/* ALPHA */

.menu-alphabet-slider {
  display: grid !important;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr) !important;
}

.menu-alphabet:not(.vertical) {
  width: 100%;
}

/* SPLIT LIST */
.split-list,
.split-list-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow-y: hidden;
}

.split-list.zero {
  grid-template-columns: 1fr;
}

.split-list.three {
  grid-template-columns: 1fr 2fr;
}

.split-list-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.split-list .vert-container {
  height: 100%;
}

.split-list .vert-container>.header {
  text-align: center;
}

.split-list.vert {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
}

.split-list.vert.equal {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.split-list-3 .segment,
.split-list .segment {
  margin: 0;
  height: 100%;
}

.split-list .list {
  padding: .5em
}

.split-list.q {
  grid-template-columns: 1fr 3fr;
}

.split-list.qr {
  grid-template-columns: 3fr 1fr;
}

.split-list.q .form {
  padding: 1em !important
}

.split-list.three .footer div:first-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-self: baseline;
}

.split-list-3 .vert-container>.header {
  background-color: var(--off-black-5) !important;
}

.split-header-3 {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
}

.split-header-3:nth-child(n+2) {
  align-self: flex-end;
}

.split-header-4 {
  display: grid;
  grid-template-columns: 6fr 1fr 1fr 1fr;
}

.split-header-4:nth-child(n+3) {
  align-self: flex-end;
}

/* DELETE CONTAINER */
.delete-container {
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "delete-button"
    "delete-header"
    "delete-content";
}

.delete-button {
  grid-area: delete-button;
  border-bottom: var(--thin-border);
}

.delete-header {
  grid-area: delete-header;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.delete-content {
  grid-area: delete-content;
  scrollbar-width: none;
}

.delete-header>.label {
  margin-top: .5em !important
}

/* END - DELETE CONTAINER */

/* CONTENT CONTAINER */
.content-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "content-header"
    "content-content";
}

.content-container {
  height: 100%;
}

.content-content {
  grid-area: content-content;
  scrollbar-width: none;
  height: 100%;
}

.content-content>.cards.solo {
  margin: 0 !important;
}

.content-content>.seg-item-list>.item {
  padding: .5em 0em !important
}

.content-content.cardNameSectionOnly,
.content-content.cardTaggedOnly {
  padding: .35em
}

.content-content.static .card {
  border-left: black solid 1px !important;
}

.content-content.weather {
  margin: 0 !important;
}

.dt-off .content-content .card {
  margin-bottom: .5em !important;
}

.menu>.content-container>.content-content {
  height: auto !important;
  overflow-y: hidden !important;
  padding: .5em;
}

.content-content.cardNameSectionOnly .cards-container>.motion-container.element>.card:not(.static),
.content-content.cardNameSectionOnly .cards-container>.card:not(.static) {
  box-shadow: none !important;
  margin-bottom: .25em !important;
  border-radius: 0 !important;
  border: none !important;
}

/* ************************************************ */

/* CONVERSATIONS */

.conversation-line {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-template-areas:
    "conversation-icon conversation-from conversation-date"
    "conversation-icon conversation-message conversation-date";
  border-bottom: var(--thin-border);
  padding: .5em 0;
}

.conversation-icon {
  grid-area: conversation-icon;
}

.conversation-from {
  grid-area: conversation-from;
  font-weight: bold;
}

.conversation-message {
  grid-area: conversation-message;
  padding: .5em .5em
}

.conversation-date {
  grid-area: conversation-date;
  font-weight: lighter;
  font-size: 9pt;
  text-align: right;
}

.conversation-from>.label {
  margin-bottom: .25em;
}

/* ************************************************ */


/* DROP GROUP CONTAINER */
.drop-group-container {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: .25em;
  border: var(--sort-borders);
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "drop-group-header"
    "drop-group-content";
}

.drop-group-container.settingsGroups .item>.header>div {
  display: flex;
  flex-direction: column;
}

.drop-group-container.settingsGroups .item>.header>div>div:nth-child(1) {
  font-size: .7rem;
  /* columns: blue !important; */
}

.drop-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75em 1.5em;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
  /* z-index: 2; */
}

/* .menu.transition {
  z-index: 3;
} */

.drop-group-content {
  min-height: 46px;
  margin: 0;
  display: block;
}

.drop-group-content.nd {
  border: none;
}

.drop-group-header>.dropdown {
  float: right;
}

.drop-group-header>.dropdown,
.drop-group-header>.dropdown>.menu>.item {
  font-size: .8rem !important;
}

.drop-group-container.fixed {
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
}

.diplay-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--off-black-2);
}

.diplay-grid.l3 {
  grid-template-columns: repeat(3, 1fr)
}

.diplay-grid.l4 {
  grid-template-columns: repeat(4, 1fr)
}

.diplay-grid.l5 {
  grid-template-columns: repeat(5, 1fr)
}

.diplay-grid.l6 {
  grid-template-columns: repeat(6, 1fr)
}

.diplay-grid.l7 {
  grid-template-columns: repeat(7, 1fr)
}

.diplay-grid.l8 {
  grid-template-columns: repeat(8, 1fr)
}

.drop-list .label {
  width: 100%;
  margin-bottom: .5em;
  padding: .75em;
}

.drop-list,
.drop-list.active {
  padding: 0;
  height: auto;
  min-height: 46px;
}

.ddp .label,
.drop-list .label {
  width: 100% !important;
  padding: 1em !important;
  margin-left: 0 !important;
}

.drop-list>div>label {
  display: flex;
}

.grid.drop-list .column {
  padding: .2em !important;
}

.ddp.label>div>div {
  padding: .2em !important;
}

.lbl-additional {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.drag-number {
  margin-bottom: .25em !important;
}

.drag-number>.label {
  margin: 0 .25em 0 0 !important;
}

.drag-number>.label {
  width: 100% !important;
  padding: .5em !important;
}

.drag-number>.label>.label {
  width: 24px !important;
  padding: .5em !important;
  margin: 0 1em 0 0 !important;
  text-align: center;
}

.drag-number>.label>.label:nth-child(2) {
  float: right;
}

.drag-number>.label>.label:nth-child(3) {
  float: right;
  width: auto !important;
}

/* dragDropper */
.dd-menu {
  padding: .5em;
}

.dd-menu-menu>.item,
.dd-menu>.item {
  display: grid !important;
  padding: .5em !important;
  align-items: center !important;
  grid-template-columns: auto 1fr;
  gap: .5em;
  border-radius: 4px;
}

.dd-menu>.item.ls {
  grid-template-columns: auto 1fr auto !important;
}

.dd-menu>.item>.label {
  padding: .5em !important;
}

.dd-menu-menu>.item {
  margin: .5em !important
}

.ddp .label {
  width: 100% !important;
  border-radius: 4px !important;
  padding: .5em !important;
}

.ddp.label>div,
.ddp.simple>div,
.ddp.viewSettingsMenu>div {
  border-radius: 4px;
  font-weight: bold;
  margin: 0 !important;
  padding: 0 !important;
}

/* THIS IS THE div that is dragged */
.ddp {
  font-weight: bold;
}

.drop-header {
  padding: .5em;
  margin-bottom: 0em;
  font-weight: bold;
}

/* DROP CONTENT */
.drop-content {
  min-height: 46px;
  padding: 0 .25em;
  padding: .25em
}

.drop-content .label {
  width: 100% !important;
  margin-bottom: .5em !important;
  padding: .5em !important
}

.drpi {
  border-bottom: 3px solid red;
}

/* END - DROP GROUP CONTAINER */


/* FILTER */
.filter-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "filter-header"
    "filter-selected"
    "filter-content"
}

.menu-filter .dropdown>.menu {
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-selected {
  padding: .5em;
  border-bottom: var(--thin-border);
}

.filter-content {
  grid-area: filter-content;
  padding: .5em;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-selected {
  grid-area: filter-selected;
  padding: .5em;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.filter-header {
  padding: .5em;
  border-top: var(--thin-border);
  display: flex;
  flex-direction: column;
  gap: .25em;
}

.filter-selected .label {
  margin: .2em;
}

/* ************************************************ */

/* FILTER ITEMS */
.filter-group-container {
  grid-template-columns: var(--gtc-100);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "filter-group-header"
    "filter-group-footer"
}

.filter-group-header {
  padding: .5em;
  font-weight: bold;
  border-bottom: var(--thin-border)
}

.filter-group-content {
  padding: .5em
}

.filter-group-content>.label {
  margin: .2em !important
}

/* ************************************************ */

/* FORM CONTAINER */
.form-container {
  height: 100%;
  scrollbar-width: thin;
  padding: 1em;
}

.form-container.simp input {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 1px solid var(--off-white-3) !important;
}

.form-container.simp .input,
.form-container.simp .dropdown {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
}

.form-container.simp .dropdown {
  border-bottom: 1px solid lightgray !important;
}

.form-container.simp .dropdown.icon {
  border: none !important;
}

[formkey='dataSource'] {
  grid-template-rows: 5fr 1fr !important;
}

.form-buttons {
  padding: 1em 0 !important
}

.form-container.dm .form:not(.mlc) {
  padding-bottom: 4em !important;
}

.form-container.dm>.form {
  margin: .5em;
  padding-bottom: 4em !important;
}

.form-container.dm {
  padding: 0;
}

.form-container.segment {
  padding: 0 !important;
}

.form-container.sf .form {
  padding-bottom: 4em !important;
}

.form-container.sf>.form {
  margin: .5em;
  padding-bottom: 4em !important;
}

.form-container.sf {
  padding: .5em !important;
}

.form-container:not(.inverted) .divider:not(.text) {
  margin: 0 0 1em 0 !important;
  padding: .5em;
}

.form-container .divider>.icon {
  margin-right: .5em !important;
}

.form-container:not(.inverted) .divider.emph:not(.text) {
  margin: 0 0 1em 0 !important;
  padding: .5em;
}

.form-container .divider.alt {
  text-transform: lowercase;
}

/* END - FORM CONTAINER */


/* GOOGLE DATA CONTAINER */
.google-data-container {
  padding: 0;
  overflow-y: hidden !important;
  overflow-x: hidden;
}

.google-data-container>.gst {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.google-data-container>.segment,
.google-data-container>.gst,
.google-data-container>.gst>.menu,
.google-data-container>.gst>.tab,
.google-data-container>.gst>.tab>.table-wrapper,
.google-data-container>.gst>.tab>.table-wrapper>.table {
  width: 100% !important;
}

.gst>.segment.tab {
  display: contents !important;
}

.google-data-container>.gst>.tab>.table-wrapper {
  height: 100%;
}

.google-data-container>.gst>.tab>.table-wrapper>.table {
  position: absolute;
}

.google-data-container .tv-tr>td:first-child {
  background-color: var(--sem-green);
  text-align: center;
}

.google-data-container .tv-tr-new>td:first-child {
  background-color: var(--sem-red);
  text-align: center;
}

.google-data-container .table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
}

.gst {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr;
}

.gst.adv-tab {
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
}

.gst.bot {
  grid-template-rows: 1fr auto;
}

.vert-container.wrp-sub .table-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.gst,
.gst>.grid,
.gst>.grid>.column,
.gst>.menu,
.gst>.tab,
.gst>.tab>.table-wrapper,
.gst>.tab>.table-wrapper>.table {
  width: 100% !important;
}

.gst>.tab,
.gst>.grid,
.gst>.grid>.column,
.gst>.tab .pushable,
.gst>.tab .pusher,
.gst>.tab>.table-wrapper {
  height: 100%;
}

.gst>.tab>.table-wrapper>.table {
  position: absolute;
}

.gst>.menu {
  overflow-y: auto;
  border-radius: 0;
}

.gst.vit>.menu {
  margin-bottom: 0 !important;
  padding: .25em;
}

.gst.vit>.tab {
  padding: 0;
  margin-top: 0 !important;
  border: 0;
}

.gst>.menu>.item,
.gst>.menu>.item.active {
  font-weight: bold;
}

/* GRID CHECK CONTAINER */

.grid-check-container {
  padding: 0 !important
}

.grid-check-container .label:nth-child(2) {
  border-left-width: 8px !important;
  border-right-width: 8px !important;
  font-weight: 700;
}

.grid-check-container .grid-check-header {
  font-weight: 700;
}

.grid-check-container .label:not(.applied) {
  opacity: .5;
}

.grid-check-container.adding .segment {
  margin-top: .5em !important;
  margin-bottom: .5em !important;
}

/* END - GRID CHECK CONTAINER */

/* GRID LIST CONTAINER */

.grid-list-container {
  height: 100%;
}

.grid-list-container>.list {
  padding: .25em .5em !important;
}

.grid-list-container>.list>.item {
  padding: .75em 0 !important;
}

.map-list-list>.menu>.item,
.grid-list-container>.menu>.item {
  padding: 1em .5em !important;
}

.grid-list-container>.menu>.item>.image:first-child {
  margin-right: .5em !important;
}

.grid-list-container>.menu>.item>.icon:first-child {
  float: left !important;
  margin-right: .5em !important;
}

.grid-list-container>.menu>.item>.icon,
.grid-list-container>.menu>.item>.image {
  display: inline-block;
}

.url-list-container {
  height: 100%;
}

.url-list-container>.list>.item {
  padding: .75em 1em !important;
}


/* ALL HEADERS */
.all-headers-menu {
  display: grid !important;
  grid-template-columns: 1fr 10fr 1fr !important;
  border-radius: 0 !important;
  margin: 0 0 .25em 0 !important;
  padding: .25em 0;
  border-bottom: var(--thin-border)
}

.all-headers-menu>.item:first-child,
.all-headers-menu>.item:last-child {
  padding-right: 0 !important;
}

.all-headers-menu>.item>.search {
  padding: .5em !important;
}

.all-headers-menu>.item,
.all-headers-menu>.menu>.item {
  padding-top: .5em !important;
  padding-bottom: .75em !important;
  border-radius: 0;
  border: 0 !important
}


/* COLORS CONTAINER */
.colorz-container {
  width: 100%;
  display: grid;
  margin: 0 0 1em 0px;
  border-radius: 4px;
  grid-template-columns: 20% 40% 40%;
  align-items: center;
  justify-items: center;
  grid-template-areas:
    "colorz-apply"
    "colorz-bc"
    "colorz-c";
}

.colorz-container.colorSelectSingle {
  grid-template-columns: 20% 80%;
}

.colorz-container div {
  margin: 0 .1em;
  justify-self: stretch
}

.colorz-container .colorz-apply:not(.applied) {
  opacity: .3;
}

.msmi>.item>.header>.icon.clr:not(.applied) {
  opacity: .3;
}

/* END - COLORS CONTAINER */

/*  .svg-wrapper  */
.svg-wrapper {
  position: relative;
  display: inline-block;
  padding: .5em;
  width: 100%;
  /* <= shrinks container to image size */
  /* transition: transform 150ms ease-in-out; */
}

.svg-wrapper img {
  /* <= optional, for responsiveness */
  display: block;
  max-width: 100%;
  height: auto;
}

.svg-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* data-sidebar */
.vsl-container {
  padding: 0 !important;
  border: 8px solid var(--off-black-2) !important;
  background-color: var(--off-black-3);
  border-radius: 8px;
}

.av-menu {
  width: 20rem !important;
}

.vsl-container .item,
.av-menu>.item,
.av-menu>[role='button']>.item,
.dmv-menu>.item {
  background-color: var(--off-black-3) !important;
  color: white;
}

/* SIDEBAR */

.vsr-container {
  padding: .2em !important;
}

.vsr-container,
.vsr-container .content {
  background-color: var(--off-black-0) !important;
}

.vsl-container-settings {
  border: 0 !important;
  padding: 0 !important;
}

.vsl-container-settings.normal {
  border: 0 !important;
}

.vsl-container-settings.normal {
  padding: 0 !important
}


/* APP CHECK */
.app-check-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: .5em;
}

.app-check-group {
  margin: 0 !important;
}

.app-check-group>.segment:first-child {
  font-weight: bold;
}

.flex-app-check>.item>.content {
  display: grid;
  margin-bottom: .5em;
  grid-template-columns: 1fr 1fr;
  gap: .5em;
}

.flex-app-check .label {
  margin-bottom: .25em !important;
}

/* ALPHA */
.alpha-menu>.item.active {
  background-color: var(--off-black-7) !important;
}

/* PHONE */

.phone-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 9fr 1fr;
  align-items: center;
  margin-bottom: 1em;
}

.phone-container.full {
  grid-template-columns: 1fr;
}

.phone-container .icon {
  height: 100%;
  width: 100%;
}

/* json-container */
.json-container {
  height: 100% !important;
  font-size: .9rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  white-space: pre-wrap;
  scrollbar-width: thin;
  padding: .5em;
}

.json-container.nfh {
  height: auto !important;
}

.json-container .react-json-view,
.json-container .pretty-json-container {
  padding: 0.5em;
}

.table-split-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

/* TABLE SPLIT */

.table-split-container>div {
  padding: 1em;
  overflow-y: auto;
  border: 1px solid var(--off-white-3);
}

.table-split-container.three,
.table-split-container.upl {
  grid-template-columns: 2fr 1fr;
}

.table-split-container.upl>div:nth-child(2) {
  padding: 0em !important;
}

.table-split-container.three .icon.user {
  font-size: 5em !important;
}

/* SPLIT CONTAINER */

.split-container.vertical {
  display: grid;
  height: 100% !important;
  grid-template-columns: 90% 10%;
  grid-template-areas:
    "split-content split-menu";
}

.split-content {
  grid-area: split-content;
  height: 100% !important;
}

.split-content .item-container {
  height: 100% !important;
}

.split-container-half {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
}

/* MOTION */

.motion-container.landing,
.motion-container.page,
.motion-container.pageItem,
.motion-container.splash {
  width: 100%;
  height: 100%;
  z-index: 9000;
}

.motion-container.pageItemElement {
  width: 100%;
}

.cards-container>.motion-container {
  margin-bottom: 7px;
}

.motion-container.element {
  width: 100%;
}

/* app-homepage */

.app-homepage {
  grid-area: app-homepage;
  position: absolute;
  bottom: 0;
  width: 100%;
  grid-template-rows: repeat(3, 1fr);
  color: white;
  z-index: 9999;
}

.app-homepage>div {
  border: 1px solid var(--off-black-3);
}

.app-homepage.fp {
  display: block;
  height: 100%;
  position: relative;
}

.app-homepage.nh>.buttons {
  grid-template-columns: auto 1fr;
}

.app-homepage.nh>.buttons>.button {
  padding: 1em 1.5em !important;
}

.app-homepage>div {
  padding: .75em;
}

.app-homepage>div:nth-child(1)>.image {
  margin: auto;
}

/* BUTTONS */

.buttons-container {
  text-align: center;
  padding: .5em;
}

.icn-grp,
.icn-ngrp {
  display: grid;
  grid-template-columns: 5% 95%;
  gap: .5em;
}

/* HORIZONTAL */
.horizontal-container {
  overflow-y: auto;
  height: 100%;
  scrollbar-width: thin;
}

.app-horz-item {
  padding: .5em .2em;
}

.app-horz-item:not(:last-child) {
  border-bottom: solid 4px darkgray;
}

.item-horz {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  overflow-x: auto;
  scrollbar-width: thin;
  overflow-x: auto !important;
}

.item-horz>.card {
  min-width: 280px;
}

.horizontal-container .content-content {
  overflow-x: hidden;
}

.item-horz {
  margin: .2em;
  scrollbar-width: thin !important;
}

/* TIME CONTAINER */

.time-container {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr
}

.time-container label {
  font-weight: bold;
  font-size: .92857143em;
}

.time-container>.grid {
  width: 100%;
  margin: 0 !important;
}

.time-container>.grid>.column {
  padding: 0 .25em 0 0 !important;
}

.time-container>.grid .dropdown {
  width: 100%;
  min-width: 1em !important;
}

.time-container>.menu {
  width: 50px !important;
}

/* FCN */

.fcm-container>.item>.content {
  font-size: .7rem;
  word-wrap: break-word;
  word-break: break-all;
}


.fcm-container>.item>.content .description>.icon:last-child {
  float: left;
}

.fcm-container>.item>.content .description>.icon:nth-child(2) {
  margin-left: .5em;
}

.fcm-container>.item>.content>.description {
  flex-direction: column !important;
  display: flex;
  gap: .5em 1em;
  justify-content: space-between;
}

/* NUM PAD */

.num-pad {
  display: grid;
  grid-template-rows: 1fr 1fr 9fr;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
}

.num-pad-value {
  font-size: 2.5rem;
  padding: .25em;
  font-weight: bold;
}

.num-grid .num-pad-pad {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
  font-size: 2rem;
  height: 100%;
  width: 100%;
  gap: 10px;
  align-items: center;
  padding: .5em;
}

.num-grid .num-pad-pad>div {
  display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  justify-items: center;
}

/* BUTTON ROWS */
.btn-rows {
  padding: 0;
  display: grid;
  gap: .25em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.btn-rows.one {
  grid-template-rows: repeat(1, 1fr);
}

.btn-rows.two {
  grid-template-rows: repeat(2, 1fr);
}

.modal-wide .btn-rows {
  display: flex;
  justify-content: flex-end;
}

/* NOTIFICAITONS */

.app-notification-container {
  display: grid;
  grid-template-rows: auto auto auto auto;
  padding: 1em !important;
  height: 100%;
  border-radius: 8px;
  gap: .5em;
  margin-bottom: .5em;
  border: 1px solid rgb(229, 229, 229);
}

.app-notification-container>div:nth-child(4) {
  font-size: .7rem;
  color: rgb(161, 161, 161) !important;
}

.app-notification-content {
  display: none;
}

.app-notification-content.active {
  display: block;
}

/* NOTIFICATION STATUS */

.notification-status .nsi-header {
  padding: .5em;
  text-align: center;
  background-color: rgb(202, 202, 202);
  font-weight: bold;
}

.notification-status .nsi.sl,
.notification-status .nsi.dl {
  display: flex;
  padding: .75em 0;
}

.notification-status .dl {
  flex-direction: column;
}

.notification-status .sl {
  flex-direction: row;
  justify-content: space-between;
}

.notification-status .dl>div:nth-child(1) {
  text-align: center;
  padding: 1em 0 0 0;
}

.notification-status .dl>div:nth-child(2) {
  padding: 1em;
}

.notification-status .sl {
  grid-template-columns: 3fr 2fr;
}

.notification-status .sl>div:nth-child(1),
.notification-status .dl>div:nth-child(1) {
  font-weight: bold;
}

.notification-status .sl>div:nth-child(2),
.notification-status .dl>div:nth-child(2) {
  font-weight: normal;
}

.notification-status .nsstp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.notification-status .nsstp>div {
  padding: .25em;
}

/* TABLE FILTER */
.table-filter-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-y: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 2fr;
}

/* SINGLE PAGE */
.single-page-container {
  height: 100%;
  overflow-y: auto;
}

.single-page-container .item-container {
  height: auto;
  border: 0 !important;
  margin-bottom: 0;
}

.single-page-container .item-header {
  font-weight: bold;
  padding: .5em;
}

.single-page-container .item-content {
  overflow-y: hidden !important;
}

/* PENDING */
.pending-container {
  height: 100%;
}

/* SCROLL */
.scroll-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}

.scroll-contents {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.scroll-element {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: scroll;
}

.scroll-element.no-padding {
  padding-top: 0;
}

/* MENU */
.menu-wrapper {
  width: 100%;
  padding: .25em .5em .25em .2em;
  margin: 0 auto;
  ;
}

/* SEARCH */

.side.data,
.side.data .vert-container {
  height: 100%;
  overflow-y: auto !important;
}

.search-float {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.search-float.side {
  top: 5em;
  bottom: 5em;
  left: 0;
  height: 100%;
}

.search-float>div {
  font-size: small !important;
  display: flex !important;
  touch-action: manipulation;
  overscroll-behavior: none;
  overflow-y: auto;
  z-index: 100;
  padding: .5em;
}

.search-float>div:nth-child(2) {
  z-index: 101;
}

/* DATA LIST */
.data-list-container {
  padding: 1em;
  height: 100%;
  font-size: .8rem;
}

.data-list-list>div {
  display: flex;
  padding: .5em;
  border-bottom: 1px solid var(--off-white-3);
  align-items: center;
}

.data-list-list.modifyList.array>div {
  flex-direction: column;
  align-items: start;
}

.data-list-list.modifyList.array>div>div {
  padding: .5em !important;
  text-align: left !important;
}

.data-list-list.modifyList>div,
.data-list-list.status>div {
  justify-content: space-between;
}

.data-list-list.status>div>div:first-child {
  font-weight: bold;
}

.data-list-list>div.diff {
  background-color: var(--off-white-1)
}

.app-dark-mode .data-list-list>div.diff {
  background-color: var(--off-black-1)
}

.data-list-list>.item {
  justify-content: center !important;
}

.list-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* HDR */

.hdr-container {
  display: grid;
  padding: .5em;
  justify-content: center;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.hdr-container>div:nth-child(1) {
  text-align: left;
}

.hdr-container>div:nth-child(2) {
  text-align: center;
  margin: auto;
  font-weight: bold;
}

.hdr-container>div:nth-child(3) {
  text-align: right;
}


.hdr-container div[width='10'] {
  width: 10% !important;
}

.hdr-container div[width='20'] {
  width: 20% !important;
}

.hdr-container div[width='30'] {
  width: 30% !important;
}

.hdr-container div[width='40'] {
  width: 40% !important;
}

.hdr-container div[width='50'] {
  width: 50% !important;
}

.hdr-container div[width='60'] {
  width: 60% !important;
}

.hdr-container div[width='70'] {
  width: 70% !important;
}

.hdr-container div[width='80'] {
  width: 80% !important;
}

.hdr-container div[width='90'] {
  width: 90% !important;
}

/* SW WRAPPER */

.sw-wrapper.full {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sw-wrapper>.header {
  border-bottom: none !important;
}

.sw-wrapper>.header>.menu {
  padding: 0 !important;
}

.sw-wrapper>.header>.menu>.item:nth-child(2) {
  font-weight: bold;
}

.sw-wrapper.topper {
  grid-template-rows: auto auto 1fr !important;
  height: 100% !important;
  grid-template-areas:
    "topper"
    "header"
    "content" !important
}

.sw-wrapper.topper.hoc {
  grid-template-rows: auto auto auto 1fr !important;
  height: 100% !important;
  grid-template-areas:
    "topper"
    "header"
    "notification"
    "content" !important
}


.sw-wrapper.dm .divider {
  color: rgb(209, 209, 209);
}

.sw-wrapper .push-pushable {
  padding: 0
}

.sw-wrapper .push-container {
  padding: 0
}

.sw-wrapper .push-content {
  padding: 0
}

.sw-wrapper.sub {
  padding: .25em
}

/* COPY */
.copy-container {
  display: grid;
  grid-template-rows: auto 1fr;
}

.copy-container>div {
  padding: 1em;
}

/* TIME */
.npicker-container {
  display: flex;
  flex-direction: row;
}

/* EMAIL */
.em-content {
  padding: 1.2em;
  text-align: center;
  margin: auto 0;
  font-size: 1.2rem !important;
}

/* DISPLAY EDITOR */

.display-editor {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.display-editor .editor {
  border: 1px solid red !important;
  margin-left: 1em;
  height: 100%;
  overflow-y: auto;
}

/* START DOTS */
.start-dots-container {
  padding-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-dot-container {
  width: 10rem;
  height: 5rem;
  display: flex;
  justify-content: space-around
}

.start-dot {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}


.start-circle-container {
  position: absolute;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  margin: auto;
}

.start-circle-container:not(.page-dimmer) {
  margin-top: 3em;
}

.start-circle {
  display: block;
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid gray;
  border-top: 0.2rem solid black;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.start-circle.suspense,
.start-circle.pageItem {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2rem solid white;
  border-top-color: black;
}

/* ITEM SETTINGS */

.item-settings {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

.item-settings>.item-settings-content {
  padding: .25em .5em;
  border-radius: 2px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.item-settings>.item-settings-content>.label {
  margin: .25em 0 .25em 0 !important;
  min-width: 160px;
  padding: .75em
}

.item-settings>.icon {
  margin-top: .5em
}

.item-settings>.segment {
  position: absolute !important;
  right: 1.5em;
  width: 40%;
  bottom: 4.5em;
  display: flex;
}

/* ITEM ALLOW */
.item-allow {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: absolute;
  bottom: 5em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

/* ITEM MAPPING */
.item-mapping {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

/* ITEM MEETING */

.item-meeting {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  position: relative;
  bottom: 2em;
  left: 2em;
  height: 0;
  z-index: 1000 !important;
}

/* SIGN IN */

.seg-sign-in {
  display: grid !important;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100%;
  width: 100%;
  padding: .5em;
  text-align: center;
}

.seg-sign-in {
  height: 100%;
}

.seg-sign-in .field {
  margin: 0 !important;
  max-width: none !important;
}

.seg-sign-in .message.icon>.content {
  margin-left: 1em
}

.seg-sign-in .reset-password {
  margin-top: 2em !important;
}

.seg-sign-in .button>.icon.loading {
  background: none !important;
}


/* STEP VERT */
.step-vert {
  display: grid;
  grid-template-rows: 1fr 3fr;
  height: 100%;
  padding: 1em;
  text-align: center;
}

.step-vert>div:last-child {
  padding: 3em;
}

.step-vert .step {
  display: flex;
  justify-content: space-between !important;
}

.step-vert>.steps.vertical>.step:after {
  content: none !important;
}

/* Q AND A */

.q-and-a-list {
  padding: .25em;
}

.q-and-a-list.active>div:first-child {
  background-color: var(--sem-blue);
  color: white
}

.q-and-a-list>div:nth-child(odd) {
  background-color: var(--off-white-3);
  text-align: center;
  font-weight: bold;
  padding: .75em;
  border-radius: 4px;
  margin-bottom: .5em;
}

.q-and-a-list>.q-and-a-list-static .sub.header {
  margin-top: 1em;
}

/* HORTIZONTAL CONTAINER */
.horz-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  padding: .25em;
  -webkit-overflow-scrolling: touch;
  will-change: auto;
  /* Add this for smooth scrolling on Safari */
}

.horz-container>div {
  flex: 0 0 auto;
  min-width: 48% !important;
}

.horz-container-ns>div {
  margin: .25em !important;
}

/* MEETINGS */

.meet-times>div {
  display: flex !important;
  margin: 0 !important;
}

.meet-times .label {
  margin-bottom: .5em !important;
}

.meet-times .mrts,
.meet-times .mrts>.label {
  display: flex;
}

.meet-times .mrts>.label {
  width: 100%;
}

.meet-times .tsl {
  font-size: 1rem;
  font-weight: bold !important;
}

/* LIST MERGE */

.list-merge {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
}

.list-merge>div,
.list-merge>.div>.list {
  height: 100%;
  padding: .5em;
}

.list-merge>div {
  overflow-y: auto;
}

.list-merge>div:nth-child(3)>div>div {
  padding: .5em;
}

/* DASHBOARD */

.dashboard-menu {
  display: grid;
  grid-template-columns: auto 6fr;
  height: 100%;
}

.dashboard-menu>div:first-child {
  overflow-y: auto;
  height: 100%;
}

.dashboard-menu .menu {
  border-radius: 0;
}

.dashboard-menu .menu>.item.sel {
  background-color: var(--sem-blue);
}

.dashboard-menu .form {
  padding: 1em;
}

.dashboard-menu>div {
  height: 100%;
  overflow-y: auto;
}

/* LIST ADD */
.list-add {
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.list-add-options {
  display: grid;
  grid-template-columns: 5fr auto auto;
  gap: .5em;
}

.list-add-options.io {
  display: grid;
  grid-template-columns: 2fr auto;
  gap: .5em;
}

.list-add-options.io>div:last-child {
  display: flex;
}

.list-add-options.io>div:last-child .icon {
  padding: .5em;
}

.list-add>div:last-child,
.list-add-options>div:last-child {
  text-align: right;
}

/* STORAGE ITEMS */

.app-storage-images .app-storage-folder {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
}

.app-storage-images .app-storage-folder {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr auto;
}

.app-storage-images .app-storage-images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr auto;
}

.app-storage-images .app-storage-image {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
}

.app-storage-images .app-storage-folder>div:first-child,
.app-storage-images .app-storage-folder>div:first-child {
  padding: 1em;
  background-color: aliceblue;
  text-align: center;
  font-weight: bold;
}

.app-storage-images .image {
  height: 60px;
  width: 60px;
}

.app-storage-images .image>img {
  height: 60px;
  width: 60px;
}

/* PAYLOAD */

.seg-payload {
  left: 0;
  width: 100%;
  position: absolute !important;
  top: 0;
  z-index: 9999 !important;
  background-color: var(--card-shadow) !important;
}

/* APP NOTIFICATION */

.app-notifications-container {
  height: 100%;
}

.app-notification-group div {
  font-size: .9rem;
}

.app-notification-title-container>div:nth-child(4) {
  font-size: .7rem !important;
  padding: .25em;
  color: rgb(148, 161, 255) !important;
}

.app-notification-date-group>div {
  padding: 1em .25em;
}

.app-notification-date-group>div:nth-child(1) {
  text-align: center;
  margin-top: 1em;
}

.app-notification-date-group>div:nth-child(2) {
  padding: 0;
}

.app-notification-group>div:first-child,
.app-notification-date-group>div:first-child {
  font-weight: bold;
  padding: .25em !important;
  margin-bottom: .5em;
  font-size: 1.1rem;
}

.note:not(.icon) {
  border: 1px solid rgb(212, 212, 212);
  padding: .5em;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.app-note-user {
  margin: .2em !important;
}

.app-note-title {
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.app-note-title>div:nth-child(2) {
  text-align: right;
}

.app-note-title>div>div:nth-child(1) {
  font-weight: bold;
}

.note>.note-time {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding: .75em 0;
  text-align: left;
  font-size: .9rem;
}

.note>.note-note {
  display: grid;
  grid-template-rows: auto 1fr;
  border: 1px solid rgb(212, 212, 212);
  padding: 1em;
}

.note>.note-note>div:nth-child(2) {
  padding: 1em;
}

.note>.note-description {
  border: 1px solid rgb(212, 212, 212);
  padding: 1em;
}

.note>.note-buttons {
  padding: .75em 0;
  display: grid;
  gap: .25em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.buttons-two-row {
  display: grid;
  grid-template-rows: 1fr 1fr;
}


.app-notifications .title>div:last-child {
  text-align: right;
}

.app-notifications .checker {
  float: right;
  font-size: 1em !important;
}

.app-notifications .checker label {
  margin-right: .5em;
  font-size: .8em;
}

.app-notifications>.title>div:nth-child(3) {
  margin-left: .5em !important
}

/* APP TICKETING */

.app-ticketing-container {
  padding: .5em !important;
}

.app-ticketing-container>.list>.item {
  display: grid !important;
  grid-template-columns: 6fr 1fr 1fr 1fr 1fr !important;
  align-items: center;
}

.app-ticketing-container .dropdown,
.app-ticketing-container .dropdown>.menu>.item {
  font-size: .9em !important;
}

.app-ticketing-container>.list>.item>div:not(:first-child) {
  text-align: center;
}

.app-ticketing-container .icon {
  margin-left: .5em
}

.atb {
  display: flex;
  justify-content: space-between;
}

/* SWIPER SUB */
.swiper-sub {
  overflow-y: hidden;
  height: 100%;
}

.swiper-sub>.react-swipeable-view-container {
  height: 100%;
}

.swiper-sub>.react-swipeable-view-container>div {
  height: 100%;
  overflow-y: auto;
}

/* NEWS */
.news-wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.news-wrapper>div:nth-child(1) {
  padding: 1em;
  text-align: center;
}

.news-container {
  width: 100%;
  padding-top: .2em !important;
  margin: 0 !important;
}

.app-dark-mode .news-container .card {
  border: 1px solid var(--off-black-3) !important;
  border-radius: 0;
  box-shadow: none !important;
}

.app-dark-mode .news-container * {
  background-color: var(--off-black-2) !important;
  color: var(--off-white-2) !important;
}

.ids-sv,
.ids-sv>.react-swipeable-view-container,
.ids-sv>.react-swipeable-view-container>div>div {
  height: 100% !important;
}


/* JITSI */
.jitsi-wrapper {
  height: 100%;
}

.jitsi-wrapper>div {
  height: 100% !important;
  width: 100% !important;
}

#jitsi-container {
  height: 100% !important;
}

/* CB CONTAINER */
.cbc-container {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr;
}

.cbc-container .button:nth-child(2),
.cbil-container .button:nth-child(2),
.cbir-container .button:nth-child(1) {
  text-align: left;
}

.cbc-container .button {
  margin-bottom: .5em !important;
  padding: 1em 0 !important
}

.cbil-container {
  display: grid !important;
  grid-template-columns: 1fr 9fr;
}

.cbir-container {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

.cbir-confirm {
  display: grid !important;
  grid-template-columns: 9fr 1fr;
}

/* VIDEO */

.vid-container>div {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}

.vid-container iframe {
  margin: 0 !important;
}

/* APP CHECK */
.flex-app-check>.item>.content {
  display: grid;
  margin-bottom: .5em;
  grid-template-columns: 1fr 1fr;
  gap: .5em;
}

.flex-app-check .label {
  margin-bottom: .25em !important;
}

.app-check {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: .5em;
}

.app-check-group {
  margin: 0 !important;
}

.app-check-group>.segment:first-child {
  font-weight: bold;
}

/* SCORING SLIDER */
.scoring-slider {
  display: grid;
  grid-template-rows: 1fr 14fr 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1em;
  gap: .5em;
}

.scoring-slider .label {
  width: 100%;
}

/* PLACES */

.places-container {
  padding: .5em !important;
}

.places-container.grouped>div:first-child {
  font-weight: bold;
}

.places-list,
.places-list.addit {
  padding-bottom: .5em;
  padding-top: .5em;
  display: grid !important;
  grid-template-columns: 1fr 9fr;
  gap: .5em;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.places-list.addit {
  padding-bottom: .5em;
  padding-top: .5em;
  display: grid !important;
  grid-template-columns: 2fr 5fr;
  gap: .5em;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.places-list>div:nth-child(2)>div:first-child {
  font-weight: bold;
}

/* MATCH POPUP */
.mu-pop {
  font-size: .8rem !important;
}

.mu-pop>.item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.mu-pop>.item .icon {
  margin-right: .75em !important;
}

/* QR CONTAINER */

.qr-container {
  align-items: center !important;
}

.qr-container.solo {
  padding: 0 1em;
  text-align: right;
}

.grid-table-link>.table {
  width: auto !important;
}

.grid-table-link thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 2 !important;
}

.grid-table-link tr>td:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
}

.grid-table-link thead>tr>th,
.grid-table-link tbody>tr>td:first-child {
  font-size: .8rem !important;
}

.grid-table-link tr {
  height: 10px !important;
}

.grid-table-link tr>td {
  padding-top: .2em !important;
  padding-bottom: .2em !important;
}

.grid-table-link tr>th:first-child,
.grid-table-link tr>td:first-child {
  width: 250px !important;
}

.grid-table-link td.sel {
  background-color: var(--sem-green-light);
}

.grid-table-link table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

.grid-table-link th {
  background: white;
  position: sticky;
  top: 0;
  writing-mode: vertical-lr;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

/* document-container */
.document-container * {
  background-color: var(--off-black-2) !important;
  color: white !important;
}

.document-container .segments {
  border: 1px solid var(--off-black-4) !important;
}

.document-container .segments>.segment:nth-child(2) {
  border-top: 1px solid var(--off-black-4) !important;
}

.app-dark-mode .menu-ids {
  background-color: var(--off-black-1) !important;
  width: 100% !important;
}

.menu-ids.alpha {
  width: 94% !important;
}

.app-dark-mode .menu-ids.rev {
  display: grid;
}

.app-dark-mode .menu-ids>.item {
  color: var(--off-white-2) !important;
}

.app-dark-mode .menu-ids>.item .label {
  margin-left: 1em;
  color: var(--off-white-2) !important;
}

.menu-ids>.item>div:first-child {
  display: flex !important;
  justify-content: space-between;
}

.menu-ids.rev>.item>div:first-child {
  display: flex !important;
  justify-content: start;
  gap: 1em;
}

.menu-ids>.item>div:first-child>div>div:nth-child(n+2) {
  font-size: .9rem;
}

.app-dark-mode .menu-ids>.item>div:first-child>div>div:nth-child(n+2) {
  color: var(--off-white-5) !important;
}

.app-dark-mode .menu-ids-alpha {
  background-color: var(--off-black-3) !important;
  color: white !important;
  padding: .5em 1em !important;
  font-weight: bold;
  font-size: 1.1rem;
}

.icns>.icon {
  margin-left: 1em;
}

.frm-right {
  display: grid !important;
  grid-template-rows: 1fr auto;
  font-size: .9rem !important;
}

.frm-right .sbm-top {
  border-top: 1px solid grey !important;
}

.frm-right .sbm-top.hdr {
  border-top: 2px solid grey !important;
  border-bottom: 1px solid grey !important;
}

.frm-right .left-sub {
  display: grid !important;
  grid-template-columns: auto 1fr !important;
  justify-items: flex-end !important;
  gap: .5em;
}

.frm-right .left-sub:first-child {
  background-color: black !important;
}

.frm-right .item {
  color: var(--off-white-3) !important;
}

.frm-right .item.dat {
  background-color: var(--off-black-3) !important;
}

.buui.cards>.card {
  margin: .2em !important;
}

.buui.cards>.card>.content {
  padding: .75em !important;
  font-size: .9rem;
}

.sbr-items {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  background-color: var(--off-black-2) !important;
}

.sbr-items .item.active {
  background-color: var(--off-black-3) !important;
}

.lbls-status {
  padding: .5em;
  display: flex;
  flex-direction: column;
  gap: .5em;
}