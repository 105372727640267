/* CARDS CONTAINER */

.break-div {
  width: 100%;
}

.plus .card-normal .card-content {
  padding: .5em .0em !important;
}

.app-swipe-content .cards {
  margin: .2em 0;
}

.prop-override-section {
  padding: .5em;
  border: 1px solid blue;
  border-radius: 8px;
  margin: .5em;
}

.card-alpha {
  padding: .25em 1em;
  font-weight: bold;
}

/* CARD IMAGE */

/* ITEM UI CONTAINER */
.item-container {
  display: grid;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "item-header"
    "item-content"
}

.item-container .card {
  display: grid;
}

.item-container.ac {
  grid-template-rows: auto 1fr;
}

.item-container .group-swiper>div {
  overflow-y: hidden !important;
}

.item-container .group-swiper,
.item-container .header {
  vertical-align: top !important;
}

.item-container .image {
  margin: 0px 8px 8px 0
}

.item-container.cbn {
  height: auto !important;
}

.item-container.fh {
  height: 100%;
}

.item-container.fic .content-content .card {
  height: 100% !important;
}

.item-container.padd {
  padding: 8px;
  padding-top: 16px
}

.item-container.fic .content-content .card {
  height: 100% !important;
}

/* item-content */
.item-content {
  grid-area: item-content;
  overflow-y: auto;
  overflow-y: auto;
  -webkit-overflow-y: auto;
  -webkit-box-flex: 0;
}

.item-content>.cards {
  margin: 0;
}

.item-content .gml-container,
.item-content .gml-container>div {
  height: 100% !important;
}

.item-container.solo>.item-content {
  grid-template-rows: auto 1fr;
}

.item-container.solo>.item-content>.solo {
  height: 100%;
  padding: 0 !important;
}

.item-content>.cards.solo {
  margin: 0 !important;
}


.solo .item-content .card {
  overflow-y: hidden !important;
}

.item-content>.card {
  margin: 0 !important;
}

.item-content.vertical {
  padding-top: 2em;
}

/*  ITEM HEADER */

.item-header {
  grid-area: item-header;
  width: 100%;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 100%;
}

/*  ITEM CAPTION */

.item-caption {
  grid-area: item-caption;
}

.dt-mode .item-caption {
  grid-area: item-caption;
  padding: 1em;
  font-size: 1.2rem;
  font-weight: bold;
}

/* ITEM FILTER */

.item-filter .menu-filter.fullPage,
.item-filter .menu-filter.tags {
  display: flex;
  padding: .5em;
}

.item-filter .menu-filter.fullPage {
  justify-content: flex-end;
}

.item-filter .menu-filter.tags {
  overflow-x: auto;
  scrollbar-width: thin;
  flex: 1 1 auto;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
}

.item-filter .menu-filter.menu {
  padding: .5em;
  border: 0;
}


/* ***************** */

.single-page-container>.item-container {
  border-radius: 3px;
  padding: .5em;
  margin-bottom: 1em;
}

.single-page-container .item-container {
  height: auto;
  border: 0 !important;
  margin-bottom: 0;
}

.single-page-container .item-header {
  font-weight: bold;
  padding: .5em;
}

.single-page-container .item-content {
  overflow-y: hidden !important;
}

.dt-off .crdc.item-vert .motion-container.element .card,
.dt-off .crdc.item-vert .card {
  margin-top: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0;
}

.cards>.card {
  margin-bottom: .5em !important;
}

.cards>.card:not(.solo .card):nth-child(1) {
  margin-top: .25em !important;
}

/* CARDS CONTAINER */
.crdc {
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

.crdc.profilePlus,
.crdc.schedulePlus {
  margin-top: 0px !important;
}

.crdc.profilePics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: .25em;
  padding: .5em !important;
}

.crdc.profilePics .pic-profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: 1px solid var(--off-white-3) !important;
}

.crdc.profilePics .pic-profile .card-image {
  padding: .5em;
}

.crdc.profilePics .pic-profile .prc {
  font-size: .9rem !important;
}

.crdc.solo {
  height: 100%;
}

.crdc.solo .card:not(.crd-sub) {
  border-radius: 0 !important;
}

.crd-sub:first-child {
  margin-top: 1em !important;
}

.crd-sub.sel {
  background-color: var(--sem-greenish) !important;
}

.crdc.multi {
  justify-content: space-between;
  align-content: stretch;
}

.crdc.multi .card {
  margin: .25em !important;
  width: calc(33.33333333% - 1em) !important;
  font-size: .8rem !important;
}

.sec-container:not(.header) {
  display: grid;
  overflow-x: hidden;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "sec-header"
    "sec-content"
}

.sec-header {
  grid-area: sec-header
}

.sec-content {
  grid-area: sec-content;
}

.sec-content.noCaptions {
  padding: .5em !important;
}

/* pad the cardNameSectionOnly smaller */
.cardNameSectionOnly .sec-container.header .sec-content {
  padding: .75em 0em .75em .5em;
}

.cardMini .sec-container {
  border: none !important
}

/* CARDS EXTRA */
.dt-off .crdc:not(.normal):not(.inline-container):not(.profile):not(.schedule) .card {
  padding: 0 !important;
}

.crdc .card>.content>.meta {
  margin-right: .5em !important;
}

.card .meta {
  font-size: 1rem !important;
}

.crdc.schedule .card>.content,
.crdc.profile .card>.content,
.crdc.normal .card>.content {
  padding: 1em .5em 1em .5em !important;
}

.crdc.schedule.plus .card,
.crdc.profile.plus .card {
  width: 100% !important;
  border-radius: 0 !important;
  padding: .25em !important;
  margin-bottom: 0 !important;
}

.crdc.normal .card .header,
.crdc.schedule .card .header {
  font-size: 1.1rem !important;
}

.crdc.profile .card>.content.header,
.card-profile .content>.header {
  display: flex !important;
}

.profile .card>.content>.header>div:not(.label) {
  padding-right: .2em !important;
}

.profile .card>.content>.header>div.label {
  margin-right: 1em !important;
}

.profile .card-image>.image {
  padding: 0 .25em 0 0 !important;
}

.profile .header>.label {
  margin: 0 0 .5em 0 !important;
}

.card-profile .header>.label {
  margin: 0 0 .5em 0 !important;
}

/* CARD IMAGE */

.crdc.normal.card-img .card,
.crdc.nameSectionOnly.card-img .card,
.crdc.profile.card-img .card {
  display: grid !important;
}

.crdc.nameSectionOnly.card-img .card>.card-content>.header,
.crdc.card-profile.img-inline.card-img .card>.card-content>.header {
  grid-template-columns: 1fr 4fr !important;
  grid-template-areas: "card-image card-content";
}

.crdc.profilePlus.card-img.img-pos-left .card {
  grid-template-columns: 35% auto !important;
  grid-template-areas: "card-image card-content";
}

.crdc.normal.card-img.img-pos-left .card,
.crdc.nameSectionOnly.card-img.img-pos-left .card,
.crdc.profile.card-img.img-pos-left .card {
  grid-template-columns: 25% auto !important;
  grid-template-areas: "card-image card-content";
}

.crdc.normal.card-img.img-pos-left .card,
.img-pos-left.card-img .card.plus {
  grid-template-columns: 2fr 5fr !important;
  grid-template-areas: "card-image card-content";
}

.img-pos-left.card-img.normal .card.plus,
.img-pos-left.card-img.nameSectionOnly .card.plus,
.img-pos-left.card-img.profile .card.plus {
  grid-template-columns: 2fr 5fr !important;
  grid-template-areas: "card-image card-content";
}

.img-pos-right.card-img.normal .card.plus,
.img-pos-right.card-img.nameSectionOnly .card.plus,
.img-pos-right.card-img.profile .card.plus {
  grid-template-columns: 1fr 3fr !important;
  grid-template-areas: "card-content card-image";
}

.img-pos-top.card-img.normal .card,
.img-pos-top.card-img.nameSectionOnly .card,
.img-pos-topLeft.card-img.nameSectionOnly .card,
.img-pos-topRight.card-img.nameSectionOnly .card,
.img-pos-top.card-img.profile .card,
.img-pos-topLeft.card-img.profile .card,
.img-pos-topRight.card-img.profile .card {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image"
    "card-content";
}

.img-pos-top .card-img.normal .card-image {
  text-align: center;
}

.img-pos-bottom.card-img.normal .card,
.img-pos-bottom.card-img.nameSectionOnly .card,
.img-pos-bottomLeft.card-img.nameSectionOnly .card,
.img-pos-bottomRight.card-img.nameSectionOnly .card,
.img-pos-bottom.card-img.profile .card,
.img-pos-bottomLeft.card-img.profile .card,
.img-pos-bottomRight.card-img.profile .card {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-image";
}

.crdc.nameSectionOnly .card>.card-content .icon:not(.labeled),
.crdc.nameSectionOnly .card>.card-content .icon:not(.labeled) {
  font-size: 1.2em;
}

.card-img.nameSectionOnly .card-image {
  padding: .5em 0 .5em .5em
}

.card-image {
  grid-area: card-image;
  text-align: center;
}

.card-image .image {
  max-height: auto !important;
}


.img-size-mini .card-image .image {
  max-width: 20px !important;
}

.img-size-tiny .card-image .image {
  max-width: 40px !important;
}

.img-size-small .card-image .image {
  max-width: 60px !important;
}

.img-size-medium .card-image .image {
  max-width: 80px !important;
}

.img-size-large .card-image .image {
  max-width: 100px !important;
}

.img-size-huge .card-image .image {
  max-width: 120px !important;
}

.img-size-massive .card-image .image {
  max-width: 140px !important;
}


.card-image:not(.card-profile .card-image) {
  padding-bottom: .5em !important;
}

.card-image.cpi>.icon {
  color: var(--sem-teal) !important;
}

.card-indics {
  grid-area: card-indics;
  padding: .5em;
  border-top: 1px solid white;
}

.card-indic {
  padding: .25em;
}

.card-indic .icon {
  margin-right: .5em;
}

.card-pdf {
  width: 100%;
  padding: .5em;
  border-top: 1px solid white;
  float: right;
  grid-area: card-pdf;
  text-align: right;
}

.img-pos-top .card-img .card-image {
  text-align: center;
}

/* CARD CONTENT */
.card-content {
  grid-area: card-content;
}

/* CARD OLD */
.card-schedule>.content,
.card-profile>.content,
.card-normal>.content {
  padding: 1em !important;
}

.card-profile>.content>.header {
  display: flex !important;
  font-size: 1.2em !important;
}

.card-profile>.content>.header>div:not(.label) {
  padding-right: .2em !important;
}

.card-profile>.content>.header>div.label {
  margin-right: 1em !important;
}

.card-profile .card-image>.image {
  padding: 0 .25em 0 0 !important;
}

.card-profile .header>.label {
  margin: 0 0 .5em 0 !important;
}

.card-schedule>.content>.meta .icon,
.card-profile>.content>.meta .icon,
.card-normal>.content>.meta .icon {
  margin-right: .5em !important;
}

.card-profile.plus,
.card-schedule.plus {
  width: 100% !important;
  border-radius: 0 !important;
  padding: .25em !important;
  margin-bottom: 0 !important;
}

.card-profile>.content {
  width: 100% !important;
}

/* CARD PROFILE */

.card-profile.pdf {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image card-content"
    "card-pdf card-pdf" !important;
}

.card-profile.indic {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image card-content"
    "card-indics card-indics" !important;
}

.card-profile.indic.top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-indics card-indics"
    "card-image card-content" !important;
}

.card-profile.indic.card-img.img-pos-top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-image"
    "card-content"
    "card-indics" !important;
}

.card-profile.card-img.img-pos-top .card-image {
  margin: auto;
  padding: 1em !important;
}

.card-profile.card-img.img-pos-top {
  grid-template-rows: auto 1fr !important;
  grid-template-areas:
    "card-content"
    "card-image "
    "card-indics" !important;
}

.card-profile.fnd {
  border: 2px solid blue !important;
}

.card-profile.rct {
  border: 2px solid green !important;
}

/* CARD SCHEDULE */
.cards .card-schedule {
  align-self: baseline !important;
}

.crdc.schedule .card>.content.extra:not(.other),
.crdc.schedulePlus.plus .card>.content.extra:not(.other) {
  display: grid;
  column-gap: .1em;
  row-gap: .5em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "pi-startDate pi-endDate"
    "pi-startTime pi-endTime"
    "pi-locations pi-locations"
    "pi-pdf pi-pdf"
}

.crdc.schedule .card>.content.extra.grp.tz,
.crdc.schedulePlus.plus .card>.content.extra.grp.tz {
  display: grid;
  column-gap: .1em;
  row-gap: .5em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "pi-startDate pi-location"
    "pi-times pi-times";
}

.crdc.schedule .card>.content.extra.other,
.crdc.schedulePlus.plus .card>.content.extra.other {
  display: grid;
  row-gap: .5em;
  grid-template-columns: 1fr;
  grid-template-areas: none !important;
}

.crdc.schedule .card>.content.extra.other>.label {
  margin: 0 !important;
}

.crdc.schedule .card>.content.extra.card-link {
  grid-template-columns: 1fr;
  justify-items: end;
}

.crdc.schedule .card>.content.extra.card-link .check-toggle,
.crdc.schedule .card>.content.extra.card-link .check-toggle>label {
  font-size: .9rem !important;
}

.pi-time {
  display: flex !important;
}

.pi-startDate {
  grid-area: pi-startDate;
}

.pi-endDate {
  grid-area: pi-endDate;
}

.pi-times {
  grid-area: pi-times;
  margin: 0 !important;
}

.pi-startTime {
  grid-area: pi-startTime;
  display: grid !important;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr;
  row-gap: 1em;
  margin-left: 0 !important;
}

.tz>.pi-startTime {
  grid-template-rows: 1fr 1fr;
}

.pi-startTime>div {
  text-align: left;
}

.pi-startTime>.icon:nth-child(2) {
  margin-left: .5em;
}

.pi-endTime {
  grid-area: pi-endTime;
  display: grid !important;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr;
  row-gap: 1em;
  margin-right: 0 !important;
}

.tz>.pi-endTime {
  grid-template-rows: 1fr 1fr;
}

.pi-endTime-alt {
  grid-area: pi-endTime-alt;
}

.pi-location {
  grid-area: pi-location;
}

.pi-locations {
  grid-area: pi-locations;
}

.pi-pdf {
  grid-area: pi-pdf;
}

.pi-times>.icon:first-child {
  margin-right: .5em !important;
}

.pi-times>.icon:last-child {
  margin-left: .5em !important;
}

.pi-times>.detail {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

/* GS */
.google-data-container .tv-tr>td:first-child {
  background-color: var(--sem-green);
  text-align: center;
}

.google-data-container .tv-tr-new>td:first-child {
  background-color: var(--sem-red);
  text-align: center;
}

/* GC */
.card-normal.gift-card {
  padding: .5em 0;
  border: solid 2px rgb(185, 185, 247);
  height: 120px;
}

.card-normal.gift-card .buttons>.button:first-child {
  background-color: rgb(255, 216, 216) !important;
}

.card-normal.gift-card .buttons>.button:last-child {
  background-color: rgb(212, 255, 207) !important;
}

.card-normal.gift-card {
  border: solid 2px rgb(221, 221, 221);
}

.card-normal.gift-card>.content>.meta>.label {
  margin-top: 1em;
}

.aie {
  display: grid;
  border: 1px solid rgb(219, 219, 219);
  padding: .25em;
  margin-bottom: 1em;
}

.aie>div:last-child {
  text-align: right;
}

.solo:not(.crdc) .card:not(.crd-sub),
.solo:not(.crdc) .card:not(.crd-sub)>.content {
  box-shadow: none !important;
  margin-bottom: .25em !important;
  border-radius: 0 !important;
  border: none !important;
}

.solo .card:not(.crd-sub),
.solo .card:not(.crd-sub)>.content,
.solo.plus .card:not(.crd-sub),
.solo.plus .card:not(.crd-sub)>.content {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
}

.card-group-favs {
  margin: 0 !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.card-group-favs>div:first-child {
  padding: .5em;
}

.card-group-favs>div:last-child {
  padding: .5em;
}

.card-group-favs>.segment:last-child>.header {
  box-shadow: none !important;
}