/* IN ORDER OF PLACEMENT */

/* start-dimmer */
.start-dimmer {
  height: 100%;
  width: 100%;
  z-index: 9998;
  pointer-events: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.start-dimmer h3 {
  text-align: center !important;
}

/* App */
.App {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/* page-area-content */
.page-area-content {
  overflow-y: hidden;
}

.page-area-content.dt.ima-d,
.page-area-content.dt-off.mob.ima-m {
  height: 100vh;
}

.page-area-content.ns .app-container {
  margin: 0 auto !important;
}

/* page-area-container */
.page-area-container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* framework-container */
.framework-container {
  height: 100vh !important;
  width: 100vw !important;
  padding: 0 !important;
  margin: 0 !important;
}

.dt .framework-container .app-content {
  width: 100%;
  margin: 0 auto;
}

.framework-container>.grid>.column:nth-child(2) {
  padding: 0 !important
}

.page-area-content,
.page-area-container,
.framework-container {
  height: 100vh !important;
}

/* app_settings_menu */
.app-settings-menu {
  position: fixed !important;
  z-index: 9999;
  text-align: center;
  width: 100%;
  top: .3em;
  height: 0;
}

.app-settings-menu * {
  opacity: 0.8;
  transition: opacity 0.3s ease !important;
}

.app-settings-menu:hover * {
  opacity: 1 !important;
}

.app-settings-menu.cap {
  top: auto;
  bottom: 2em !important;
}

.app-settings-menu>.buttons {
  border: 2px solid grey;
  border-radius: 6px;
  background-color: gray;
}

/* app_settings_menu-google */
.app-settings-menu-google {
  position: relative !important;
  top: 1em;
  height: 0;
  width: 40vw !important;
  margin: 0 auto !important;
  text-align: center !important;
}

/* app_settings_menu-bottom */
.app-settings-menu-bottom {
  position: fixed !important;
  z-index: 9999;
  text-align: center;
  width: 100%;
  bottom: 5em;
  height: 0;
}

.app-settings-menu-bottom>.buttons {
  border: 2px solid grey;
  border-radius: 6px;
}

/* app_settings_grid */
.app-settings-grid {
  height: 100vh;
  margin: 0 !important;
}

.app-settings-grid>.column {
  padding: 0 !important
}

.app-settings-grid>.column.app {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* settings-app-container */
.settings-app-container {
  /* height: 100%; */
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas:
    "settings-app-content";
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  margin: auto !important;
}


/* settings-app-content */
.settings-app-content {
  margin: auto !important;
}

.settings-app-content {
  grid-area: settings-app-content;
  overflow-y: hidden !important;
  overflow-x: hidden;
}

.settings-app-content.mob {
  margin: auto 0;
}

.settings-app-content.dt-mode {
  width: 100%;
  height: 100%;
  padding: 0;
}

/* settings-app-mobile-wrap */
.settings-app-content.ft:not(.dt-mode)>div>.settings-app-mobile-wrap,
.settings-app-content:not(.dt-mode)>.settings-app-mobile-wrap {
  border: 10px solid rgb(150, 150, 150);
  margin: 0px auto;
  border-radius: 8px;
}

/* page-content-container */
.page-content-container {
  position: relative;
}

/* page-dimmer */
.page-dimmer {
  height: 100%;
  width: 100%;
  z-index: 8000;
  position: relative;
  pointer-events: none;
  position: absolute !important;
}

/* start-circle-container */
.start-circle-container {
  position: absolute;
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  margin: auto;
}

.start-circle-container:not(.page-dimmer) {
  margin-top: 3em;
}

/* app-sidebar-left-wrapper */
.app-sidebar-left-wrapper {
  z-index: 10000 !important;
  width: 100%;
  overflow-x: hidden;
  max-width: 90%;
}

.apl-3 .app-sidebar-left-wrapper {
  max-width: 90% !important;
}

.app-sidebar-left-wrapper.full-width {
  max-width: 100%;
}

.dt-on .app-sidebar-left-wrapper {
  max-width: 30%;
}

.app-navigation .menu>.item>.image {
  display: inline-block;
  max-height: 30px !important;
  max-width: 30px !important;
}

.app-sidebar-left-wrapper .menu>.item>.image {
  display: inline-block;
  margin-right: 1em;
  max-height: 24px !important;
  max-width: 24px !important;
}

.app-sidebar-left-wrapper.hdn {
  display: none !important;
}

/* ************************************************************************** */

/* TRANSITION */

/* APP DASHBOARD */
.app-dashboard .content,
.app-dashboard .table-wrapper,
.app-dashboard .adv,
.app-dashboard .transition-wrapper,
.app-dashboard .menu.vertical {
  height: 100% !important;
  overflow-y: hidden !important;
}

.app-dashboard .content,
.app-dashboard .transition-wrapper,
.app-dashboard .menu.vertical {
  overflow-y: auto !important;
}

.transition-wrapper {
  height: 100% !important;
}

.app-settings-grid .footer:not(.setz .footer):not(.app-sidebar-menu .footer),
.app-dashboard .footer:not(.setz .footer) {
  padding: 1em !important;
}

/* APP HEADER */
.top-menu {
  border-radius: 0;
}

.top-menu>.item:nth-child(1) {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.top-menu .right>.item {
  padding: 0 .35em !important;
}

.top-menu>.item:nth-child(1) {
  padding-left: .75em !important;
  padding-right: .75em !important
}

.app-menu.top .menu-title * {
  font-weight: bold !important;
}

.top-menu>.menu-title {
  display: grid !important;
  grid-template-rows: auto auto auto;
  padding-left: .25em !important;
  font-weight: bold;
}

.top-menu>.menu-title>div:not(:first-child) {
  font-size: .8rem;
  padding-top: .5em;
  opacity: .7;
}

.top-menu>.menu-title {
  font-size: .8rem;
}

.app-topMenu {
  grid-area: app-topMenu;
}

/* CONTAINERS */

/* POPUPS */
.item-popup {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  position: fixed;
  right: 1.5em;
  z-index: 1000 !important;
}

.item-popup.single-left {
  left: 2em;
  align-items: flex-start;
}

.item-popup.single-right {
  right: 2em !important;
  align-items: flex-start;
}

.item-popup.solo-right {
  position: relative;
  right: 2em;
  bottom: 2em !important;
  height: 0;
}

.item-popup.solo-right.stag {
  position: fixed;
}

.notes .item-popup {
  bottom: 2em !important;
}

.item-popup.solo-left {
  position: relative;
  align-items: flex-start;
  left: 2em;
}

.item-popup.solo-left.stag {
  position: fixed;
}

.item-popup.indy {
  position: relative;
  align-items: flex-start;
  left: 2em;
}

.item-popup>.item-popup-content {
  padding: .25em .5em;
  border-radius: 2px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.page-wrapper .item-popup {
  bottom: 2em
}