:root {
  --score-allow-edit: rgb(0, 53, 4);
  --score-allow-edit-admin: rgb(2, 0, 58);
  ;
  --score-selected: rgb(201, 233, 255);
  --score-selected-complete: rgb(0, 88, 146);
  --score-selected-c: rgb(66, 66, 66);
  --score-progress: rgb(189, 255, 205);
  --score-def: rgb(14, 214, 30);
  --score-past: rgb(255, 197, 197);
  --score-nr: rgb(208, 208, 208);
  --score-complete: rgb(233, 247, 252);
  --score-scores: rgb(24, 206, 39);
  --score-rt: rgb(219, 251, 219);
  --score-type: rgb(219, 219, 219);
  --score-winner: rgb(0, 141, 7);
  --score-winner-md: rgb(0, 82, 141);
  --match-winner: rgb(0, 0, 0);
  --match-winner-dm: rgb(222, 255, 221);
  --match-loser-dm: rgb(204, 204, 204);
  --match-team: rgb(73, 73, 73);
  --score-blue-back: rgb(56, 117, 209);
  --score-blue: rgb(69, 142, 252);
  --score-red: #db2828;
  --score-green: #21ba45;
  --score-grey: #767676;
  --score-dark-grey: #707070;
  --score-black: #1b1c1d;
  --section-grid-header: rgb(54, 68, 90);
  --score-divider: rgb(223, 223, 223);
  --upset: rgb(248, 255, 153);
  --match-loss: #db2828;
  --match-win: #21ba45;
  --header-light: #f0f0f0;
  --date-wrapper: rgb(215, 215, 215);
  --bracket-wrapper: rgb(96, 96, 96);
  --bracket-wrapper-complete: rgb(18, 83, 0);
  --match-same: rgb(144, 0, 0);
  --match-bye: rgb(0, 52, 71);
  --br-8: 8px;
  --playoff-bg: rgb(16, 12, 37);
  --gtc-standings-match: 6fr repeat(4, 1fr);
  --gtc-standings-match-pts: 6fr repeat(6, 1fr);
  --gtc-standings-half: 8fr repeat(8, 1fr);
  --gtc-standings-quarter: 8fr repeat(6, 1fr);
  --gtc-rankings: 1fr 2fr 8fr repeat(3, 1fr);
}

.playoff-create-container {
  display: grid;
  grid-template-columns: 5% 32% 31% 33%;
  height: 100%;
  /* overflow-y: hidden; */
  gap: .25em;
  background-color: var(--off-black-0);
}

.tmcl {
  display: flex;
  justify-content: space-around;
}

.tmcl>.label {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.tmcl>.label>span {
  padding: .25em;
}

.playoff-create-container.five {
  grid-template-columns: 5% 25% 25% 20% 25%;
}

.ms-realtime {
  border: 5px solid var(--off-black-5) !important;
  border-radius: 12px !important;
}

.sports-seasons>div>div>div {
  padding: .5em;
  font-weight: normal;
}

.sports-seasons .label {
  margin: .2em;
}

.levels-date-matches {
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.levels-date-matches>div:first-child {
  padding: .75em;
  font-weight: bold;
  text-align: center;
  background-color: var(--off-black-2) !important;
  margin-bottom: .25em;
}

.level-date-matches {
  width: 100%;
}

.level-date-matches>.level-date-matches-header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: .5em .5em;
  font-weight: bold;
  background-color: var(--off-black-3) !important;
  border-radius: 4px;
  margin: 0px .25em;
}

.level-date-matches>.level-date-matches-header>div:nth-child(3) {
  text-align: right;
}

.level-date-matches>.level-date-matches-header>div:nth-child(2) {
  text-align: center;
  font-weight: normal !important;
  font-size: .8rem !important;
  color: var(--off-white-1)
}

.level-date-matches>.level-date-matches-header>div:nth-child(3)>.label {
  margin: 0 .75em;
}

.level-date-matches>.level-date-matches-header .label {
  font-weight: normal;
}


.lbls-lbl>.label {
  margin: 0;
}

.lbls-lbl>.label:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.lbls-lbl>.label:nth-child(2) {
  border-radius: 0;
}

.lbls-lbl>.label:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0 !important;
}

.sdw-lbls .ae>.label {
  text-decoration: underline;
}

.level-date-matches .segment {
  padding: 0 1em 1em 1em;
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu>div {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.level-date-match {
  padding: .5em;
  margin-right: .4em;
  border: 1px solid var(--off-black-3);
  border-radius: 4px;
  background-color: var(--off-black-2) !important;
}

.level-date-match>div {
  text-align: left !important;
  font-size: .8rem;
  color: rgb(197, 197, 197) !important;
}

.level-date-match .label {
  margin: .5em !important;
}

.level-date-match>.winner {
  color: white !important;
}

.level-date-matches .level-date-match .match-away .label.msr,
.level-date-matches .level-date-match .match-home .label.msr {
  float: right;
}

.level-date-matches .level-date-match.complete {
  border-left-color: var(--sem-green) !important;
  border-left-width: 2px;
}

.hdn {
  display: none;
}

.tryout_list,
.tryout_list_h {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr 1fr;
}

.tryout_list,
.tryout_list_h {
  padding: .5em;
}

.tryout_list_header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: .5em;
  padding: 1em;
  text-align: center;
}

.tryout_list>div:not(:nth-child(2)):not(:nth-child(3)) {
  text-align: center;
}

.tryout_list {
  border-bottom: 1px solid rgb(218, 218, 218);
}

.tryout_list_h {
  padding-bottom: .5em;
}

.tryout_list_h>div {
  text-align: center;
  font-weight: bold;
}


.ldg {
  height: 100% !important;
  width: 100% !important;
}

.gs-match-list>.item {
  display: grid !important;
  grid-template-rows: auto 1fr;
  gap: 1em;
}

.gs-match-list .label {
  margin-top: .5em;
}

.p-bracket.roundOf16 {
  display: grid;
  grid-template-columns: repeat(2, 50%) !important;
  grid-template-rows: repeat(8, 1fr) !important;
  grid-template-areas:
    "p-r16-1"
    "p-r16-2"
    "p-r16-3"
    "p-r16-4"
    "p-r16-5"
    "p-r16-6"
    "p-r16-7"
    "p-r16-8"
  ;
}

.p-bracket.quarterFinals {
  display: grid;
  grid-template-columns: repeat(4, 50%) !important;
  grid-template-rows: repeat(4, 1fr) !important;
  grid-template-areas:
    "p-qf-1"
    "p-qf-2"
    "p-qf-3"
    "p-qf-4"
  ;
}

.p-bracket.semiFinals {
  display: grid;
  grid-template-columns: repeat(4, 50%) !important;
  grid-template-rows: repeat(2, 1fr) !important;
  grid-template-areas:
    "p-sf-1"
    "p-sf-2"
  ;
}

.p-bracket>div {
  padding: .25em;
}

.p-r16-1 {
  grid-area: p-r16-1;
}

.match-grid {
  border: 1px solid var(--bracket-wrapper);
}

.playoff-top>.match-grid {
  border: 1px solid var(--bracket-wrapper);
  border-bottom: 1px solid var(--off-black-3);
  border-radius: 4px 4px 0 0;
}

.playoff-bottom>.match-grid {
  border: 1px solid var(--bracket-wrapper);
  border-top: 1px solid var(--off-black-3);
  border-radius: 0 0 4px 4px;
}

.playoff-top>.match-grid.complete {
  border: 1px solid var(--bracket-wrapper-complete);
  border-bottom: 1px solid var(--off-black-3);
  border-radius: 4px 4px 0 0;
}

.playoff-bottom>.match-grid.complete {
  border: 1px solid var(--bracket-wrapper-complete);
  border-top: 1px solid var(--off-black-3);
  border-radius: 0 0 4px 4px;
}

.p-r16-2 {
  grid-area: p-r16-2;
}

.p-r16-3 {
  grid-area: p-r16-3;
}

.p-r16-4 {
  grid-area: p-r16-4;
}

.p-r16-5 {
  grid-area: p-r16-5;
}

.p-r16-6 {
  grid-area: p-r16-6;
}

.p-r16-7 {
  grid-area: p-r16-7;
}

.p-r16-8 {
  grid-area: p-r16-8;
}

.p-qf-1 {
  grid-area: p-qf-1;
}

.p-qf-2 {
  grid-area: p-qf-2;
}

.p-qf-3 {
  grid-area: p-qf-3;
}

.p-qf-4 {
  grid-area: p-qf-4;
}

.p-sf-1 {
  grid-area: p-sf-1;
}

.p-sf-2 {
  grid-area: p-sf-2;
}

.matches-filter {
  display: grid;
  height: 100% !important;
}

.matches-filter>.content {
  height: 100%;
  overflow-y: auto;
}

.sports-container {
  padding: .15em;
}

.list-create-teams.list>.item>.content>.description {
  font-size: .8rem !important;
}

.schools-list.vlo,
.sports-active.vlo,
.teams-list.vlo {
  padding-right: 2em !important;
}

.sports-active>.item {
  font-weight: bold !important;
  padding: 1em .25rem !important;
}

.sports-active.menu>.item>.header {
  color: var(--off-white-4) !important;
  text-align: center;
}

.sports-active.menu>.item>.menu>.item {
  padding: .75rem !important;
  font-size: 1rem !important;
}

.sports-active .segment {
  padding: .25em;
}

.sports-active .segment>div {
  padding: 1em;
  margin: .25em;
  background-color: var(--off-black-2) !important;
  ;
  font-size: .8rem;
}


.sports-active.menu.school>.item {
  padding: .25em .25em;
}

.mi-sports>.item {
  display: grid !important;
  grid-template-columns: 5fr 1fr !important;
  grid-template-rows: 1fr 1fr !important;
  grid-template-areas:
    "mi-sports-name mi-sports-active"
    "mi-sports-season mi-sports-season";
}

.mi-sports-name {
  grid-area: mi-sports-name;
  font-weight: bold;
}

.mi-sports-active {
  grid-area: mi-sports-active;
  text-align: right;
}

.mi-sports-season {
  grid-area: mi-sports-season;
}

.mi-access>div {
  display: flex !important;
  flex-direction: column;
}

.mi-access>div>div {
  padding: .5em;
}

.mi-access>div>div:first-child {
  font-weight: bold;
}

.mi-access .input {
  margin-top: .25em !important;
}

.match-scoring-container {
  height: 100%;
}

.match-scoring-container>.segment {
  padding: .5em;
}

.match-scoring-container>.segment>.grid {
  margin: 0;
}

.match-scoring-container>.segment>.grid>.row:first-child {
  font-weight: bold;
}

.form-golfers {
  height: 100%;
}

.athletes-container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  padding: 0 !important;
  background-color: rgb(200, 200, 200);
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
}

.app-dark-mode .athletes-container {
  background-color: var(--off-black-1) !important;
}

.athletes-container>.athlete {
  display: grid !important;
  grid-template-columns: 1fr 6fr 1fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  grid-template-areas:
    "athlete-icon athlete-details athlete-number";
}

.athletes-container.golfers {
  padding: 0 !important;
  display: grid !important;
  grid-template-rows: auto 1fr;
  height: 100%;
  background-color: var(--sem-black) !important;
}

.athletes-container.golfers>div:nth-child(2) {
  height: 100%;
  overflow-y: auto;
  /* scrollbar-width: thin; */
}

.athletes-container>.athlete.golfers {
  grid-template-columns: 1fr 1fr 6fr 1fr !important;
  grid-template-areas:
    "athlete-index athlete-icon athlete-details athlete-number";
}

.athletes-container>.athlete-team-golfers>.athlete-team {
  display: grid !important;
  grid-template-columns: 3fr 2fr 3fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  font-size: .85rem;
  font-weight: bold;
  grid-template-areas:
    "athlete-details-1 athlete-number-1 athlete-number-2 athlete-details-2";
}

.athlete-details-2 {
  text-align: right;
}

.athlete-number-1,
.athlete-number-2,
.athlete-index {
  text-align: center;
}

.athlete-icon {
  grid-area: athlete-icon;
}

.athlete-icon {
  grid-area: athlete-icon;
  text-align: center;
}

.athlete-details {
  grid-area: athlete-details;
  padding: 0 .5em;
  opacity: .8;
}

.athlete-number {
  grid-area: athlete-number;
  text-align: center;
}

.athlete-number>.label {
  min-width: 30px;
}

.athlete-number-1 {
  border-right: 1px solid rgb(216, 216, 216);
}

.athlete-number-2 {
  border-left: 1px solid rgb(216, 216, 216);
}

.athletes-container>.athlete .athlete-name {
  font-weight: bold !important;
  opacity: 1 !important
}

.athlete-icon>i.icon {
  margin: 0 !important
}

.athlete-team-select {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  align-items: center;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: .5em .5em !important;
  background-color: whitesmoke;
  text-align: center;
}

.athlete-team-select .label {
  width: 99% !important;
  padding: 1em .1em;
  background-color: rgb(173, 173, 173);
}

.athlete-team-header {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  text-align: center;
  padding: .5em;
  font-weight: bold;
}

.athlete-team-header-select {
  display: grid !important;
  grid-template-rows: 1fr 1fr !important;
  text-align: center;
  padding: .5em;
  font-weight: bold;
  color: var(--off-white-3);
}

.athlete-team-header-select>div {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
}

.player-split {
  display: flex;
  flex-direction: column;
  gap: .75em;
}

.drop-list>.athletes-container>.athlete {
  margin-bottom: .5em !important;
  background-color: white !important;
}

.ddp>.athletes-container {
  margin: 0 !important;
}

.ddp>.athletes-container>.athlete {
  margin: 0 !important;
  padding: .25em !important;
  background-color: rgb(228, 228, 228) !important;
}

.portal>div {
  width: 100%;
}

.grid-row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  padding: 0 !important;
  align-items: center;
}

.ddp {
  align-items: center;
}

.grid-row>div:first-child {
  font-size: 1rem;
  font-weight: bold;
}

.grid-row>div>div {
  padding: 5em !important;
  align-items: center;
}

.match-scoring-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.team-history .winner-history,
.team-history .runnerUp-history,
.team-history .no-history {
  text-align: center;
  font-size: .8rem;
  padding: .5em;
}

.team-history .no-history {
  text-align: left;
}

.team-history .winner-history>div:nth-child(2),
.team-history .runnerUp-history>div:nth-child(2) {
  display: flex;
  gap: .5em 1em;
  flex-wrap: wrap;
}

.team-history .winner-history>div:nth-child(2),
.team-history .runnerUp-history>div:nth-child(2) {
  font-size: .8rem;
  text-align: left;
}

.team-history .winner-history span,
.team-history .runnerUp-history span {
  color: grey;
  margin-left: .25em
}

.score-type {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(3, 1fr);
}

.score-type>div.selected {
  padding: .5em;
  background-color: var(--score-selected);
  color: var(--score-selected-c);
}

.sets {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: repeat(5, 1fr);
  ;
}

.match-data-results {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 1em;
  height: 100%;
  overflow-y: hidden;
}

.match-data-results.frapp {
  grid-template-columns: repeat(2, 1fr);
}

.match-data-results>div {
  padding: .25em;
}

.match-data-results>div:last-child {
  height: 100%;
  overflow-y: auto;
}

.sets>div {
  padding: .25em;
  border: 1px solid rgb(214, 214, 214);
  margin: .1em;
  border-radius: var(--br-8);
}

.sets>div.selected {
  background-color: var(--score-selected);
  color: var(--score-selected-c);
  font-weight: bold;
}

.sidebar .match-set-scoring {
  background-color: rgb(38, 37, 44) !important;
}

.match-set-scoring {
  padding: 1em .5em;
  z-index: 9999 !important;
}

.match-set-scoring>.header,
.match-set-scoring>.content {
  padding: .25em
}

.match-score-table {
  grid-area: match-score-table;
  grid-template-rows: repeat(2, 1fr);
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  padding: .5em !important;
  gap: 8px;
  background-color: black !important;
  color: white;
  font-size: .9rem !important;
}

.match-score-table>div:not(:first-child) {
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: 7fr repeat(6, 1fr);
  gap: 1px;
}

.match-score-table>div>div {
  padding: .15em
}

.match-score-table>div:not(:first-child)>div:first-child {
  text-align: left;
}

.scores {
  display: grid;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  align-items: center;
  text-align: center;
  grid-template-rows: repeat(1, 1fr);
  ;
  grid-template-columns: repeat(2, 1fr);
}

.scores.single {
  grid-template-columns: repeat(1, 1fr);
}

.scores-set {
  text-align: center;
  padding: 0 0 .5em 0;
  font-weight: bold;
  border-bottom: 1px solid gray;
}

.scores-teams {
  display: grid;
  width: 100%;
  text-align: center;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

.scores-teams>div {
  padding: .5em;
}

.scores .scoring-team {
  display: grid;
  width: 100%;
  gap: 1px;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: auto;
  height: 100%;
  padding: .25em;
}

.scores .sets-team {
  display: grid;
  width: 100%;
  grid-template-rows: repeat(4, 1fr);
}

.scores .sets-team>div {
  padding: .5em;
  font-weight: bold;
}

.scores .scoring-team>div {
  padding: .25em;
  background-color: rgb(233, 233, 233);
  border: 1px solid lightblue;
  border-radius: 4px;
}

.scores .scoring-team.ns>div {
  padding: .25em;
  background-color: rgb(233, 233, 233);
  border-width: 0 !important;
  border-radius: 4px;
}

.scores .sets-team>div.selected,
.scores .scoring-team>div.selected {
  background-color: var(--score-selected);
  color: var(--score-selected-c)
}

.scoring-team>div.def {
  border-color: var(--score-def);
  font-weight: bold;
}

.scores-team,
.match-score-table>div:not(:first-child)>div:first-child {
  font-weight: bold;
}

.match-score-table .winner {
  background-color: var(--score-winner);
  font-weight: bold;
}

.match-score-table.matchDay .winner {
  background-color: var(--score-winner-md);
  font-weight: bold;
}

.scoring-header>div.selected {
  background-color: var(--score-selected);
  color: var(--score-selected-c);
}

.scoring-header>div.complete {
  background-color: var(--score-selected-complete);
  color: white;
}

.scoring-row-1>div:not(:first-child),
.scoring-row-2>div:not(:first-child),
.scoring-header>div:not(:first-child) {
  border-radius: var(--br-8);
}

.match-details {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: repeat(3, 1fr);
  background: #fff;
  width: 100%;
  align-items: center;
  margin: 0 0 .25em 0px;
  padding: .25em;
  grid-template-areas:
    "md-away md-toggle md-home"
    "md-date md-date md-date"
    "md-time md-time md-time";
}

.match-demo {
  border: 1px solid blue;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  grid-template-rows: repeat(1, 1fr);
  width: 100%;
  grid-template-areas:
    "m-a m-r m-h";
}

.m-a {
  grid-area: m-a;
}

.m-r {
  grid-area: m-r;
}

.m-h {
  grid-area: m-h;
}

.match-demo>div:has([class*="m-r"]) {
  display: none;
}

.schedule-match {
  display: grid;
  background: #fff;
  width: 100%;
  row-gap: .5em;
  padding: .25em .25em;
}

.match-grid {
  gap: .5em;
}

.schedule-match.full {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  grid-template-areas:
    "match-date match-time"
    "match-sl match-sl"
    "match-away match-home";
}

.schedule-match.section {
  display: grid;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: repeat(1, 1fr);
  align-items: center;
  grid-template-areas:
    "match-away match-display match-home";
}

.schedule-match.section.mopts,
.schedule-match.section.rt {
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "match-away . match-home"
    "match-display match-display match-display";
}

.schedule-match .match-score-table {
  border: 3px solid var(--off-black-3)
}

.match-rtc {
  display: grid;
  grid-template-columns: 4fr 1fr 4fr !important;
  grid-template-rows: repeat(1, 1fr) !important;
  grid-area: match-rtc;
  text-align: center;
  padding: .5em 0;
}

.match-rtc>.label {
  border: 1px solid var(--off-black-5) !important;
  color: white;
}

.match-rtc.al>.label:nth-child(1) {
  border-bottom: 1px solid var(--sem-green) !important;
}

.match-rt>.label:nth-child(2) {
  border-bottom: 1px solid var(--sem-blue) !important;
}

.match-rtc.hl>.label:nth-child(3) {
  border-bottom: 1px solid var(--sem-green) !important;
}

.match-rtc.as>.label:nth-child(1),
.match-rtc.as>.label:nth-child(3) {
  border-bottom: 1px solid var(--sem-blue) !important;
}

.match-score-opts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-area: match-score-opts;
  text-align: center;
}

.match-score-btns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-area: match-score-btns;
  text-align: center;
  padding: 1em .5em;
  background-color: var(--off-black-2);
  border: 4px solid var(--off-black-4);
  border-radius: 8px;
}

.match-score-btns>.vert-container {
  row-gap: .5em;
}

.match-grid .match-score-btns {
  padding-top: .5em;
}

.match-score-btns.kpd,
.match-score-btns.opts,
.match-score-btns.rmv,
.match-score-btns.amd {
  grid-template-columns: 1fr;
  text-align: center;
  padding: .5em 1em;
}


.match-score-btns.amd>.buttons {
  display: flex;
  text-align: center;
  padding: .5em 1em;
}

.match-score-btns.rmv>.buttons {
  display: grid;
  grid-template-columns: 1fr 6fr;
  text-align: center;
  padding: .5em 1em;
}

.schedule-match.blocked.section {
  grid-template-columns: 2fr 2fr 1fr;
  grid-template-areas:
    "match-away match-away-score match-score-status";
}

.schedule-match:not(.rt):not(.lmo):not(.mopts).future {
  grid-template-columns: 4fr 2fr 4fr !important;
}

.schedule-match.mmr:not(.rt).future {
  grid-template-columns: 4fr 2fr 4fr !important;
  grid-template-rows: repeat(2, 1fr) !important;
  grid-template-areas:
    "match-away match-display match-home"
    "media-media media-media media-media";

}

.schedule-match.playoff,
.schedule-match.playoff.bye {
  border-bottom: 1px solid var(--off-black-2) !important;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "match-location . match-sl"
    "match-away match-display match-home";
}

.schedule-match.playoff:has(.match-scores),
.schedule-match.playoff:has(.match-scores).bye {
  border-bottom: 1px solid var(--off-black-2) !important;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-location . match-sl"
    "match-away match-display match-home"
    "match-scores match-scores match-scores";
}

.schedule-match.playoff.lch,
.schedule-match.playoff.lch.bye {
  border-bottom: 1px solid var(--off-black-2) !important;
  grid-template-columns: 4fr 2fr 4fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date . match-sl"
    "match-away match-display match-home"
    "match-scores match-scores match-scores";
}

.schedule-match.playoff.flip,
.schedule-match.playoff.flip.bye {
  grid-template-areas:
    ". . match-sl"
    "match-home match-display match-away"
    "match-trophy match-media match-location"
    "match-scores match-scores match-scores";
}

.schedule-match.playoff.rt {
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    ". . match-sl"
    "match-away . match-home"
    "match-trophy match-media match-location"
    "match-rt match-rt match-rt";
}

.schedule-match.playoff.flip.rt {
  grid-template-areas:
    ".  match-sl"
    "match-home match-away"
    "match-trophy match-location"
    "match-display match-display";
}

.schedule-match.playoff.rt.rtm {
  grid-template-rows: repeat(5, 1fr) auto;
  grid-template-areas:
    ". . match-sl"
    "match-away match-display match-home"
    "match-trophy match-media match-location"
    "match-rt match-rt match-rt"
    "match-score-btns match-score-btns match-score-btns";
}

.schedule-match.playoff.flip.rt.rtm {
  grid-template-rows: repeat(4, 1fr) auto;
  grid-template-areas:
    ". . match-sl"
    "match-home . match-away"
    "match-trophy match-location"
    "match-rt match-rt match-rt"
    "match-score-btns match-score-btns match-score-btns";
}


.schedule-match.pool {
  padding: .75em;
  width: 100%;
  grid-template-rows: repeat(2, 1fr);
  border-bottom: 1px solid var(--score-divider);
  grid-template-areas:
    "pool-header"
    "pool-match";
}

.match-playoff {
  display: grid;
  grid-area: match-playoff;
  grid-template-columns: 2fr 1fr 2fr;
  text-align: center;
  font-size: .8rem;
  padding-top: .25em;
  grid-template-areas:
    "match-round match-display match-location";
}

.schedule-match.playoff.rt .match-playoff {
  grid-template-columns: 33% 33% 33%;
}

.matches-latest>.vert-container>.content {
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
}

.matches-latest-team>.vert-container>.content {
  display: grid;
  grid-template-rows: repeat(5, 1fr) !important;
}

.matches-latest-team:nth-child(2) {
  border-left: 1px solid var(--off-black-3) !important;
}

.schedule-match.teams.lmo {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  padding: 0 .75em;
  align-items: center;
  grid-template-areas:
    "match-display"
    "match-last-date"
    "match-opponent" !important;
}

.matches-latest .schedule-match.lmo {
  padding: 0 .25em;
}

.matches-latest-team .schedule-match.lmo {
  grid-template-columns: 1fr 1fr !important;
  grid-template-rows: repeat(1, 1fr);
  padding: .25em;
  grid-template-areas:
    "match-display match-opponent" !important;
}

.summary-split .matches-latest-team:last-of-type .schedule-match.lmo {
  grid-template-areas:
    "match-opponent match-display" !important;
}

.match-last-date {
  grid-area: match-last-date;
  font-size: .8rem;
  text-align: center;
}

.match-players {
  text-align: center;
  font-size: .8rem;
}

.match-players>div,
.match-form>div,
.team-overview>div {
  margin-bottom: .5em;
  border: solid 1px var(--off-black-1);
  border-radius: var(--br-8) !important;
  padding: .25em;
  background-color: var(--off-black-1);
}

.team-overview>div>div {
  background-color: var(--off-black-2);
}

.team-overview .vert-container>.header {
  background-color: var(--off-black-2) !important;
}

.match-players>div:first-child,
.match-form>div:first-child,
.team-overview>div>div:first-child {
  padding: .75em 1em;
}

.team-overview .schedule-match {
  border: 0 !important;
}

.app-dark-mode .team-overview>.segments>.segment:first-child {
  color: white !important;
}

.school-info {
  font-size: .9rem !important;
  padding: .5em !important;
}

.school-info>div {
  display: grid;
  grid-template-columns: 1fr 3fr !important;
  padding: .25em;
}

.team-overview>.segments>.segment:last-child {
  padding: .75em;
}

.team-overview>.segments>.segment:first-child {
  font-weight: bold;
}

.app-dark-mode .schedule-match {
  background-color: var(--sem-black) !important;
  border-bottom-color: var(--sem-black-b) !important;
}

.app-dark-mode .pool-match>.teams>.match-home,
.app-dark-mode .pool-match>.teams>.match-away {
  color: var(--off-white-2);
}

.app-dark-mode .pool-match>.teams>.match-home.w,
.app-dark-mode .pool-match>.teams>.match-away.w {
  color: white;
}

.app-dark-mode .schedule-match>.match-home,
.app-dark-mode .schedule-match>.match-away {
  color: white;
}

.app-dark-mode .standings-selected {
  background-color: var(--sem-black-b) !important;
  color: rgb(242, 242, 242) !important;
}

.app-dark-mode .athletes-container>.athlete {
  background-color: var(--sem-black) !important;
  border-bottom-color: var(--sem-black-b) !important;
}

.app-dark-mode .ms-summary>div {
  background-color: var(--sem-black) !important;
}

.app-dark-mode .ms-summary {
  background-color: black;
}

.app-dark-mode .table-rankings {
  border-radius: var(--br);
  border: 1px solid var(--off-black-3)
}

.app-dark-mode .table-rankings,
.app-dark-mode .table-rankings th {
  background-color: black !important;
  background: black !important;
  color: white !important;
}

.pool-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em 0;
  grid-template-areas:
    "match-court match-work";
}

.pool-match {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  background: #fff;
  width: 100%;
  font-size: .85rem;
  padding: .5em 0;
  align-items: center;
  grid-template-areas:
    "match-away match-home";
}

.match-grid>.pool-match {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  background: #fff;
  width: 100%;
  font-size: .85rem;
  align-items: center;
  gap: .5em;
  grid-template-areas:
    "match-ls"
    "teams"
    "match-dates";
}

.match-grid>.pool-match:has(.match-rtc) {
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    "match-ls"
    "teams"
    "match-rtc"
    "match-dates";
}

.match-grid>.pool-match>.teams {
  display: grid;
  grid-template-columns: 85% 15%;
  grid-template-rows: repeat(2, 1fr);
  background: #fff;
  width: 100%;
  font-size: .85rem;
  align-items: center;
  grid-template-areas:
    "match-away result-away"
    "match-home result-home";
}

.match-grid>.pool-match>.teams.flip {
  grid-template-areas:
    "match-home result-home"
    "match-away result-away";
}

.match-ls {
  grid-area: match-ls;
}

.match-ls>.label {
  font-weight: 500 !important;
}

.match-dates {
  display: grid;
  color: var(--off-white-4);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: .5em;
  grid-area: match-dates;
  grid-template-areas:
    "match-date match-time"
    "match-location match-location";
}

.match-dates>div {
  padding: .25em;
}

.match-results-score {
  display: grid;
  grid-area: match-results-score;
  grid-template-columns: repeat(3, 1fr);
}

.match-dates {
  grid-template-columns: repeat(2, 1fr) !important;
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas:
    "match-date match-time";
}

.match-dates.loc {
  grid-template-columns: repeat(2, 1fr) !important;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "match-date match-time"
    "match-location match-location";
}

.match-dates.results {
  /* grid-template-columns: repeat(3, 1fr) !important; */
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "match-results-se match-results-se match-results-se"
    "match-date match-location match-time";
}

.playoff-grid .match-dates.results {
  grid-template-columns: repeat(2, 1fr) !important;
  grid-template-rows: repeat(2, 1fr) !important;
  grid-template-areas:
    "match-results-se match-results-se"
    "match-date match-time"
    "match-location match-location" !important;
}

.playoff-grid .match-dates.results.loc {
  grid-template-rows: repeat(3, 1fr) !important;
  grid-template-areas:
    "match-results-se match-results-se"
    "match-date match-time"
    "match-location match-location" !important;
}

.schedule-match.teams {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: repeat(2, 1fr);
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .75em;
  border-bottom: 1px solid rgb(192, 192, 192);
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display match-home";
}

.schedule-match.teams.opts,
.schedule-match.teams.rt {
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date . match-sl "
    "match-away . match-home"
    "match-display match-display match-display";
}

/* when the scores are available for the match */
.schedule-match.section.scrs:not(.playoff.scrs) {
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "match-away match-display match-home"
    "match-scores match-scores match-scores";
}

.schedule-match.teams.scrs {
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date . match-sl"
    "match-away match-display match-home"
    "match-scores match-scores match-scores";
}

.schedule-match.teams.tia.oo:not(.playoff) {
  grid-template-areas:
    "match-date . match-sl "
    "match-home match-display .";
}

.schedule-match.teams.tih.oo:not(.playoff) {
  grid-template-areas:
    "match-date . match-sl "
    "match-away match-display .";
}

.schedule-match.teams.playoff {
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "match-date match-location match-sl "
    "match-away match-display match-home";
}

.schedule-match.teams.playoff.scrs {
  grid-template-rows: repeat(z, 1fr);
  grid-template-areas:
    "match-date match-location match-sl "
    "match-away match-display match-home"
    "match-scores match-scores match-scores";
}

.schedule-match.teams.playoff.rt {
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "match-date . match-sl "
    "match-away . match-home"
    "match-trophy . match-location"
    "match-display match-display match-display";
}

/* This works mopts adds match-score-btns */
.schedule-match.section.mopts {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "match-away match-display match-home"
    "match-score-btns match-score-btns match-score-btns";
}

.schedule-match.section.playoff.mopts {
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    ". . match-sl"
    "match-away match-display match-home"
    "match-score-btns match-score-btns match-score-btns";
}

.schedule-match.section.playoff.scrs.mopts {
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    ". . match-sl"
    "match-away match-display match-home"
    "match-scores match-scores match-scores"
    "match-score-btns match-score-btns match-score-btns";
}

.schedule-match.rtm-current {
  display: grid;
  grid-template-rows: repeat(2, 1fr) !important;
  grid-template-areas:
    "match-away match-display match-home"
    "match-rtc match-rtc match-rtc" !important;
}

.schedule-match.section.rtm-current.mopts {
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "match-away match-display match-home"
    "match-rtc match-rtc match-rtc"
    "match-score-btns match-score-btns match-score-btns" !important;
}

.schedule-match.section.playoff.rtm-current {
  grid-template-rows: 1fr 1fr 1fr auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    ". . match-sl"
    "match-away match-display match-home"
    "match-rtc match-rtc match-rtc" !important;
}

.schedule-match.section.playoff.rtm-current.mopts {
  grid-template-rows: 1fr 1fr 1fr auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    ". . match-sl"
    "match-away match-display match-home"
    "match-rtc match-rtc match-rtc"
    "match-score-btns match-score-btns match-score-btns" !important;
}


.schedule-match.section.mopts.rt {
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "match-away match-rt match-home"
    "match-score-btns match-score-btns match-score-btns";
}

.schedule-match.teams.tih:not(.oo) .match-away {
  font-weight: normal !important;
  color: var(--off-white-4);
}

.schedule-match.teams.tia:not(.oo) .match-home {
  font-weight: normal !important;
  color: var(--off-white-4);
}

.schedule-match.teams.tia.oo:not(.playoff) .match-away {
  display: none;
}

.schedule-match.teams.tih.oo:not(.playoff) .match-home {
  display: none;
}

.schedule-match.teams.rt:not(.lmo) {
  grid-template-columns: 3fr 2fr 3fr;
}

.team-overview .schedule-match.teams.rt:not(.lmo) {
  grid-template-columns: 4fr 3fr 4fr;
}

.schedule-match.teams.blocked {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "match-date match-sl match-score-status"
    "match-away match-away-score match-away-score"
    "match-home match-home-score match-home-score";
}

.match-result {
  grid-area: match-result;
}

.schedule-match.team {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 2fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .5em;
  grid-template-areas:
    "match-away match-display match-home"
    "match-ls match-ls match-ls";
}

.schedule-match.teams.scores {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .5em;
  grid-template-areas:
    "score-away match-away match-display match-home score-home";
}

.push-header.score-types {
  padding: .5em 2em
}

.push-header.score-types .menu-slider {
  background-color: var(--score-type);
}

.score-away,
.result-away {
  grid-area: result-away;
  font-size: 1rem;
  text-align: center;
  color: var(--off-white-4);
}

.score-home,
.result-home {
  grid-area: result-home;
  font-size: 1rem;
  text-align: center;
  color: var(--off-white-4);
}

.score-away.w,
.score-home.w,
.result-away.w,
.result-home.w {
  color: white;
  font-weight: bold;
}

.match-date {
  grid-area: match-date;
  font-size: .8rem;
}

.legend-team {
  color: var(--upset) !important;
}

.match-time {
  grid-area: match-time;
  font-size: .8rem;
  justify-self: right;
}

.match-display {
  grid-area: match-display;
  text-align: center;
  font-size: .9rem;
  font-weight: bold;
  border-radius: 4px;
  padding: .15em;
  color: white;
}

.match-display.rt {
  border-bottom: 1px solid var(--sem-green) !important;
}

.ms-result.matchDay,
.match-display.matchDay {
  border-bottom: 1px solid var(--sem-blue) !important;
}

.match-display.rt.flash {
  background-color: var(--sem-blue) !important;
}

.match-display.matchDay.flash {
  background-color: var(--sem-green) !important;
}

.match-rtc.flash-away>.label:nth-child(1) {
  background-color: var(--sem-blue) !important;
}

.match-rtc.flash-home>.label:nth-child(3) {
  background-color: var(--sem-blue) !important;
}

.match-rt {
  grid-area: match-rt;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: auto auto;
  grid-area: match-rt;
  grid-template-areas:
    "away label home"
    "match-display-rt match-display-rt match-display-rt";
  text-align: center;
  align-items: center;
  padding: .2em;
  border: 1px solid var(--off-black-2);
  border-radius: 4px;
}

.match-display-rtm {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  text-align: center;
}

.rtm-set {
  display: flex;
}

.match-display-rt {
  grid-area: match-display-rt;
  border-radius: 4px;
  padding: .25em;
  font-weight: bold;
}

.match-rt>.label {
  color: white !important;
  text-align: center;
}

.match-rt>.label:nth-child(2) {
  display: flex;
  justify-content: space-around;
}

.match-rt>.label:nth-child(2)>div:last-child {
  color: var(--sem-blue)
}

.schedule-match>div {
  align-self: center !important;
}

.schedule-match.lmo.teams>.match-display {
  padding: .1em;
}

.schedule-match.ame:not(.complete):not(.rt) .match-display {
  border: 1px solid var(--sem-green) !important;
}

.schedule-match.ame .match-display {
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.ase .match-display {
  border-bottom: 1px solid var(--sem-blue) !important;
}

.schedule-match.mmr .match-display {
  background-color: var(--sem-green-blackish) !important;
}

.match-display>div {
  text-align: center;
}

.match-display.paste>.label {
  background-color: var(--score-past);
  color: black !important;
}

.team-overview .match-display.complete,
.schedule-match.complete .match-display>.label {
  background-color: var(--score-complete);
  font-weight: bold;
  text-align: center;
}

.schedule-match.complete .match-display {
  font-size: 1rem;
}

.team-overview .schedule-match {
  padding: .25em
}

.match-display>.label {
  width: 100%;
}

.schedule-match.rt .match-display>.label {
  padding: .5em .5em;
  color: white;
  font-size: 1rem;
}

.schedule-match.rt.ame .match-display>.label:nth-child(2) {
  border: 1px solid gray !important;
}

.pool-match.rt .up.home,
.match-grid .match-rt .up.home,
.schedule-match.rt .match-rt>.label.up.home {
  border-right: 1px solid var(--sem-green);
}

.pool-match.rt .up.away,
.match-grid .match-rt .up.away,
.schedule-match.rt .match-rt>.label.up.away {
  border-left: 1px solid var(--sem-green);
}

.pool-match.rt.scp .up,
.schedule-match.rt .match-display.scp>.label.up {
  background-color: var(--sem-green);
}

.schedule-match.rt .match-display .button.up {
  border-top: 1px solid var(--sem-green) !important;
  border-bottom: 1px solid var(--sem-green) !important;
}

.schedule-match.rt .match-display .button.down {
  border-top: 1px solid var(--sem-red) !important;
  border-bottom: 1px solid var(--sem-red) !important;
}

.schedule-match.rt .match-display .button.tie {
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}

.pool-match.rt .down.home,
.match-grid .match-rt .down.home,
.schedule-match.rt .match-rt>.label.down.home {
  border-right: 1px solid var(--sem-red);
}

.pool-match.rt .down.away,
.match-grid .match-rt .down.away,
.schedule-match.rt .match-rt>.label.down.away {
  border-left: 1px solid var(--sem-red);
}

.pool-match.rt .tie,
.schedule-match.rt .match-display>.label.tie {
  border: 1px solid var(--sem-blue);
}

.team-overview .match-display.win,
.match-display.win>.label {
  border-color: var(--match-win);
  color: white;
}

.team-overview .match-display.loss,
.match-display.loss>.label {
  background-color: var(--match-loss);
  color: white;
}

.match-display.score-available {
  border: 1px solid var(--score-scores) !important;
  font-weight: bold;
  border-radius: 4px;
}

.ms-summary .match-display,
.schedule-match.rt:not(.lmo) .match-display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.matches-latest .schedule-match.rt:not(.lmo) .match-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "match-display-score match-display-score"
    "team-score-away team-score-home";
}

.summary-split {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.team-score-away {
  grid-area: team-score-away;
}

.team-score-home {
  grid-area: team-score-home;
}

.mr {
  grid-area: mr;
}

.label.super-small {
  min-width: .5em !important;
  min-height: .5em !important;
  margin-right: .5em !important;
}

.match-sl {
  grid-area: match-sl;
  text-align: right;
}

.match-sl>.label {
  color: var(--off-white-3) !important;
  font-weight: normal;
}

.match-score {
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
}

.match-score.progress {
  background-color: var(--score-progress);
}

.match-playoff-header {
  text-align: center;
  grid-area: match-playoff-header;
  font-weight: bold;
}

.match-away,
.match-home {
  overflow-wrap: break-word;
  white-space: normal;
  align-items: center !important;
}

.schedule-match .match-away {
  display: grid;
  grid-template-columns: auto 4fr;
  grid-area: match-away;
  color: var(--match-team);
  text-align: left;
}

.schedule-match .match-home {
  display: grid;
  grid-template-columns: 4fr auto;
  grid-area: match-home;
  text-align: right;
  color: var(--match-team);
}

.match-away>span,
.match-home>span {
  font-weight: bold !important;
  font-size: 1rem !important;
}

.match-away>.label,
.match-home>.label {
  line-height: 1em !important;
  max-height: 1em;
}

.playoff .match-away,
.playoff .match-home {
  flex-direction: row-reverse;
}

.pool-match .match-away,
.pool-match .match-home {
  justify-content: flex-start;
}

.schedule-match.flip>.match-away {
  text-align: right;
}

.schedule-match.flip>.match-home,
.teams.flip>.match-home {
  text-align: left;
}

.schedule-match.teams.tia.oo:not(.playoff) .match-home {
  text-align: left;
}

.match-home-score {
  grid-area: match-home-score;
  font-weight: bold !important;
}

.match-away-score {
  grid-area: match-away-score;
  font-weight: bold !important;
}

.match-away-score.win>.label,
.match-home-score.win>.label {
  background-color: var(--sem-green);
  color: white;
}

.match-away-score.loss>.label,
.match-home-score.loss>.label {
  background-color: var(--sem-red);
  color: white;
}

.match-score-status {
  grid-area: match-score-status;
  text-align: right;
}

.match-opponent-full {
  grid-area: match-opponent-full;
  font-weight: bold;
  text-align: left;
}

.match-opponent {
  grid-area: match-opponent;
  text-align: center;
  font-size: .8rem;
  font-weight: bold;
}

.match-full-score {
  grid-area: match-full-score;
  text-align: center;
  padding: .5em 0
}

.playoff-grid .match-away>span,
.playoff-grid .match-home>span {
  font-size: .9rem !important;
}

.pbsd {
  text-align: center;
}

.match-number {
  grid-area: match-number;
  font-weight: bold;
}

.match-grid .match-home {
  text-align: left;
}

.blocked .match-home {
  text-align: left;
}

.match-work {
  grid-area: match-work;
  text-align: right;
  font-size: .7rem;
}

.match-court {
  grid-area: match-court;
  font-size: .7rem;
}

.match-ls {
  grid-area: match-ls;
  text-align: right;
}

.score-away {
  grid-area: score-away;
}

.score-home {
  grid-area: score-home;
}

.match-round {
  grid-area: match-round;
  text-align: left;
  font-size: .8rem;
  color: var(--off-white-4);
}

.match-media {
  grid-area: match-media;
  text-align: center;
  font-size: .8rem;
  color: var(--off-white-4);
}

.match-location {
  grid-area: match-location;
  text-align: right;
  font-size: .8rem;
  font-weight: normal !important;
  color: var(--off-white-4);
}

.match-results {
  grid-area: match-results;
  text-align: center;
  font-size: .8rem;
  font-weight: normal !important;
  color: var(--off-white-4);
}

.match-scores>span,
.match-results-se {
  display: flex;
  justify-content: space-around;
  grid-area: match-results-se;
  background-color: var(--off-black-3);
}

.schedule-match.wh .abs>span:nth-child(3),
.pool-match.wh .abs>span:nth-child(3) {
  color: white !important
}

.schedule-match.wa .abs>span:nth-child(1),
.pool-match.wa .abs>span:nth-child(1) {
  color: white !important
}

.abs {
  margin: 0 .1em;
  font-size: .8rem;
  color: var(--off-white-4) !important
}

.match-dates .whmp,
.match-scores .whmp {
  color: var(--sem-green)
}

.match-dates .lhmp,
.match-scores .lhmp {
  color: var(--sem-red)
}

.match-trophy {
  grid-area: match-trophy;
  text-align: left;
  font-size: .8rem;
  font-weight: normal !important;
  color: var(--off-white-4);
}

.match-scores {
  grid-area: match-scores;
  text-align: center;
  font-size: .8rem;
  font-weight: normal !important;
}

.match-scores>span,
.schedule-match.section .match-scores>span {
  background-color: var(--off-black-3);
  padding: .2em 1em;
  border-radius: 4px;
}

.schedule-match.playoff .match-location {
  text-align: left;
}

.schedule-match.playoff.teams .match-location {
  text-align: center;
}

.match-dates .match-location {
  text-align: center;
}

.schedule-match>.match-away .label,
.schedule-match>.match-home .label {
  width: 20px;
  text-align: center;
  padding: .5em !important;
}

.schedule-match .match-away .label {
  margin-right: .5em;
}

.schedule-match.flip .match-away .label {
  margin-right: 0;
  margin-left: .5em;
}

.schedule-match .match-home .label {
  margin-left: .5em;
}

.schedule-match.flip .match-home .label {
  margin-left: 0;
  margin-right: .5em;
}

.app-dark-mode .match-away,
.app-dark-mode .match-home {
  font-size: 1rem !important;
  color: var(--off-white-3) !important;
}

.app-dark-mode .wa .match-away {
  font-weight: bold;
}

.app-dark-mode .wh .match-home {
  font-weight: bold;
}

.app-dark-mode .complete .match-away.winner,
.app-dark-mode .complete .match-home.winner {
  color: var(--match-winner-dm) !important;
  color: var(--off-white-0) !important;
}

.app-dark-mode .complete .match-away:not(.winner),
.app-dark-mode .complete .match-home:not(.winner) {
  font-weight: normal !important;
  color: var(--off-white-3) !important;
}


.schedule-matchDate {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-matchDate-header"
    "schedule-matchDateTeams";
}

.schedule-matchDate-header {
  grid-area: schedule-matchDate-header;
  padding: .25em;
  font-weight: bold;
  font-size: .8rem;
  text-align: center;
  border-top: 1px solid rgb(204, 204, 204);
  border-bottom: 1px solid rgb(204, 204, 204);
}

.schedule-matchDateTeams {
  grid-area: schedule-matchDateTeams;
}

.schedule-dates {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  max-width: 600px;
  margin: auto;
  align-items: center;
  grid-template-areas:
    "schedule-dates-header"
    "schedule-levels";
}

.schedule-dates-wrapper {
  height: 100%;
  overflow-y: auto;
}

.schedule-dates-header {
  grid-area: schedule-dates-header;
  padding: .5em;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.match-buttons-container .schedule-dates-header {
  padding: 0 .5em;
}

.schedule-dates {
  height: 100%;
}

.schedule-dates.nr {
  opacity: .2;
}

.match-grids {
  max-width: 600px;
  margin: auto;
}

.schedule-dates-header>.label {
  margin-right: 1em;
}

.schedule-dates-header>.icon {
  float: right;
}

.schedule-dates-ls {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-dates-header schedule-dates-header-ls"
    "schedule-levels schedule-levels";
}

.schedule-dates-header-ls {
  grid-area: schedule-dates-header-ls;
  padding: .5em;
  font-weight: bold;
}

.schedule-levels {
  grid-area: schedule-levels;
  border-bottom: 1px solid rgb(207, 207, 207);
  align-self: self-start;
  padding: 0 .5em 0 0;
}

.sdw {
  background-color: var(--date-wrapper) !important;
  border: 1px solid var(--date-wrapper);
  border-radius: var(--br);
  padding: 0em;
  margin: .5em .25em;
  text-align: left;
}

.sdw>div:first-child {
  display: flex;
  justify-content: space-between;
  padding: .5em .5em .5em 1em;
  font-size: 1rem;
  font-weight: bold;
  background-color: var(--date-wrapper);
  border-radius: var(--br-8) var(--br-8) 0 0;
}

.sdw>div>div:nth-child(2)>.label {
  margin-left: .5em !important;
}

.schedule-level {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  padding: .25em;
  grid-template-areas:
    "schedule-level-header"
    "schedule-level-section";
}

.schedule-level-header {
  grid-area: schedule-level-header;
  padding: .5em;
  font-weight: bold;
  background-color: rgb(214, 213, 213)
}

.schedule-level-section {
  grid-area: schedule-level-section;
}

.schedule-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background: #fff;
  width: 100%;
  align-items: center;
  grid-template-areas:
    "schedule-section-header"
    "schedule-section-teams";
}

.schedule-section>.divider {
  margin-top: .25em;
  margin-bottom: 0;
  font-size: .8rem;
  color: rgb(170, 170, 170) !important;
}

.schedule-section>.divider>.header {
  color: grey;
  font-size: .8rem;
  font-weight: 600 !important;
  color: rgb(170, 170, 170) !important;
}

.schedule-section .divider .label {
  font-size: .2rem;
}

.date-header {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr 1fr;
}

.schedule-date-header {
  border-top: 3px solid rgb(185, 185, 185);
  padding: .5em;
  font-weight: bold;
  background-color: rgb(64, 64, 64);
  color: white;
}

.schedule-section.fav {
  margin-bottom: .5em;
  border-bottom: 1px solid rgb(199, 199, 199);
  padding: .25em;
}

.schedule-section.fav>.schedule-section-header {
  background-color: rgb(214, 255, 214);
  font-weight: bold;
}

.schedule-section.fav>.schedule-section-header>i.icon {
  margin-right: .5em;
}

.app-dark-mode .schedule-section.fav {
  border-bottom: 1px solid var(--off-black-3)
}

.schedule-section-header {
  grid-area: schedule-section-header;
  padding: .5em;
  background-color: rgb(221, 221, 221);
  border: 1px solid rgb(185, 185, 185);
  font-weight: bold;
  border-radius: 4px;
}

.schedule-section-teams {
  grid-area: schedule-section-teams;
  padding: .5em 0
}

.schedule-section-teams.mld {
  grid-area: schedule-section-teams;
  padding: .25em;
}

.schedule-section-teams>.segment>div {
  font-size: .9rem;
  color: var(--off-white-3) !important;
}

.schedule-levels>div,
.schedule-levels>div>div {
  width: 100% !important;
  height: 100% !important;
  max-width: none !important;
  max-height: none !important;
}

.league-standings {
  padding: .5em .5em;
  width: 100%;
  height: 100%;
}

.standings-container {
  padding: .25em !important;
  width: 100%;
  border-radius: 8px !important;
  box-shadow: none !important;
  margin-bottom: .5em;
}

.standings-container.team {
  padding-top: .25em;
  width: 100%;
  margin-bottom: 0 !important;
}

.standings-header {
  padding: .75em !important;
  font-weight: bold;
  text-align: center !important;
  width: 100%;
  border-radius: var(--br-8) var(--br-8) 0 0;
}

.standings-header>.icon.star {
  margin-right: 1em;
}

.standings-header>.icon.picture {
  float: right;
}

.standings-table {
  padding: 0 0 0 .2em !important;
  font-size: 1rem !important;
  width: 100%;
}

.standings-table .label {
  color: white;
}

.standings-table .standing-team {
  display: grid;
  width: 100%;
}

.standings-table .standing-team div:first-child {
  display: flex;
  align-items: center;
}

.standings-table .standing-team div.nmr {
  font-size: .9rem;
}

.standings-table .standing-team.ptc {
  background-color: var(--off-black-5);
}

.standings-table.no-ties .standing-team,
.standings-table.match .standing-team,
.standings-table.quarter:not(.at) .standing-team {
  grid-template-columns: var(--gtc-standings-match);
}

.standings-table.no-ties .standing-team.pts,
.standings-table.match .standing-team.pts {
  grid-template-columns: var(--gtc-standings-match-pts);
}

.standings-table.quarter.at .standing-team {
  grid-template-columns: var(--gtc-standings-quarter);
}

.standings-table.ties .standing-team,
.standings-table.half .standing-team {
  grid-template-columns: var(--gtc-standings-half);
}


.standings-table.quarter .standing-team>div:nth-child(2),
.standings-table.half .standing-team>div:nth-child(2),
.standings-table.ties .standing-team>div:nth-child(2),
.standings-table.no-ties:not(.pts) .standing-team>div:nth-child(4),
.standings-table.no-ties.pts .standing-team>div:nth-child(5),
.standings-table.match .standing-team>div:nth-child(4) {
  border-left: 1px solid var(--off-black-5)
}

.standings-table.quarter .standing-team>div:nth-child(5),
.standings-table.half .standing-team>div:nth-child(6),
.standings-table.ties .standing-team>div:nth-child(6),
.standings-table.no-ties .standing-team>div:nth-child(2),
.standings-table.match .standing-team>div:nth-child(2) {
  border-left: 1px solid var(--off-black-5)
}

.standing-section-header>div,
.standing-team>div {
  padding: .4em .25em;
}

.standings-table .standing-team>div:nth-child(n+2) {
  text-align: center;
}

.standing-section-header>div:nth-child(n+2),
.standings-table.ties .standing-team>div:nth-child(n+2),
.standings-table.half .standing-team>div:nth-child(n+2) {
  font-size: .9rem !important;
  padding-left: 0;
  padding-right: 0;
}

.standing-team .team-rank {
  background-color: var(--off-black-4) !important;
}

.standings-table.no-ties .standing-section-header,
.standings-table.match .standing-section-header,
.standings-table.quarter:not(.at) .standing-section-header {
  display: grid;
  grid-template-columns: var(--gtc-standings-match);
  grid-template-areas:
    ". ss-section ss-section ss-overall ss-overall"
    "ss-team ss-w ss-l ss-ow ss-ol";
}

.standings-table.no-ties.pts .standing-section-header,
.standings-table.match.pts .standing-section-header {
  display: grid;
  grid-template-columns: var(--gtc-standings-match-pts);
  grid-template-areas:
    ". ss-section ss-section ss-section ss-overall ss-overall ss-overall"
    "ss-team ss-w ss-l ss-p ss-ow ss-ol ss-op";
}

.standings-table.quarter.at .standing-section-header {
  display: grid;
  grid-template-columns: var(--gtc-standings-quarter);
  grid-template-areas:
    ". ss-section ss-section ss-section ss-overall ss-overall ss-overall"
    "ss-team ss-w ss-l ss-t ss-ow ss-ol ss-ot";
}

.standings-table.ties .standing-section-header,
.standings-table.half .standing-section-header {
  display: grid;
  grid-template-columns: var(--gtc-standings-half);
  grid-template-areas:
    ". ss-section ss-section ss-section ss-section ss-overall ss-overall ss-overall ss-overall"
    "ss-team ss-w ss-l ss-t ss-p ss-ow ss-ol ss-ot ss-op";
}

.standing-section-header>div {
  text-align: center;
}

.ss-section {
  grid-area: ss-section;
}

.ss-overall {
  grid-area: ss-overall;
}

.ss-team {
  grid-area: ss-team;
}

.ss-w {
  grid-area: ss-w;
}

.ss-l {
  grid-area: ss-l;
}

.ss-t {
  grid-area: ss-t;
}

.ss-p {
  grid-area: ss-p;
}

.ss-ow {
  grid-area: ss-ow;
  border-left: 1px solid var(--off-black-3)
}

.ss-ol {
  grid-area: ss-ol;
}

.ss-ot {
  grid-area: ss-ot;
}

.ss-op {
  grid-area: ss-op;
}

.standings-table .label {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.standings-table>.label:nth-child(2) {
  float: right;
}

.table-rankings {
  padding: .25em;
}

.table-rankings>.table-rankings-header,
.table-rankings>.table-rankings-teams>div {
  display: grid;
  grid-template-columns: var(--gtc-rankings);
}

.table-rankings>.table-rankings-header>div,
.table-rankings>.table-rankings-teams>div>div {
  padding: .25em 0;
}

.table-rankings>.table-rankings-header>div,
.table-rankings>.table-rankings-teams>div>div:not(:nth-child(3)) {
  text-align: center;
}

.history-container>.grid>.row>.column.six,
.table-rankings {
  font-weight: bold !important;
  font-size: 1rem;
}

.table-rankings td:nth-child(2) .label {
  min-width: 36px;
}

.standings-table .fav .icon.ssfav {
  margin-left: 1em;
}

.standings-selected {
  background-color: var(--score-selected) !important;
  color: var(--score-selected-c) !important;
}

.standings-headerr>tr>th {
  padding: .25em !important;
  text-align: center;
}

.ms-summary-full {
  height: 100% !important;
  overflow-y: auto;
}

.ms-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, auto) 1fr;
  background: #fff;
  background-color: white;
  padding: .25em;
  width: 100%;
  font-weight: bold;
  grid-template-areas:
    "ms-away ms-home"
    "ms-result ms-result"
    "ms-date ms-time"
    "ms-level ms-section"
    "ms-location ms-location"
    "ms-score ms-score";
}

.ms-summary>div {
  padding: .5em;
  text-align: center;
  border-radius: var(--br);
}

.ms-summary>div:nth-child(-n+2) {
  padding: .75em;
}

.ms-summary .icon {
  margin: 0 1em;
}

.ms-date {
  grid-area: ms-date;
}

.ms-time {
  grid-area: ms-time;
}

.ms-result {
  grid-area: ms-result;
}

.ms-level {
  grid-area: ms-level;
}

.ms-section {
  grid-area: ms-section;
}

.ms-location {
  grid-area: ms-location;
}

.ms-away {
  grid-area: ms-away;
  font-size: 1.2rem;
  text-align: center;
}

.ms-home {
  grid-area: ms-home;
  font-size: 1.2rem;
  text-align: center;
}

.ms-scores {
  grid-area: ms-scores;
  background-color: lightblue;
}

.ms-score {
  grid-area: ms-score;
  height: 100% !important;
}

.match-details>div {
  padding: .75em;
  text-align: center;
  margin: 1px;
  border-radius: 2px;
  border: 1px solid rgb(216, 216, 216);
}

.md-away {
  grid-area: md-away;
}

.md-toggle {
  grid-area: md-toggle
}

.md-home {
  grid-area: md-home;
  text-align: right;
}

.md-date {
  grid-area: md-date;
  text-align: center;
}

.md-time {
  grid-area: md-time;
  text-align: center;
}

.md-toggle>i.icon {
  font-size: 1em !important;
}

.level-grid {
  display: grid;
  background: #fff;
  width: 100%;
  gap: 1px;
  font-weight: bold;
}

.level-grid>div {
  border-radius: 2px;
  border: 1px solid rgb(216, 216, 216);
}

.section-div {
  display: grid;
  width: 100%;
  grid-template-rows: auto 1fr;
  padding: .5em;
  border-radius: var(--br);
  margin: .5em;
  background-color: grey;
}

.section-div>div:first-child {
  padding: .5em 1em;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: var(--section-grid-header);
}

.section-grid {
  display: grid;
  width: 100%;
  font-weight: bold;
  padding: .25em;
  row-gap: .5em;
}

.section-grid.tc-5 {
  grid-template-columns: repeat(6, 1fr);
}

.section-grid.tc-6 {
  grid-template-columns: repeat(7, 1fr);
}

.section-grid.tc-7 {
  grid-template-columns: 2fr repeat(7, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.section-grid.tc-8 {
  grid-template-columns: 2fr repeat(8, 1fr);
  grid-template-rows: repeat(15, 1fr);
}

.section-grid.tc-9 {
  grid-template-columns: 2fr repeat(9, 1fr);
  grid-template-rows: repeat(19, 1fr);
}

.section-grid.gd-8 {
  grid-template-rows: repeat(9, 1fr);
}

.section-grid.gd-9 {
  grid-template-rows: repeat(10, 1fr);
}

.section-grid.gd-10 {
  grid-template-rows: repeat(11, 1fr);
}

.section-grid>div {
  text-align: center;
  border: 1px solid rgb(226, 226, 226);
  font-size: .8rem;
  padding: .5em .2em;
  background-color: var(--sem-blue);
  color: white;
  border-radius: var(--br);
}

.section-grid>div.home {
  background-color: black;
  color: white;
}

.section-grid>div.away {
  background-color: white;
  color: black;
}

.section-grid>div.ns {
  text-decoration: underline;
}

.section-grid>div.nm {
  background-color: rgb(234, 176, 176);
  color: black;
}

.section-grid>div.home.co {
  background-color: var(--off-black-3);
  color: white;
  text-decoration: underline;
  border: 2px solid red;
}

.section-grid>div.away.co {
  background-color: var(--off-white-2);
  color: black;
  text-decoration: underline;
  border: 2px solid red;
}

.section-grid>div.section-date-header,
.section-grid>div.section-date {
  color: white;
}

.section-grid>div.open {
  background-color: rgb(205, 212, 0);
  color: black;
  border-radius: var(--br);
}

.match-teams {
  display: grid;
  width: 100%;
  height: 100%;
  padding: .25em !important;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto 1fr;
}

.match-teams.fh {
  height: 100%;
}

.match-teams-teams {
  display: grid;
  width: 100%;
  padding: 0;
  grid-template-columns: 1fr 1fr;
}

.match-teams>div {
  margin-bottom: .5em;
  width: 100%;
}

.match-teams-teams .dropdown,
.match-teams-teams .dropdown .item>span {
  font-size: .8rem;
}

.match-header {
  display: grid;
  background-color: whitesmoke;
  width: 100%;
  padding: 1em;
  border-bottom: 1px solid rgb(141, 141, 141);
  grid-template-rows: auto auto;
}

.match-header>.row {
  padding: .5em 0 !important;
  font-weight: bold;
}

.scoring-update {
  display: grid;
  /* grid-template-rows: repeat(auto 1fr); */
  grid-template-columns: repeat(1, 1fr);
  background-color: whitesmoke;
  width: 100%;
  padding: 1em .25em;
  border-top: 1px solid rgb(141, 141, 141);
  border-bottom: 1px solid rgb(141, 141, 141);
  grid-template-areas:
    "match-score-table"
    "team-scoring";
}

.match-team-names {
  text-align: center !important;
  display: grid;
  padding-bottom: 1em;
  grid-template-columns: 1fr 1fr;
  font-weight: bold;
}

.match-away-home {
  padding: 0 0 .5em 0;
  text-align: center;
  font-weight: bold;
}

.match-update-date {
  padding: 0 0 .5em 0;
  text-align: center;
}

.team-scores {
  text-align: center !important;
  display: grid;
  padding: 0 .25em;
  grid-template-columns: 1fr 1fr;
  width: 100% !important;
  gap: .5em;
}

.team-scoring {
  text-align: center !important;
  display: grid;
  padding: 0 .25em;
  grid-template-columns: 3fr 1fr 3fr;
  width: 100% !important;
  gap: .5em;
}

.team-scores .label {
  padding: 1em
}

.team-score {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  font-weight: bold;
  gap: .5em;
  align-items: center;
}

.match-score-header {
  background-color: red;
}

.match-updates {
  border-top: 1px solid grey;
  text-align: center;
  margin-top: 1em;
  padding-top: 1em;
}

.match-calendar {
  display: grid;
  grid-template-columns: 4fr 1fr;
  padding: .5em 0;
}

.match-calendar>div:nth-child(2) {
  text-align: right;
}

.team-section {
  display: flex;
  justify-content: space-around;
}

.teams-list {
  padding: .5em;
  height: 100%;
  overflow-y: auto;
  background-color: var(--off-black-1);
  color: white;
}

.teams-list>div {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 1em;
  padding: .75em .5em;
  justify-content: space-evenly;
}

.teams-list>div>div:not(:first-child) {
  text-align: center;
}

.sportLevels-school {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(8, 1fr);
  padding: 2em;
}

.schools-list {
  padding: .25em
}



.schools-list>div {
  padding: .5em;
  height: 100%;
  overflow-y: auto;
  border-bottom: 1px solid var(--off-black-5);
}

.schools-list .label {
  float: right;
  margin-left: 1em !important;
}

.schools-list>div>div:nth-child(n+2) {
  padding: .25em;
}

.ss-pt,
.ss-npt .ss-npte {
  padding-left: .25em !important;
  padding-right: .25em !important;
}

.ss-pt,
.ss-pt td:nth-child(1),
.ss-npt,
.ss-npt td:nth-child(1),
.ss-npte,
.ss-npte td:nth-child(1) {
  margin-right: 1em !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.ss-pt,
.ss-pt td:nth-child(1) {
  border-left: 3px solid var(--sem-green-light) !important;
}

.ss-npt,
.ss-npt td:nth-child(1) {
  border-left: 3px solid var(--sem-red-light) !important;
}

.ss-npte,
.ss-npte td:nth-child(1) {
  border-left: 3px solid var(--sem-red) !important;
}

.ss-npta,
.ss-npta td:nth-child(1) {
  border-left: 3px solid var(--sem-green) !important;
}

.ss-pt .label:nth-child(2),
.ss-npt .label:nth-child(2) {
  margin-left: 1em !important;
}

.ss-mm .sssmr {
  color: var(--sem-yellow)
}

.legend,
.legend-schedule>div {
  margin: .5em .25em !important;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 0 !important;
}

.legend-schedule>div {
  flex-direction: row;
}

.legend-schedule .label {
  font-size: .2rem !important;
}

.legend>div {
  padding: 0 .5em;
  font-size: .8rem;
}

.legend>div:nth-child(1) {
  border-left: 2px solid var(--sem-green-light) !important;
}

.legend>div:nth-child(2) {
  border-left: 2px solid var(--sem-red-light) !important;
}

.legend>div:nth-child(3) {
  border-left: 2px solid grey !important;
}

.standings-legend-pop {
  border: none !important;
}

.standings-legend-icn {
  margin: 1em 1em 3em 1em !important;
  float: right !important;
}

.standings-legend,
.sections-legend,
.schedule-legend {
  display: flex;
  flex-direction: column;
  gap: .25em;
  background-color: var(--off-black-2) !important;
}

.standings-legend>.label,
.sections-legend>.label,
.schedule-legend>.label {
  background-color: var(--off-black-3);
  color: white;
  font-weight: normal;
  margin: 0 !important;
}

.standings-legend>.label:nth-child(1) {
  border-left: 2px solid var(--sem-green);
}

.standings-legend>.label:nth-child(2) {
  border-left: 2px solid var(--sem-green-light);
}

.standings-legend>.label:nth-child(3) {
  border-left: 2px solid var(--sem-red-light);
}

.standings-legend>.label:nth-child(4) {
  border-left: 2px solid var(--sem-red);
}

.standings-legend>.label:nth-child(n+5) {
  border-left: 2px solid grey;
}

.schedule-legend>.label {
  color: white;
}

.schedule-legend>.label:nth-child(1) {
  border-left: 2px solid var(--sem-green);
}

.schedule-legend>.label:nth-child(2) {
  border-right: 2px solid var(--sem-green);
}

.schedule-legend>.label:nth-child(3) {
  border-left: 2px solid var(--sem-yellow);
  border-right: 2px solid var(--sem-yellow);
}

.schedule-legend>.label:nth-child(4) {
  border-bottom: 1px solid var(--sem-blue);
}

.match-rosters>.header {
  text-align: center;
  padding: 1em !important;
}

.card-profile.athlete .header {
  display: grid !important;
  grid-template-columns: 1fr 4fr 2fr 2fr;
  width: 100%;
}

.card-profile.athlete .header>div.label {
  padding: .5em;
  text-align: center !important;
}


.card-profile.athlete .description {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.card-profile.athlete .description>div {
  text-align: center;
}

.schools-district {
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  width: 100%;
  align-items: center;
  font-size: .8rem !important;
}

.schools-district.level {
  grid-template-columns: 1fr 5fr 5fr 1fr 1fr;
}

.schools-district>.label>span {
  margin: .25em;
}

.schools-district>div:nth-child(4) {
  text-align: right;
}

.schools-district .icon,
.schools-district span {
  margin: .25em !important;
}

.schools-district span:nth-child(3) {
  color: var(--sem-blue) !important;
}

.schools-district span:nth-child(4) {
  color: var(--sem-red) !important;
}

.schools-district span:nth-child(5) {
  color: var(--sem-blue) !important;
}

.set-buttons {
  display: grid;
  grid-template-columns: 1fr;
  padding: .5em 0;
  text-align: center;
}

.match-buttons {
  display: grid;
  grid-template-columns: repeat(3, 3fr) 1fr;
  padding: .5em 0;
  text-align: center;
}

.match-buttons.clear {
  grid-template-columns: 1fr;
}

.flx2 {
  display: flex;
}

.schedule-buttons {
  padding: 0;
  text-align: center;
}

.schedule-buttons.list {
  text-align: left;
  width: 100%;
}

.schedule-buttons .menu.transition {
  height: 300px;
  overflow-y: auto;
}

.schedule-buttons .ui.dropdown .menu>.item {
  padding: .2em .5em !important;
}

.schedule-buttons.list>.label {
  width: 100%;
  margin-bottom: .25em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scorepad-container {
  display: grid;
  width: 100%;
  text-align: center;
  gap: 6px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background-color: var(--score-blue-back);
  padding: .5em;
  border-radius: 8px;
}

.scorepad-container>div {
  border-radius: 8px !important;
}

.scorepad-container.done {
  grid-template-rows: repeat(5, 1fr);

}

.scorepad-header {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
  text-align: center;
  padding: .5em;
}

.scorepad-header>div {
  line-height: 36px !important;
  text-align: center;
  font-size: 1.1rem;
}

.scorepad-header .label.selected {
  border: 1px solid black !important
}

.scorepad-container>div {
  display: block;
  border-radius: var(--br);
  background-color: var(--score-grey);
}

.scorepad-container>div[color='blue'] {
  background-color: var(--score-blue);
}

.scorepad-container>div[color='grey'] {
  background-color: var(--score-grey);
}

.scorepad-container>div[color='dark-grey'] {
  background-color: var(--score-dark-grey);
}

.scorepad-container>div[color='green'] {
  background-color: var(--score-green);
}

.scorepad-container>div[color='red'] {
  background-color: var(--score-red);
}

.scorepad-container>div[color='black'] {
  background-color: var(--sem-black) !important;
}

.scorepad-container>div {
  line-height: 60px !important;
  text-align: center;
  color: white !important;
  max-height: 60px;
}

.scorepad-container .keypad-score {
  font-size: 2rem;
}

.scorepad-container>div>i.icon {
  font-size: 2rem;
}

.grid-overflow,
.playoff-grid {
  width: auto !important;
  margin: auto;
  padding: 1.25em
}

.grid-overflow-c {
  height: 100%;
  overflow-y: auto;
}

.playoff-grid .row>.column {
  min-width: 180px;
  padding: 0 !important
}

.playoff-grid .row>.column>div {
  padding: .25em !important
}

.playoff-grid .segment {
  display: flex;
  align-items: center;
  padding: 0 .25em;
  margin: .25em 0;
}

.playoff-grid .match-grid {
  width: 100%;
  min-width: 140px;
  padding: .25em;
}

.level-date-matches .match-grid {
  padding: .5em;
  margin-bottom: .5em;
  border-radius: 8px;
  border: 1px solid var(--off-black-3);
}

.level-date-matches .match-grid .pool-match>.teams>div,
.playoff-grid .match-grid .pool-match>.teams>div {
  width: 100%;
  padding: .25em 0
}

.level-date-matches .match-grid .pool-match>.teams>div>.label,
.playoff-grid .match-grid .pool-match>.teams>div>.label {
  margin-right: 1em;
  text-align: center;
}

.playoff-grid-header {
  margin-bottom: -2.5em !important
}

.playoff-grid-header>.column {
  padding: .25em 0 !important;
  text-align: center;
  justify-items: center;
}

.playoff-grid-header>.column>.segment {
  padding: .25em;
  font-weight: bold;
}

.match-menu-header {
  text-align: center;
}

.match-menu-header>div:nth-child(1) {
  font-size: 1.1rem;
}

.match-menu-header>div:nth-child(n+2) {
  font-weight: normal;
  font-size: 0.8rem;
  margin: .5em;
  color: rgb(195, 195, 195);
}

.no-data>.rf,
.match-menu-header>.rf {
  font-size: 1.1rem !important;
  background-color: var(--sem-blue);
  padding: .25em;
  color: white;
  padding: .5em;
  width: 100%;
  text-align: center;
}

.split-header {
  display: grid;
  width: 100%;
  grid-template-columns: 5fr 1fr 5fr;
}

.split-header>div:nth-child(1) {
  text-align: left;
}

.split-header>div:nth-child(2) {
  text-align: center;
}

.split-header>div:nth-child(3) {
  text-align: right;
}

.allStar-container>.segment:nth-child(odd) {
  font-weight: bold;
  background-color: var(--header-light);
}

.pools-container {
  height: 100%;
  display: grid !important;
  padding: .5em .5em !important;
  grid-template-rows: 1fr 4fr;
}

.pools-container>div:nth-child(2) {
  height: 100%;
  overflow-y: scroll;
}

.level-container {
  background-color: var(--off-black-5) !important;
  color: white;
}

.sections-container {
  padding: .25em
}

.section-container {
  margin: .25em
}

.section-container>.segment {
  padding: .5em
}

.section-container>.segment:first-child {
  background-color: var(--off-white-2);
  display: flex;
  justify-content: space-between;
}

.section-container>.segment.sel {
  background-color: var(--off-white-5);
}

.cal-status {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.alignment-container {
  display: grid !important;
  height: 100%;
  grid-template-columns: 1fr !important;
}

.alignment-container.existing {
  display: grid !important;
  height: 100%;
  grid-template-columns: 1fr !important;
}

.alignment-container>div {
  height: 100%;
  overflow-y: auto;
  padding: .5em
}

.alignment-container>div:nth-child(2)>div {
  height: 100%;
  overflow-y: auto;
}

.match-score-rt {
  grid-area: match-score-rt;
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
  grid-template-rows: 1fr 1fr !important;
  grid-template-areas: "rt-h-s-c rt-h-ss-c rt-sn-c rt-a-ss-c rt-a-s-c"
    "rt-h-s rt-h-ss rt-sn rt-a-ss rt-a-s";
  gap: .2em;
  width: 100%;
  padding: .5em;
  font-size: .9rem;
}

.match-score-rt>div {
  border: 1px solid grey;
  padding: .25em 1em;
  background-color: var(--off-black-3);
  border-radius: 4px;
}

.match-score-rt>div.winning {
  background-color: var(--sem-green);
  font-weight: bold;
}

.match-score-rt>div.losing {
  background-color: var(--sem-red);
  font-weight: bold;
}

.lbl-rnk {
  width: auto !important;
  margin-left: .5em !important;
  margin-right: .5em !important;
  background-color: var(--off-black-3) !important;
  color: var(--off-white-3) !important;
}

.level-date-matches .lbl-rnk {
  width: auto !important;
  margin-left: 0em !important;
  margin-right: .5em !important;
}

.playoffs-pending>div:nth-child(1),
.playoffs-pending>div:nth-child(3) {
  padding: .5em;
  font-weight: bold;
  background-color: var(--off-white-1);
  text-align: center;
}

.matches-pending>div {
  border-bottom: 1px solid var(--off-white-4);
  padding: .25em;
}

.matches-pending>div>div {
  padding: .25em;
}

.matches-pending-teams,
.matches-pending-prior {
  display: grid;
  grid-template-columns: 5fr 1fr 5fr;
  text-align: center;
}

.matches-pending-teams>div:first-child,
.matches-pending-prior>div:first-child {
  text-align: left !important;
}

.matches-pending-teams>div:last-child,
.matches-pending-prior>div:last-child {
  text-align: right !important;
}

.matches-pending-teams>div>.label {
  margin: 0 .5em !important;
}

.matches-pending-prior {
  font-size: .8rem;
}

.matches-pending-match {
  background-color: var(--off-white-1);
}

.matches-pending-match.selected {
  background-color: white;
}

.matches-pending-teams .label,
.matches-pending-match .label {
  margin: .25em !important;
}

.fav-items * {
  color: var(--off-white-4) !important;
}

.app-dark-mode .schedule-dates,
.app-dark-mode .schedule-levels {
  color: inherit !important;
  border-color: var(--off-black-3) !important;
}


.app-dark-mode .schedule-level {
  background: var(--off-black-1) !important;
}

.app-dark-mode .standings-container,
.app-dark-mode .standings-table,
.app-dark-mode .schedule-dates {
  background-color: var(--me-background-bgc-dm) !important;
  color: var(--off-white-1) !important;
}

.app-dark-mode .schedule-section-teams,
.app-dark-mode .schedule-match,
.app-dark-mode .standings-header,
.app-dark-mode .standings-table,
.app-dark-mode .sdw,
.app-dark-mode .schedule-section,
.app-dark-mode .standings-container,
.app-dark-mode .sports-active,
.app-dark-mode .schedule-match .app-dark-mode .team-overview div {
  background-color: var(--off-black-1) !important;
  background: var(--off-black-1) !important;
  color: white !important;
}

.app-dark-mode .sports-active {
  border-radius: var(--br-8) !important;
}

.app-dark-mode .sports-active .item {
  color: white !important;
}

.app-dark-mode .sports-active .item:not(:last-child) {
  border-bottom: 1px solid var(--off-black-3) !important;
}

.app-dark-mode .segments,
.app-dark-mode .segment {
  background-color: black !important;
  background: black !important;
  color: white;
}

.app-dark-mode>.content,
.app-dark-mode .match-grid,
.app-dark-mode .pool-match,
.app-dark-mode .pool-match>.teams,
.app-dark-mode .pool-match .app-dark-mode .schedule-level,
.app-dark-mode .table-rankings,
.app-dark-mode .history-container,
.app-dark-mode .standings-table,
.app-dark-mode .standings-table .standings-headerr {
  background-color: var(--off-black-1);
  background: var(--off-black-1);
  color: white;
}


.app-dark-mode .sdw {
  background-color: var(--off-black-1) !important;
  border: 1px solid var(--off-black-1) !important;
}

.schedule-match.playoff,
.schedule-match.playoff.bye {
  border: 1px solid var(--off-black-2) !important;
}

.match-grid.bye>.pool-match,
.schedule-match.playoff.bye {
  background-color: var(--off-black-1) !important;
  opacity: .7 !important;
}


.match-grid.pending>.pool-match,
.schedule-match.playoff.pending {
  background-color: var(--off-black-1) !important;
  opacity: .7 !important;
}

.schedule-match.rt {
  border: 1px solid green !important;
  padding: .5em;
  border-radius: 8px;
}

.app-dark-mode .standings-header {
  background-color: var(--off-black-2) !important;
}

.app-dark-mode .sdw>div {
  background-color: var(--off-black-1) !important;
}

.app-dark-mode .sdw>div:nth-child(1) {
  background-color: var(--off-black-3) !important;
  border: 1px solid var(--off-black-3) !important;
  color: var(--off-white-1) !important
}

.app-dark-mode .sdw.playoffs>div:nth-child(1) {
  background-color: var(--off-black-4) !important;

}

.app-dark-mode .label.yellow {
  color: black !important;
}

.app-dark-mode .divider:not(.text.divider) {
  background-color: var(--off-black-1) !important;
}

.match-display-score {
  grid-area: match-display-score;
  text-align: center;
}

.tmc-opps {
  padding: .5em .5em 5em .5em !important;
  color: var(--off-white-2);
  background-color: var(--off-black-1) !important;
}

.tmc-opp-teams {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: .25em;
  align-items: center;
  font-size: .8rem;
}

.tmc-opp {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: .25em;
  align-items: center;
  font-size: .8rem;
  font-weight: bold;
}

.tmc-opp>div:nth-child(2) {
  text-align: center !important;
}

.tmc-opp-teams>div:last-child,
.tmc-opp>div:last-child {
  text-align: right !important;
}

.tmc-opp .tmcd.w {
  border: 1px solid var(--sem-green);
}

.tmc-opp .tmcd.l {
  border: 1px solid var(--sem-red);
}

.match-display.nr {
  background-color: var(--score-nr);
}

.match-display.ae {
  border: 1px solid rgb(223, 223, 223);
}

.schedule-match:not(.scrs)>.match-display {
  font-size: .8rem;
}

.app-dark-mode .schedule-match.teams .match-display,
.app-dark-mode .schedule-match.section .match-display,
.app-dark-mode .schedule-match.playoff .match-display {
  border: 1px solid var(--off-black-3);
}

.app-dark-mode .schedule-match.teams.ae:not(.rt) .match-display,
.app-dark-mode .schedule-match.section.ae:not(.rt) .match-display,
.app-dark-mode .schedule-match.playoff.ae:not(.rt) .match-display {
  background-color: var(--score-allow-edit);
}

.app-dark-mode .pool-match.mal-admin .match-dates,
.app-dark-mode .schedule-match.teams.ae.mal-admin:not(.rt) .match-display,
.app-dark-mode .schedule-match.section.ae.mal-admin:not(.rt) .match-display,
.app-dark-mode .schedule-match.playoff.ae.mal-admin:not(.rt) .match-display {
  background-color: var(--score-allow-edit-admin);
}

.schedule-match.wa:not(.rt):not(.teams) .match-playoff>.match-display,
.schedule-match.wa:not(.rt):not(.teams)>.match-display {
  border: 1px solid var(--off-black-4);
  border-left: 2px solid var(--sem-green);
}

.schedule-match.wh:not(.rt):not(.teams) .match-playoff>.match-display,
.schedule-match.wh:not(.rt):not(.teams)>.match-display {
  border: 1px solid var(--off-black-4);
  border-right: 2px solid var(--sem-green);
}

.schedule-match.wh.mdr:not(.rt):not(.teams) .match-playoff>.match-display,
.schedule-match.wh.mdr:not(.rt):not(.teams)>.match-display {
  border: 1px solid var(--off-black-4);
  border-right: 2px solid var(--sem-blue);
}

.matches-latest-team .schedule-match.win.lmo>.match-display,
.schedule-match.win.teams>.match-display {
  border: 1px solid var(--sem-green) !important;
}

.matches-latest-team .schedule-match.fmrb .win.lmo>.match-display,
.schedule-match.fmrb.win.teams>.match-display {
  background-color: var(--sem-green) !important;
  border: 0 !important;
}

.matches-latest-team .schedule-match.loss.lmo>.match-display,
.schedule-match.loss.teams>.match-display {
  border: 1px solid var(--sem-red) !important;
}

.matches-latest-team .schedule-match.fmrb.loss.lmo>.match-display,
.schedule-match.fmrb.loss.teams>.match-display {
  background-color: var(--sem-red) !important;
  border: 0 !important;
}

.matches-latest-team .schedule-match.tie.lmo>.match-display,
.schedule-match.tie.teams>.match-display {
  border: 1px solid var(--sem-blue) !important;
}

.matches-latest-team .schedule-match.fmrb.tie.lmo>.match-display,
.schedule-match.fmrb.tie.teams>.match-display {
  background-color: var(--sem-blue) !important;
  border: 0 !important;
}

.app-dark-mode .schedule-match.future .match-display {
  color: var(--off-white-4) !important;
}

.app-dark-mode .schedule-match.no-res:not(.rtm-current) .match-display {
  border-left-width: 2px !important;
  border-right-width: 2px !important;
  border-left-color: var(--sem-yellow) !important;
  border-right-color: var(--sem-yellow) !important;
}

.app-dark-mode .ms-result.ae {
  background-color: var(--off-black-5) !important;
}

.app-dark-mode .ms-result.ae.mal-admin {
  background-color: var(--off-black-3) !important;
}

.ms-result.mdr {
  color: var(--sem-blue) !important;
}

.app-dark-mode .match-buttons .button:not(.btn-sel),
.app-dark-mode .ms-button-group .button:not(.btn-sel) {
  background-color: var(--off-black-3) !important;
  color: white !important;
}

.app-dark-mode .history-container .grid .label {
  background-color: var(--off-black-3) !important;
}

.app-dark-mode .history-container .grid .row {
  border-bottom: 1px solid var(--off-black-3) !important;
}

.app-dark-mode .team-overview .label {
  color: var(--off-white-1) !important;
}

.app-dark-mode .standings-container {
  border: 1px solid var(--off-black-3) !important;
}

.app-dark-mode .upcoming-matches {
  background-color: var(--off-black-2) !important;
  padding: .5em;
  text-align: center;
}

.app-dark-mode .schedule-section .divider .header {
  color: var(--off-white-1) !important
}

.app-dark-mode .schedule-match.future.pending .match-away {
  font-size: .8rem;
}

.app-dark-mode .match-grid.sm,
.app-dark-mode .match-grid.sm>.pool-match {
  border-color: var(--match-same) !important;
}

.app-dark-mode .match-away.upset>span,
.app-dark-mode .match-home.upset>span {
  background-color: var(--off-black-2) !important;
  color: var(--upset);
}

.app-dark-mode .checker-lineup {
  background-color: var(--off-black-3);
}

.matches-latest-team .match-display {
  border: 0 !important;
}

.chg {
  background-color: pink;
}

.scoring-half {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  text-align: center;
  gap: .5em;
  font-weight: bold;
  align-items: center !important;
}

.scoring-half>div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .5em;
}

.scoring-half>div:not(:first-child) {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  align-items: center !important;
}

.score-updown {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  border: 1px solid rgb(161, 160, 159);
  border-radius: 8px;
  align-items: center !important;
  padding: .5em;
}

.team-records {
  display: flex;
  flex-direction: column;
  padding: .5em;
  color: white;
  background-color: black;
  height: 100%;
}

.team-records>.team-record {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: .15em;
  text-align: center;
  background-color: var(--off-black-3);
  border: 1px solid var(--off-black-3);
  margin-bottom: .5em;
}

.team-records>.team-record>.team-record-name {
  display: flex;
  padding: .5em;
  justify-content: space-between;
  border-radius: 8px;
}

.team-records>.team-record.selected>.team-record-name {
  background-color: var(--sem-blue);
  font-weight: bold;
}

.team-records .team-record-sec>div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .5em;
  margin: .5em;
  text-align: center;
  border: 1px solid var(--off-black-3);
  background-color: var(--off-black-2);
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.team-records .team-record-results,
.team-records .team-record-nonResults,
.team-records .team-record-opps {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: var(--off-white-4);
}

.team-records .team-record-opps>div {
  display: flex;
  flex-direction: row;
  text-align: left;
  color: var(--off-white-4);
  justify-content: space-between;
}

.team-records .team-record-opps {
  text-align: left;
}

.team-records .team-record-opps .team-sel {
  color: white;
}

.team-records div {
  padding: .15em;
}

.tournament-list {
  padding: 1em;
}

.tournament-list .card * {
  color: white !important;
}

.item-container.tournaments .content.extra {
  background-color: var(--off-black-2) !important;
}

.match-options {
  display: flex;
  justify-content: space-between;
  padding: .5em !important;
}

.golf-match-chart {
  text-align: center;
  font-size: .8rem;
}

.golf-status {
  background-color: var(--off-black-2) !important;
}

.srl-pp {
  border: 1px solid var(--sem-blue) !important;
}

.srl-pp-sel {
  background-color: var(--sem-blue) !important;
}

.golf-match-chart>div {
  width: 100%;
  padding: .5em;
  background-color: rgb(162, 162, 162);
}

.recharts-tooltip-wrapper {
  color: black;
  background-color: blue !important;
}

.sssmr {
  padding: .5em .75em !important;
  float: right;
  background-color: var(--off-black-3) !important;
}

.sssmr>.icon {
  padding: 0 !important;
}

.schedule-grid {
  margin: 0 !important
}

.schedule-grid>.row {
  border-bottom: 1px solid rgb(204, 204, 204);
  padding: .5em 0 !important;
}

.schedule-grid>.row>.column {
  padding: .5em !important
}

.dater {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  text-align: left;
}

.dater>div:first-child {
  font-weight: bold;
  font-size: 1.2em;
}

.dater>div:last-child {
  font-weight: bold;
}

.match-team-container {
  display: grid;
  height: 100%;
  overflow-y: auto;
}

.match-team-schedules>.item>.header {
  display: flex;
  justify-content: space-between;
}

.media-media {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-area: media-media;
  padding: .5em;
  background-color: var(--off-black-3);
}

/* .createSeeds .drag-number .label {
  background-color: black !important;
  border-width: 1px !important;
  color: white !important;
} */