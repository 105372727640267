/* GENERAL */
.cntr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hwcc {
  height: 100%;
  width: 100%;
  text-align: center;
  align-content: center;
  padding: 1em;
  white-space: pre-line
}

.fm-rg,
.fm-ri {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
}

.fm-rg,
ul {
  position: relative;
  width: 300px;
}

.fm-ri {
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 15px 18px;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  cursor: grab;
}

.fm-ri svg {
  width: 18px;
  height: 18px;
  cursor: grab;
}


.disn {
  display: none;
}

.txt-line-through {
  text-decoration: line-through;
  color: var(--off-white-4) !important;
}

.full-page-alert * {
  background-color: var(--off-black-2) !important;
  color: white !important;
}

.results-wrap {
  width: 100% !important;
  overflow-y: auto;
}

.labels-sorted {
  display: flex !important;
  justify-content: start !important;
  align-items: center;
}

.labels-sorted>div:nth-child(2)>div:nth-child(2)>div {
  font-size: .8rem;
  padding: .25em 0 0 .5em;
}

.dep {
  background-color: red;
  position: relative !important;
}

.dep>.item {
  background-color: var(--off-black-4);
}

.wprw {
  height: 100vh;
  width: 100vw;
  background-color: whitesmoke;
}

.wpr {
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.wpr>div {
  padding: 1em;
}

.wpr .content {
  height: 100%;
  background-color: grey;
}

.vert-container {
  display: grid;
  width: 100% !important;
  overflow-y: hidden !important;
  grid-template-columns: 100%;
  height: 100%;
}

/* .modal>.vert-container {
  padding: .5em 0 !important;
} */

.vert-container .vert-container {
  max-height: 100%;
}

.vert-container.setz {
  max-height: 100% !important;
}

.vert-container.mini {
  height: auto !important;
  margin-bottom: 1em;
  border: 1px solid var(--off-black-2);
  border-radius: 8px;
}

.modal .vert-container {
  border-radius: 0 !important;
}

.vert-container .vert-container>.header {
  border-top: 1px solid var(--off-black-2);
}

.vert-container>.content.mid:has(.content.mid),
.wpr>.content:has(.content) {
  height: 100%;
  overflow-y: hidden !important;
  /* Apply to the last 'content' */
}

.vert-container>.content.mid:not(:has(.content.mid)),
.wpr>.content:not(:has(.content)) {
  height: 100%;
  overflow-y: auto !important;
  /* Apply to all 'content' except the last one */
}

.vert-container>.content.mid:has(canvas) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.vert-container>.content.mid:has(canvas)>div {
  padding: 1em;
  background-color: white;
}

.ovh {
  height: 100%;
  overflow-y: hidden;
}

.ova {
  height: 100% !important;
  overflow-y: auto !important;
}

.h100 {
  height: 100% !important;
}

.h0 {
  height: auto !important;
}

.hlp-icn {
  position: absolute !important;
  bottom: 2em !important;
  right: 1.5em !important;
  z-index: 999;
}

.tar {
  text-align: right;
}

.mar0 {
  margin: 0 !important
}

.mar10 {
  margin: 1em !important;
}

.mar10 {
  margin: 1em !important;
}

.mar15 {
  margin: 1.5em !important;
}

.mar2 {
  margin: 2em !important;
}

.mar5 {
  margin: .5em !important;
}

.padd0 {
  padding: 0 !important
}

.padd10 {
  padding: 1em !important;
}

.padd10 {
  padding: 1em !important;
}

.padd15 {
  padding: 1.5em !important;
}

.padd15 {
  padding: 1.5em !important;
}

.padd2 {
  padding: 2em !important;
}

.padd5 {
  padding: .5em !important;
}

.no100 {
  height: auto !important;
}

.upc {
  text-transform: uppercase;
}

.margin-auto {
  margin: auto
}

.dddddddddd {
  position: relative;
}

.pppppppppp {
  position: relative;
}

.ui.form .field {
  margin: .5em 0
}

.form>textarea {
  margin-bottom: 1em !important;
}

p {
  line-height: 1.5 !important;
  margin-bottom: .5em !important;
}

.full-web-page {
  width: 100vw !important;
  height: 100vh;
  background-color: grey;
}

.full-web-page .content .content {
  margin: auto;
}

.nob {
  text-align: center;
}

.bld {
  font-weight: bold;
}

.btn-icns {
  display: grid !important;
  grid-template-columns: auto 1fr auto;
  margin-bottom: 1em !important;
}

.btns-sign-in {
  display: flex !important;
  flex-direction: column !important;
}

.btns-sign-in>.button {
  margin-bottom: 1em !important;
  min-width: 16em;
}

/* swvs */
.swvs {
  overflow: hidden;
  width: 100%;
  padding-top: 0px;
  height: 100%;
}

.swvs>div {
  height: 100%;
}

.seg-nbm {
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  grid-template-rows: auto 1fr !important;
}

.segment.placeholder {
  height: 100% !important;
}

.btn-save-container {
  padding: 0 !important;
  margin: 0 !important;
}

.signin .btn-save-container .button {
  float: inherit !important;
  text-align: center;
  max-width: none !important;
}

/* ONE OFFS */

.fw-b {
  font-weight: bold;
}

#parent {
  width: 100%;
  height: 200px;
  /* Set the desired height for the parent div */
  overflow: auto;
}

.menu {
  white-space: nowrap;
}

.stack-error {
  font-size: .8rem;
  height: 100%;
  overflow-y: auto;
  padding: .5em;
}

.dd-container {
  display: grid;
  grid-template-columns: auto 1fr;
}

/* pusher */
.pusher>.settings-app-container {
  position: absolute;
}

.pusher-container {
  height: 100%;
  overflow: inherit !important;
}

.pusher-container .item-popup {
  bottom: 2em;
}

/* sidebar */
.app-sidebar-menu .icon.large {
  font-size: 1.2em;
}

.app-sidebar-menu>.content .menu {
  margin-top: 0;
  margin-bottom: 0;
}

.sidebar-item {
  overflow: hidden !important;
}

.action-sidebar>.item {
  padding: 1.5em !important;
  font-weight: bold !important;
  font-size: .9rem;
}

.dis-none {
  display: none !important;
}

.hodd {
  padding: 0 !important;
  float: right;
}

.hdd.tv,
.hdd.tv .menu>div>span {
  font-size: .7rem;
}

.hdd.tv .menu>div {
  padding: .3em !important
}

.ip-url {
  word-wrap: break-word;
  word-break: break-all;
}

.is-html img {
  max-width: 100px !important;
  max-height: 100px !important;
}

.is-html ul {
  padding-left: 1.5em !important;
}

.df-r1-c2 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c4 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c5 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);
  gap: .25em;
  align-items: center;
  justify-items: center;
}

.df-r1-c4:nth-child(4) {
  justify-self: right;
}

.df-r3-c2,
.df-r2-c2 {
  padding: .5em;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: .25em;
}

.df-r3-c2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.df-r2-c2 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.df-r3-c2 .label,
.df-r2-c2 .label {
  width: 100%;
  margin: 0;
}

/* APP USER LIST */
.app-user-list .menu-alphabet {
  width: 100%;
  padding: .25em !important;
}

.sidebar .app-user-list .card {
  width: 94%;
}

/* MODALS */

.modal-full {
  z-index: 10001 !important;
}

.modal-wide {
  height: 90% !important;
  width: 90% !important;
}

.modal-wide .sw-wrapper.dm {
  background-color: black;
}

.modal-wide .sw-wrapper.dm>.content {
  width: 60%;
  margin: 0 auto;
  border: 1px solid var(--topper);
}

/* HEADER */
.disp-override {
  display: flex;
  padding: .35em !important;
  background-color: var(--off-black-0);
  border-radius: 8px;
}

.prp-ovr .sec-header,
.prp-ovr .sec-content>div {
  position: relative;
}

.prp-ovr .disp-override {
  position: absolute;
  top: 0;
  right: 0;
}

/* HEADER */
.header-flex-right {
  display: flex;
  gap: 1em;
}

.header-flex {
  display: flex;
  justify-content: space-between;
}

.topper-flex {
  text-align: center;
}

.topper-flex-2 {
  display: flex;
  justify-content: space-between;
}

.header-flex .selection {
  font-size: .8rem;
  margin-right: .5em;
}

.header-flex .selection .item>span {
  font-size: .8rem;
}

.header-flex>form,
.header-flex>.two>div {
  width: 50%;
}

.header-flex>div:nth-child(1) {
  justify-content: flex-start;
}

.header-flex>div:nth-child(2) {
  justify-content: flex-end;
}

.header-flex .foot {
  padding: 1em 1em;
}

/* ALPHA FLOAT */

.alpha-float {
  font-size: xx-small !important;
  z-index: 100;
  display: flex !important;
  touch-action: manipulation;
  height: 100%;
  overscroll-behavior: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.alpha-float.menu-alphabet {
  justify-content: center;
}

.alpha-float.dead {
  opacity: .1;
}

.alpha-float.dead.hidden {
  opacity: 0;
}

.alpha-float.dead.hidden.vo-none {
  opacity: 0;
}

.alpha-float.dead.hidden.vo-lightest {
  opacity: .1;
}

.alpha-float.dead.hidden.vo-light {
  opacity: .3;
}

.alpha-float.dead.hidden.vo-medium {
  opacity: .5;
}

.alpha-float.dead.hidden.vo-dark {
  opacity: .7;
}

.alpha-float.dead.hidden.vo-darkest {
  opacity: .9;
}

.alpha-float>.item {
  padding: .5em 1em !important;
  text-align: center;
}

.abs-side,
.abs-side-sub {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.show-letter {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: .5em;
  text-align: center;
  font-weight: bold;
  font-size: 1.3rem;
}

.circle-container {
  position: fixed;
  top: 50%;
  right: 6em;
  transform: translateY(-50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.scrolling-letter {
  color: #ffffff;
  font-size: 48px !important;
}

.scrolling-count {
  position: absolute;
  right: 2em;
  bottom: 2em;
  font-size: 12px !important;
  opacity: .7;
}

/* CREATE CONTENT */
.create-content>textarea {
  height: 100%;
  border: .1px solid lightgrey
}

.create-content.json {
  padding: .5em;
}

.create-content.json {
  padding: 1em;
}

/* MENU SLIDER */

.menu-slider {
  margin: .2em 0 .2em 0 !important
}

.menu-slider>.item {
  font-weight: bold !important;
  padding: .2em !important;
}

.menu-slider>.item>.icon {
  margin: 0 !important
}

.menu-slider.bs>.item>.label {
  margin-right: 1em !important
}

/* SLIDERS */

.vertical-slider {
  height: 100%;
  width: 100%;
  background-color: rgb(75, 75, 75);
  border-radius: 4px;
}

.horizontal-slider {
  width: 100%;
  height: var(--horz-height);
  background-color: rgb(75, 75, 75);
  color: white;
  vertical-align: middle;
  border-radius: 4px;
}

.vertical-slider.dis,
.horizontal-slider.dis {
  background-color: lightgrey;
}

.vertical-slider>.example-mark .horizontal-slider>.example-mark {
  margin: 0px calc(19px);
  bottom: calc(50% - 6px);
}

.horizontal-slider .example-thumb {
  width: 28px;
  height: var(--horz-height);
  line-height: var(--horz-height);
}

.vertical-slider>.example-mark {
  background-color: grey;
  width: 100%;
  height: 22px;
  border: 1px solid grey;
  color: white;
  left: 0;
}

.example-thumb {
  font-size: 0.8rem;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.example-mark::before {
  background-color: red;
}

.vertical-slider .example-thumb {
  width: 100%;
  height: 22px;
  line-height: 22px;
}

/* IMAGES */

.item-container img {
  max-width: 100% !important;
}

.img-ts-col {
  border: var(--thin-borders);
}

.img-ts-col.pdf {
  border: none
}

.img-ts-container {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.img-ts-container.selx {
  border: 2px solid blue;
}

.img-ts-image {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.img-ts-image.pending {
  opacity: .35;
}

.settings-dark-mode .img-ts-image .icon {
  color: grey;
}

.img-ico-sel {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.img-ico-view {
  position: absolute;
  top: 5px;
  right: 2px;
  z-index: 1;
}

.img-profile {
  padding: 3em !important;
  border: var(--thin-borders);
}

.lbl-user {
  padding: .5em !important;
}

.lbl-user,
.lbl-user>.icon {
  margin: 0 .25em !important;
}

/* SEARCH */

.search-page {
  padding: .5em;
  border: 0
}

.search-page .menu-search,
.item-header .menu-search {
  width: 100% !important;
}

.search-page>.item.search,
.item-header .menu-search>.menu {
  width: 100% !important;
}

.search-page>.item.search>.input.labeled {
  width: 100% !important;
}

.search-page>.item.search>.input.labeled .icon,
.item-header .menu-search>.menu .label>.icon {
  margin: 0 !important
}

/* BUS SCHEDULE */

.busSchedule .cards {
  padding: .5em !important;
}

.busSchedule .cards .label {
  width: 100%;
  text-align: center;
}

.busSchedule .cards .content.extra {
  display: flex;
  flex-direction: row;
}

/* CHECKER */
.inline-container .content>.checker {
  float: right;
  font-size: small;
  margin-left: 1em;
  margin-left: 2em;
}

.menu-select-header>.checker {
  float: right;
}

.app-notifications .checker {
  float: right;
  font-size: 1em !important;
}

.app-notifications .checker label {
  margin-right: .5em;
  font-size: .8em;
}

.seg-cal-select>.checker {
  padding: .5em;
  text-align: right;
}

.header-checker {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.header-checker>.checker {
  text-align: right;
}

.table .checker {
  justify-content: center;
}

.standings-table .table .checker {
  justify-content: left;
}

.checker .label {
  border-radius: 4px;
  border: 1px solid #9dbbd1 !important;
  padding: .5em .25em;
  min-width: 30px;
  text-align: center;
  margin-right: .25em !important;
}

.checker .icon {
  border-radius: 4px;
  border: 1px solid #9dbbd1 !important;
}

.checker label {
  margin-left: 1em !important;
  align-self: center;
}

.form-container .checker,
.form>.checker,
.checker.inline {
  padding: 0 0 1.5em 0;
}

.checker {
  display: flex;
  justify-items: center;
  align-items: flex-end;
}

.checker-text {
  display: flex;
  flex-direction: column;
  justify-items: left;
}

.checker-text>label:nth-child(1) {
  font-weight: bold;
}

.checker-text>label {
  align-self: normal;
}

.inline-container .content>.checker label {
  margin-left: .25em !important
}

.sw-wrapper .checker {
  margin-bottom: .25em;
}

.menu-select-items .checker.counter {
  display: grid;
  grid-template-columns: 1fr 8fr 2fr;
  text-align: left !important;
  justify-items: left !important;
  align-items: center;
}

.checker.counter .rc-input-number {
  margin: 0 !important;
}

.wfw .checker {
  padding: 0 0 1.5em 0;
}

.seg-button {
  width: 100%;
  background-color: rgb(91, 91, 91) !important;
}

.seg-button.slt-clientProfiles {
  background-color: var(--sem-blue) !important;
}

.seg-button>input {
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 9998 !important;
}

.mis,
.msmi>.item {
  background-color: inherit !important;
  padding: 0 !important;
  width: 100%;
}

.mis>.header>.icon.mrk {
  opacity: 1;
}

.mis>.header>.icon.n-mrk {
  opacity: .5;
}

.msmi.two .item>.header {
  grid-template-columns: 1fr 9fr !important;
}

.msmi>.item>.header,
.msmi.three>.header,
.msmi.three .item>.header {
  grid-template-columns: 1fr 6fr 1fr !important;
}


.sib>.button {
  margin-top: 1em;
}

.app-full {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: auto;
}

/* INFO WINDOW */
.info-window {
  width: 120px;
  transform: translate(-50%, -150%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: .5em;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid grey;
  background-color: rgb(231, 251, 255);
  gap: 1em;
  text-align: center;
  font-size: .8rem;
}

.info-window>div:first-child {
  text-align: right;
  font-size: .8rem;
}

/* NEW VERSION */
.new-version-alert {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}


/* MENU APP SETTINGS */

.menu-app-settings {
  padding-top: .5em !important
}

.menu-app-settings>.item {
  padding: .75em !important;
  margin-left: .5em !important;
}

.menu-app-settings>.item>.header {
  margin: 0 !important;
}

.menu-app-settings>.item>.icon {
  float: left !important;
  margin-right: .75em !important;
}

.menu-app-settings.profile {
  height: 100%;
}

.menu-app-settings.profile>.item {
  margin: 0 !important;
}

.menu-app-settings.profile>.item>.header {
  padding: 0 !important;
}

.menu-app-settings.profile>.item>.icon {
  margin: 0 !important;
}


/* NOTIFICATIONS FOREGROUND */
.notification-forground {
  position: fixed !important;
  bottom: 5%;
  z-index: 1000;
  width: 100%;
}

.notification-forground.desktop {
  right: 1%;
  bottom: 1%;
  width: 20%;
}

/* GRID TEXT */

.grid-text {
  padding: .1em
}

.grid-text-map {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  width: 100%;
}

.grid-text-map>.row {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.grid-text-map>.row>.column {
  text-align: center !important;
  padding-left: .1rem !important;
  padding-right: .1rem !important;
}

.grid-text-map>.row>.column>.field {
  margin: .25em !important;
  text-align: left;
}

/* OTHERS */
.ssc-normal {
  height: 100%;
}

.ssc-normal,
.ssc-normal .segment,
.ssc-normal .menu,
.ssc-normal .grid,
.ssc-normal .label:not(.mini):not(.tiny):not(.small):not(.large),
.ssc-normal .table {
  font-size: .8rem !important;
}

.ccs {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
}

.icon-glow:before {
  -webkit-animation: glow 1300ms infinite;
  -moz-animation: glow 1300ms infinite;
  -o-animation: glow 1300ms infinite;
  animation: glow 1300ms infinite;
}

.item-meeting>.icon {
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}

.time-keeper {
  text-align: center;
  padding: 2em
}

.label-selects {
  display: flex !important;
  flex-direction: column;
  row-gap: .25em;
}

.label-selects>.label>.label:first-child {
  margin-right: .5em;
}

.label-selects>.label>.dropdown {
  margin-right: .5em;
}

.microphone-wrapper {
  text-align: right;
  padding: 0 .5em !important;
}

.push-pushable {
  height: 100%;
  width: 100%;
}

.push-push {
  height: 100%;
}

.pending-wait {
  height: 100% !important;
  width: 100% !important;
  display: block
}

.pending-wait .icon {
  font-size: 1em !important;
}

.sem-carosel {
  padding: .25em;
}

.sem-carosel .banners-image>.image {
  height: 60px;
  width: auto;
}

.sem-menu {
  /* padding: 1em; */
  height: 100%;
}

.item-info {
  position: absolute;
  top: .5em;
  right: .5em;
}

.phone-auth>.mdl-card {
  box-shadow: none;
}

.phone-auth {
  font-family: inherit !important;
  font-size: 1rem !important;
}

#firebaseui-auth-container {
  padding: 0;
  height: 100%;
  width: 100%
}

.firebaseui-container {
  padding: 0;
  height: 100%;
  width: 100%
}

#firebaseui-auth-container div,
#firebaseui-auth-container input,
#firebaseui-auth-container h1 {
  font-size: 1rem !important;
}

.grecaptcha-badge {
  display: none;
}

.flx-lr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quick-view {
  padding: 0 !important;
}

.google-dot {
  height: 18px;
  width: 18px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.google-url {
  height: 30px;
  width: 30px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-left: 2px;
  font-weight: bold;
}

.row-split {
  display: flex;
  gap: .5em;
  flex-direction: column;
}

.topic-groups {
  padding: .5em
}

.header-button-select {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.header-button-select>div:nth-child(2) {
  text-align: center;
}

.killOf {
  overflow-y: hidden !important;
  -webkit-overflow-y: hidden !important;
}

.inh {
  padding: 0 !important
}

.inh.oth>.menu>.item {
  font-size: 1em !important;
}

.inh.oth .item.fcmt {
  overflow-wrap: break-word;
  padding: 1em;
  font-size: .8rem;
  inline-size: 100px;
}

.dm-mode .app-header img {
  max-width: 200px !important;
  max-height: 200px !important;
}

.field.disabled {
  opacity: .9 !important;
}

.ddom {
  background-color: var(--off-white-3) !important;
  font-size: .9rem !important;
  height: 50vh;
  overflow-y: auto;
}

.ddom .item {
  font-size: .9rem !important;
  background-color: var(--off-white-1) !important;
}

.dot-label {
  padding: .25em;
  align-items: center;
  font-size: .9rem;
}

.qr-center {
  margin: auto;
}

.flex-space-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-center>.segments {
  min-width: 50vw;
}

.available-teams>div:first-child {
  padding: 1em;
  background-color: rgb(214, 214, 214);
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid grey;
}

.available-teams .label {
  margin-left: 1em !important;
}

.lbl-btns {
  display: flex !important;
  flex-direction: row;
  color: white !important;
  align-items: center;
  justify-content: center;
  background-color: inherit !important;
}


.lbl-user {
  padding: .5em !important;
}

.lbl-user,
.lbl-user>.icon {
  margin: 0 .25em !important;
}


.img-nrm {
  display: block;
  max-width: 100%;
}

.dd-linking {
  font-size: .8rem;
  width: 200px !important;
  background: var(--sem-grey) !important;
  color: white !important;
  font-weight: bold;
}

.footer-right {
  padding: 1em !important;
  background-color: black !important;
}

.warning.message {
  word-wrap: break-word;
}

.rating-container {
  padding: 1em 0;
}

.rating-container.select {
  margin: auto;
  width: 100%;
  text-align: center;
}

.ct-no-data {
  height: 100% !important;
}

.piom>.header,
.piom>.menu>.item {
  display: grid !important;
  grid-template-columns: 1fr 8fr 1fr !important;
}

.piom>.menu>.item {
  padding: 1.5em !important;
}

.piom>.header>div:last-child,
.piom>.menu>.item>div:last-child {
  text-align: right;
}

.srr .push-container {
  grid-template-rows: auto 1fr 1fr;
}

.iconed-map {
  display: flex !important;
  padding: .5em !important;
  align-items: center;
  justify-content: flex-start;
}

.card-flag {
  display: flex;
  gap: 1em
}

.card.bu>.content>.meta {
  word-break: break-all;
}

.form>.field.df>.input>input {
  border-color: rgb(121, 0, 0);
}

.form>.field>.input>input[readonly] {
  background-color: var(--off-white-1);
}

.client-status>.item>.content>.description:nth-child(3) {
  font-size: .8rem !important;
}

.client-status>.item>.content>.description .label {
  width: 100%;
}

.opt-icn {
  display: flex !important;
}

.opt-icn>.text {
  width: 100%;
}

.opt-icn>.text>.icon {
  float: right;
}

.sign-up {
  text-align: center;
  margin-top: 2em;
}

.list-check>.item {
  align-content: center !important;
  align-items: center !important;
}

.list-check>.item>.checkbox {
  margin-right: 1em !important;
}

.list-cb-container>.list>.checkbox {
  margin-right: 1em;
}

.image-meta {
  padding: 1em;
  color: black;
  word-break: break-word;
}

.settings-dark-mode .image-meta {
  color: white;
}


.card-icons>div {
  display: flex;
  justify-items: flex-end;
  width: 100%;
}

.btn-space {
  display: flex;
  justify-content: space-between;
}

.seg-header-label {
  display: flex;
  justify-content: space-between;
}

.seg-header-label>div:nth-child(1) {
  font-weight: bold;
}

.cred-lbls {
  display: flex;
  padding: 1em;
  justify-content: center;
}

.ms-key {
  font-size: .7rem;
  margin-right: 1em;
}

.dimmer-seg {
  height: 100% !important;
  width: 100% !important;
}

.dimmer-seg .button {
  margin-top: 2em !important;
}


.prp-description {
  padding: 1em .5em;
  font-weight: normal;
  opacity: 1 !important;
}

.line-icon {
  display: flex;
  justify-content: flex-end;
}


.query-line {
  display: flex;
}

.query-line {
  font-size: .8rem
}

.query-line div {
  margin-right: .75em !important;
}

.query-line.fglb {
  color: var(--off-black-7)
}

.sat-preview {
  margin-top: 1em !important;
}

.list-button,
.list-button>.text,
.list-button .header,
.list-button>.menu,
.list-button>.menu>.item {
  font-weight: bold !important;
  padding: .35em !important;
  font-size: .78571429rem !important;
}

.card-icon {
  float: right;
}

.PhoneInputInput {
  font-size: medium;
  padding: .67em 1em;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, .15)
}

.user-auths .label {
  float: right;
}

/* .full-image>.content {
  margin: auto;
} */

.segment.is-html {
  border: 1px solid rgba(34, 36, 38, .15) !important;
  border-radius: .28571429rem !important;
  min-height: 50px;
  width: 100%;
  word-break: break-word;
}

.field-object-container {
  margin-bottom: 1em;
}

.confirmation-button {
  margin-bottom: 4px !important;
}

.abc.so>.btn-confirm>.button {
  height: 3em;
  border-radius: 0 !important;
}

.abc.so {
  text-align: left !important;
  border-radius: 0 !important;
  height: 3em;
}

.icon.bordered {
  border-radius: 3px !important;
}

.menu.transition {
  min-width: 180px !important;
}

.menu.borderless {
  border-radius: 0 !important;
}

.react-json-view {
  height: 100% !important;
  padding: 1em;
}

.rc-input-number {
  margin-bottom: 1em !important;
}

.create-event-container {
  width: 50% !important;
  height: 100% !important;
  margin: 5em auto !important;
}

.paste-container {
  padding: .25em
}

.paste-content {
  height: 100%;
}

.paste-toggle {
  margin-bottom: 1em;
}

.ta-full {
  height: 100%;
  width: 100%;
  border: none;
  padding: .5em
}

.line-s {
  display: block;
}

.line-h {
  display: none !important;
}

.fav-header {
  font-weight: bold;
}

.ph-full {
  height: 100%;
}

.seg-ph-full {
  height: 100%;
  text-align: center;
  font-weight: bold;
}

.scroll-container>.card {
  width: 100%;
}

.icons>.icon.corner {
  font-size: .5em !important;
}

.content.segment.placeholder>.form>.phone-container>.field,
.content.segment.placeholder>.form>.field {
  max-width: none;
  margin-right: 0;
  margin-left: 0;
}

.content.segment.placeholder>.button {
  margin-top: 1em;
}

.content.segment.placeholder>div {
  margin-top: 2em;
  text-align: center;
}

.content.segment.placeholder.fw {
  margin-top: 0;
}

.list>.item>.label {
  margin-right: .5em;
}

.input.icon {
  margin-top: .5em
}

.input.labeled>.label.label>.icon {
  margin: 0
}

.app-user-meta>.meta {
  padding: .25em !important;
}

.app-user-meta>.meta>.icon {
  margin-right: .5em !important;
}

.list.relaxed.ordered>.item>.content>.header {
  word-wrap: break-word;
  font-size: .8rem;
}

/* .prc {
  border: 1px solid blue;
  border-radius: 8px;
} */

.prc.edt {
  display: grid;
}

/* SCROLL BARS */

/* Width of the scrollbar */
body {
  font-family: 'Roboto', 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
  color: rgba(0, 0, 0, .87);
}

html {
  height: 100%;
  margin: 0;
}

body {
  scrollbar-width: thin;
  height: 100%;
  margin: 0;
  /* or auto or thick */
}

body ::-webkit-scrollbar {
  width: 4px !important;
  -webkit-overflow-scrolling: touch;
  background-color: var(--scroll-main);
}

.app-dark-mode ::-webkit-scrollbar {
  background-color: var(--off-black-2) !important;
}

body,
body ::-webkit-scrollbar {
  scrollbar-width: none;
}

body ::-webkit-scrollbar-thumb {
  width: 4px !important;
  -webkit-overflow-scrolling: touch;
  background-color: var(--scroll-sub) !important;
}

body .app-dark-mode ::-webkit-scrollbar-thumb {
  background-color: var(--off-black-4) !important;
}

.menu-alphabet {
  scrollbar-width: thin;
}

.dt-mode .app-container::-webkit-scrollbar,
.vert-container>.content::-webkit-scrollbar,
.sb-content::-webkit-scrollbar,
.no-sb::-webkit-scrollbar {
  display: none;
}

.fresnel-greaterThan-mobile .swipe-tab::-webkit-scrollbar {
  background: rgb(92, 92, 92);
  height: 6px !important;
}

.fresnel-at-mobile .swipe-tab::-webkit-scrollbar {
  background: black;
  height: 0px !important;
}

.settings-app-container .app-swipe-menu>.menu::-webkit-scrollbar {
  display: inline;
}

.dt .app-swipe-menu>.menu {
  scrollbar-width: thin
}

.mob .app-swipe-menu>.menu {
  scrollbar-width: none;
}

.menu .divider {
  margin: 0 !important;
}

.ddl-full .menu.transition {
  width: max-content;
}

.btn-image {
  padding: 0 !important;
  background-color: black !important;
}

.btn-image>img {
  width: 30px;
  height: 30px;
  border-radius: 2px;
}

.lbl-vote {
  position: absolute;
  top: .1em;
  right: .1em;
  margin: 0 !important;
}