:root {
  --allow-bgc: rgb(43, 43, 43);
  --almost-black: rgb(24, 24, 24);
  --almost-black-hov: rgb(32, 32, 32);
  --almost-black-item: rgb(27, 27, 27);
  --dark-adjust-brdc: rgb(83, 83, 83);
  --dark-adjust-dvdr-bgc: #1b1c1d;
  --dark-divider-div: #858585;
  --dark-inputs-brdc: rgb(62, 62, 62);
  --dark-inputs-c: rgb(233, 233, 233);
  --dark-label: rgb(182, 182, 182);
  --dark-thin-border-menu: rgb(112, 112, 112);
  --dark-thin-border-settings: solid 1px rgb(44, 44, 44);
  --menu-item-border: rgb(15, 15, 15);
  --menu-item-divider: #b1a500;
  --menu-item-divider-alt: #a5a5a5;
  --page-bgc: rgb(26, 26, 26);
  --pbc: rgba(47, 47, 47);
  --settings-menu-item-bc-restrict: rgb(122, 77, 77);
  --drop-content-darkish: rgb(37, 37, 37);
}

.sbrc {
  width: 100%;
  border-radius: 0 !important;
  overflow-x: hidden;
  scrollbar-width: none;
}

.samh.swc {
  background-color: var(--off-black-7) !important;
}

.samh .sub.header {
  display: grid !important;
  gap: .5em;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-template-rows: repeat(1, 1fr) !important;
}

.samh .sub.header .label {
  margin: 0 !important;
}

/* VERT-CONTAINER */

.vert-container>.ct-vs {
  padding: .5em 0;
}

.vert-container.app-dashboard .footer:first-of-type {
  padding: 1em 1em !important;
}

.vert-container .menu:not(.pios) {
  padding: 0 !important;
}

.vert-container .menu>.item {
  border-radius: 0 !important;
}

.vert-container.cmq {
  height: auto;
}

.vert-container.segment {
  padding: 0
}

.vert-container.thcf {
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "topper"
    "header"
    "content"
    "footer";
}

.vert-container.hcf {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.vert-container.ocf {
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "notification"
    "content"
    "footer";
}

.vert-container.hnc {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header"
    "navigation"
    "content";
}

.vert-container.hcff {
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    "header"
    "content"
    "footer"
    "footer2";
}

.vert-container.htbf {
  grid-template-rows: auto 1fr 4fr auto;
  grid-template-areas:
    "header"
    "top"
    "bottom"
    "footer";
}

.vert-container.hc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
}

.vert-container.hlc {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header"
    "help"
    "content";
}

.vert-container.hoc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
}

.vert-container.hloc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "help"
    "content";
}

.vert-container.oc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "notification"
    "content";
}

.vert-container.cf {
  grid-template-rows: 1fr auto;
  grid-template-areas: "content"
    "footer";
}

.vert-container.c {
  grid-template-rows: 1fr;
  grid-template-areas: "content";
}

.vert-container.hp {
  grid-template-rows: 2fr auto;
  grid-template-areas:
    "header"
    "path";
}

.vert-container.hpc {
  grid-template-rows: 1fr 1fr auto;
  grid-template-areas:
    "header"
    "path"
    "content";
}

.vert-container.thc {
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "topper"
    "header"
    "content";
}

.vert-container.tc {
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "topper"
    "content";
}

.vert-container.thcf>.content {
  height: 100%;
}

.vert-container>.header {
  grid-area: header;
}

.vert-container>.notification {
  grid-area: notification;
  margin: 0 1em 0 1em;
  font-size: .9rem;
  position: fixed;
  top: 90px;
  z-index: 9999;
  width: 90%;
  line-height: 1.3rem;
}

.vert-container.sw-wrapper>.notification {
  margin-top: 2em;
}

.vert-container>.path {
  grid-area: path;
  padding: 1em;
}

.vert-container>.navigation {
  grid-area: navigation;
}

.vert-container>.top {
  grid-area: top;
  padding: 1em;
}

.vert-container>.content {
  grid-area: content;
  height: 100%;
  scrollbar-width: none;
}

.vert-container>.content>.form:not(.form-golfers.form) {
  padding: 1em;
}

.vert-container>.content .form-container {
  scrollbar-width: none;
}

.vert-container>.bottom {
  grid-area: bottom;
  padding: 1em;
}

.vert-container>.footer {
  grid-area: footer;
}

.vert-container>.footer.split {
  padding: 1em;
}

.vert-container>.footer2 {
  grid-area: footer2;
}

.vert-container.linking>.header>.divider {
  margin-top: 0 !important;
  font-size: .8rem;
}

.vert-container .pbc {
  font-size: .8rem !important;
  padding: 1em 1.5em;
}

.vert-container .pbc * {
  background-color: inherit !important;
  color: var(--off-white-3) !important;
}

:not(.settings-dark-mode) .vert-container>.footer {
  border-top: var(--thin-border-settings) !important;
}

:not(.settings-dark-mode) .vert-container>.footer.modify {
  text-align: right;
}

.vert-container.sgn .grid>.column:nth-child(2) {
  font-weight: bold;
}

.vert-container.sgn .button>.icon.loading {
  background: none;
}

.vert-container.basic>.header,
.vert-container.basic>.footer {
  padding: .5em;
  font-weight: normal;
}

.vert-container .thc .topper,
.vert-container .adv .topper,
.vert-container .wrp-sub .topper,
.vert-container .wrp-sub>.header,
.vert-container.paddedHeaderAndFooter>.header,
.vert-container.noContent>.header,
.vert-container.paddedHeader>.header,
.vert-container.paddedContent>.footer,
.vert-container.padded>.header {
  padding: 1em;
  font-weight: bold;
}

.vert-container.noContent>.content>.header {
  text-align: center;
  padding-bottom: 1em;
}

.vert-container.noContent>.content {
  padding: .5em;
}

.modal .vert-container.noContent>.content {
  margin: auto;
}

.vert-container.padded>.content:not(.igw) {
  padding: .5em;
}

.vert-container.paddedHeaderAndFooter>.footer,
.vert-container.paddedHeader>.footer,
.vert-container.paddedContent>.footer,
.vert-container.padded>.footer {
  padding: 1em .25em;
}

.vert-container.paddedFooter>.footer {
  padding: 1em;
}

.vert-container.sgn>.header {
  padding: 0;
  font-weight: normal;
}

.vert-container.sgn>.footer {
  padding: .5em;
  font-weight: normal;
}

.vert-container.linking>.header {
  padding: .5em;
  font-weight: normal;
}

.vert-container.linking>.content {
  padding: 0;
}

.vert-container.splitLR {
  display: grid;
  grid-template-rows: 1fr !important;
  grid-template-columns: 1fr 4fr;
  width: 100%;
  height: 100% !important;
  grid-template-areas:
    "header content" !important;
}

.vert-container.splitLR>div {
  height: 100% !important;
}

.vert-container.split.tbf {
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  grid-template-columns: 100% !important;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "top"
    "bottom"
    "footer"
}

.vert-container.splitHorz {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "left"
    "right"
}

.vert-container.splitVert {
  display: grid;
  grid-template-rows: 1fr 1fr !important;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  grid-template-areas:
    "header"
    "content"
}

.vert-container.splitVert>.header {
  margin-bottom: .5em;
  height: 100%;
}

.vert-container.split>.header {
  padding: .5em;
  font-weight: normal;
}

.vert-container.split>.top {
  padding: .5em;
}

.vert-container.split>.bottom {
  padding: .5em;
}

.vert-container.split>.footer {
  padding: .5em;
}

.vert-container.match-pending>.footer {
  text-align: right;
}

.vert-container.nf {
  height: auto !important;
}

.vert-container.pending {
  width: 100%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "content";
}

.vert-container.pending>.content {
  text-align: center;
  font-size: 1.5rem;
  padding: 2em;
  margin: auto 0 !important;
  height: auto !important;
}

.vert-container.pending>.header {
  padding: 1em;
  font-weight: bold;
  text-align: center;
}

.vert-container.segments,
.vert-container.segments>.segment {
  border: 0;
  border-radius: 0 !important;
}

.vert-container.fip {
  padding-bottom: 2em;
}

.form-container .vert-container {
  height: auto;
}

.vert-container .menu>.item.sel,
.av-menu.menu>.item.sel {
  background-color: var(--off-black-5) !important;
}

.vert-container>.content>.menu>.item {
  align-items: center !important;
}

.google-data-container .vert-container.thcf {
  height: 100% !important;
}

.adv .vert-container>.topper {
  text-align: center;
}

.vert-container.images-content,
.vert-container.images-content>.content {
  padding: 0px;
}

.vert-container .divider.horizontal {
  font-size: .8rem !important;
  margin: .25em 0 !important;
  padding: 0.5em !important;
}

/* ADV */
.adv {
  display: grid;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  grid-template-columns: 1fr 7fr;
}

.adv.sgs {
  grid-template-columns: 7fr;
}

.adv .menu:first-child {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

/* menu-groups */
.menu-groups {
  margin: 0 !important;
  margin-top: .25em !important;
}

.menu-groups .item {
  padding: 0 !important;
  width: 100%;
  margin: .5em 0;
}

.menu-groups .item>.header {
  display: grid !important;
  align-items: center;
  display: grid;
  font-size: 1.1rem !important;
  padding: 0.5em 1em !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.menu-groups .item>.header>div:last-child,
.menu-groups .item>.header>.icon:last-child {
  justify-self: end;
}

.menu-groups .item>.header>.icons {
  width: fit-content;
}

.menu-groups>.item>.menu {
  margin: .5em 0 !important;
  padding: 0.25em 0 !important;
}

.menu-groups>.item>.menu>.item {
  font-size: 1rem !important;
  padding: 0.5em 1.5em !important;
}

/* menu-groups depending on the count */
.menu-groups.three .item>.header {
  grid-template-columns: 1fr 6fr 1fr !important;
}

.menu-groups.four .item>.header {
  grid-template-columns: 1fr 6fr 2fr 1fr !important;
}

/* OTHER */
.smii {
  text-align: right;
}

.smii.tt {
  display: flex;
  justify-content: space-between;
  padding: .5em;
  border-radius: 8px;
}

.profile-select {
  min-width: 180px;
  border-left: 1px solid rgb(104, 104, 104) !important;
  border-right: 1px solid rgb(104, 104, 104) !important;
}

.profile-select.cln {
  background-color: var(--sem-greenish);
}

.profile-select .menu>.item {
  font-size: .9rem !important;
}

.profile-select.access {
  font-size: .8rem;
}

.profile-select.access .item {
  font-size: .8rem !important;
}

.profile-select.data-source.dropdown.scrolling.ui .menu {
  width: 280px !important;
}

/* ICONS LEFT */

.icons-left>.item.sel {
  opacity: .6;
}

/* This will line up the the icons to the left (sideMenu/iconsLeft */
.icons-left .item {
  display: flex !important;
  padding: .75em !important
}

.icons-left>.item {
  align-self: center !important;
}

.icons-left>.item>img,
.icons-left>.item>.icon {
  float: left !important;
  margin-right: .5em !important;
  margin-left: 0 !important;
  align-self: center;
}

.icons-left>.item>img {
  vertical-align: middle !important;
  display: inline-block !important;
}

.settings-console-list .header>.icon {
  margin-right: .5em !important;
}

.settings-console-list>.item>.content>.content>.label {
  min-width: 48px !important;
  text-align: center !important;
}

.sfp {
  height: 100%;
}

.sfp>.message {
  background-color: var(--off-black-2) !important;
  color: var(--off-white-1) !important;
  margin: .5em;
}