.cssd {
  display: grid !important;
  width: 100%;
}

.cssd .pc-1 {
  grid-area: pc-1;
}

.cssd .pv-1 {
  grid-area: pv-1;
}

.cssd .pc-2 {
  grid-area: pc-2;
}

.cssd .pv-2 {
  grid-area: pv-2;
}

.cssd .pc-3 {
  grid-area: pc-3;
}

.cssd .pv-3 {
  grid-area: pv-3;
}

.cssd .pc-4 {
  grid-area: pc-4;
}

.cssd .pv-4 {
  grid-area: pv-4;
}

.cssd .pc-5 {
  grid-area: pc-5;
}

.cssd .pv-5 {
  grid-area: pv-5;
}

.cssd.labRuns {
  padding: 1em !important;
  width: 100%;
  word-wrap: break-word;
  text-wrap: wrap;
  gap: 1em;
  grid-template-areas:
    "pc-1 pv-1"
    "pc-3 pv-3"
    "pc-4 pv-4" !important;
}

.cssd.labRuns>div:nth-child(odd) {
  font-weight: bold;
}

.cssd.labRuns>.pc-2,
.cssd.labRuns>.pv-2 {
  display: none;
}